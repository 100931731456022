import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the icons related global CSS, prevent flash of unstyled icons
import '@olx/laquesis-surveys/dist/surveys_laquesis.css';

import { AdvertisingConsent } from '@domains/shared/components/Advertising/AdvertisingConsent';
import { RWDContextProvider } from '@domains/shared/contexts/RWDContext';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useScrollRestoration } from '@domains/shared/hooks/useScrollRestoration';
import { useUnregisterServiceWorker } from '@domains/shared/hooks/useUnregisterServiceWorker/useUnregisterServiceWorker';
import type { FeatureFlags } from '@domains/shared/types/featureFlags';
import { config } from '@fortawesome/fontawesome-svg-core';
import { CurrentUserContextProvider } from '@lib/currentUser';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import type { DesktopHeader } from '@lib/graphql/ssr/helpers/getDesktopHeaderIfExist';
import type { Translations } from '@lib/i18n/types/translations';
import { useSentryCustomTagsMonitoring } from '@lib/monitoring/client/useSentryCustomTagMonitoring';
import { newrelicBrowserErrorHandler } from '@lib/pages/document/newrelicBrowserErrorHandler';
import { MetaTags } from '@lib/pages/MetaTags';
import { DEFAULT_FEATURE_FLAGS } from '@lib/pages/ssrWrappers/consts/defaultFeatureFlags';
import { parseLanguageInCustomApp } from '@lib/pages/ssrWrappers/helpers/parseLanguageInCustomApp';
import type { BasePageCoreProps } from '@lib/pages/types';
import type { CurrentUser } from '@lib/pages/user';
import { WebVitals } from '@lib/pages/webVitals/WebVitals';
import type { SiteSettingsConfig } from '@lib/siteSettings/SiteSettingsContext';
import { SiteSettingsContextProvider } from '@lib/siteSettings/SiteSettingsContext';
import { ThemeProvider } from '@lib/styles/ThemeProvider';
import type { CookiesCategorisation } from '@olxeu-eprivacy-storage/react';
import { CookieStorageProvider, createCookieStorage } from '@olxeu-eprivacy-storage/react';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import App from 'next/app';
import type { JSX } from 'react';
import { useMemo, useRef } from 'react';

// https://fontawesome.com/docs/web/use-with/react/use-with#next-js
config.autoAddCss = false;

interface Props extends LaquesisPageProps, BasePageCoreProps {
    featureFlags?: FeatureFlags;
    translations: Translations;
    user?: CurrentUser;
    currentUser: CurrentUser;
    desktopHeader: DesktopHeader;
}

const { env, isCookieSDKEnabled: IS_CONSENT_REQUIRED } = getPublicEnvConfig();
const SHOULD_DEBUG_COOKIE_CONSENT = !['PRD', 'test', undefined].includes(env);

const CustomApp = ({
    Component,
    pageProps,
    router,
}: AppProps<Props & { cookiesCategorisation: CookiesCategorisation }>): JSX.Element => {
    useScrollRestoration(router);
    useSentryCustomTagsMonitoring(router);

    const { cookiesCategorisation = {}, featureFlags = DEFAULT_FEATURE_FLAGS, lang } = pageProps || {};
    const siteSettingsProps: SiteSettingsConfig = {
        featureFlags,
        lang,
    };

    // We decided to remove web pushes from our app but still users have registered SW, so we have to unregister it
    useUnregisterServiceWorker();

    // Cookie SDK
    const cookiesCategorisationRef = useRef<CookiesCategorisation>({});
    cookiesCategorisationRef.current = {
        ...cookiesCategorisationRef.current,
        ...cookiesCategorisation,
    };

    // Cookie reservoir is created once per user SPA session
    const cookieConsentStorage = useMemo(
        () =>
            IS_CONSENT_REQUIRED
                ? createCookieStorage(
                      IS_CONSENT_REQUIRED,
                      cookiesCategorisationRef.current,
                      SHOULD_DEBUG_COOKIE_CONSENT,
                  )
                : createCookieStorage(false),
        [],
    );

    newrelicBrowserErrorHandler();

    return (
        <>
            <MetaTags />
            <WebVitals experiments={pageProps.experiments} />
            <ThemeProvider>
                <SiteSettingsContextProvider {...siteSettingsProps}>
                    <CookieStorageProvider storage={cookieConsentStorage}>
                        <AdvertisingConsent cookieCategoryMap={cookiesCategorisationRef.current} />
                        <CurrentUserContextProvider user={pageProps.currentUser}>
                            <RWDContextProvider initialIsDesktop={pageProps.desktopHeader?.['Is-Desktop'] === 'true'}>
                                <Component {...pageProps} />
                            </RWDContextProvider>
                        </CurrentUserContextProvider>
                    </CookieStorageProvider>
                </SiteSettingsContextProvider>
            </ThemeProvider>
        </>
    );
};

CustomApp.getInitialProps = async (appContext: AppContext): Promise<AppInitialProps> => {
    // Disable automatic static files optimisation - globally - otherwise our
    // env props are unavailable (like CDN url, one trust config) - it is done by
    // setting custom `CustomApp.getInitialProps`
    // @link https://nextjs.org/docs/advanced-features/automatic-static-optimization
    //
    // Additionally, for "static" pages (404, 500) set missing lang page property
    // (fallback) which is overwritten by `withBaseSsrHandler`.

    const appInitialProps = await App.getInitialProps(appContext);

    /* Lang fallback recognition mechanism */
    const lang = parseLanguageInCustomApp(appContext?.ctx);

    return {
        ...appInitialProps,
        pageProps: { lang, ...appInitialProps.pageProps },
    };
};

export default CustomApp;
