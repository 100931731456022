// This file re-exports "process.env.NEXT_PUBLIC_SITECODE" variable which is replaced
// during the build by the Next.js to string for mocking purpose in the unit tests
export const SITE_CODE = {
    pl: 'otodompl',
    ro: 'storiaro',
    pt2: 'imovirtualpt2',
} as const;

export type SiteCode = ObjectValues<typeof SITE_CODE>;

export const CURRENT_SITE_CODE = process.env.NEXT_PUBLIC_SITECODE as SiteCode;
