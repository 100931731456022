import { SITE_CONFIG } from '@config/siteConfig';
import { checkIsLocale } from '@lib/i18n/checkIsLocale';
import type { AppContext } from 'next/app';

/**
 * This is the fallback version of the
 * @link ./parseLanguage.ts
 * function, which is used for pages without the custom getServerSideProps and which value is overriden
 * by default by `parseLanguage()`
 */
export const parseLanguageInCustomApp = (context: AppContext['ctx'] | undefined): string => {
    // Try to fetch from query
    let lang: string | undefined;
    const asPath = context?.asPath;

    if (!lang && asPath && !asPath?.startsWith('/_next/')) {
        // Try to read from the asPath
        const [, asPathLang] = asPath?.split('?')?.[0]?.split('/') || [];

        if (asPathLang && checkIsLocale(asPathLang)) {
            lang = asPathLang;
        }
    }
    if (!lang && asPath !== '/' && asPath !== '/social-login-redirect') {
        // Try to fetch from the cookie, do not try to do it for the homepage and social login redirect page
        // Do not use cookie lang on special pages (homepage and social login redirect)
        const cookies = typeof document !== 'undefined' ? document.cookie : context?.req?.headers?.cookie || '';
        lang = cookies
            .split('; ')
            .find((cookie) => cookie.startsWith('lang='))
            ?.split('=')?.[1];
    }
    if (!lang || !checkIsLocale(lang)) {
        // otherwise use default one
        lang = SITE_CONFIG.metadata.defaultLanguage;
    }

    return lang;
};
