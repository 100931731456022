import type { LinkMetaTag } from '@config/siteConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import { logWarn } from '@domains/shared/helpers/logger';
import Head from 'next/head';
import type { FC, JSX, PropsWithChildren } from 'react';
import { memo } from 'react';

const { name, property, links } = SITE_CONFIG.metadata;

export const MetaTagsBase: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Head>
            {links?.length
                ? links.map(({ rel, href, extraParams, fixedKey }: LinkMetaTag): JSX.Element | null => {
                      if (rel === 'preload' || rel === 'preconnect' || rel === 'dns-prefetch') {
                          logWarn(
                              '[MetaTags] preload/preconnect/dns-prefetch are managed directly in _document component',
                          );

                          // staticAssetsPrefix is undefined for static pages
                          return null;
                      }

                      return <link rel={rel} href={href} key={fixedKey ?? `link_${rel}`} {...extraParams} />;
                  })
                : null}
            {property
                ? Object.entries(property).map(([key, value]) => (
                      <meta property={key} content={value} key={`meta_property_${key}`} />
                  ))
                : null}
            {name
                ? Object.entries(name).map(([key, value]) => (
                      <meta name={key} content={value} key={`meta_name_${key}`} />
                  ))
                : null}
            {children}
        </Head>
    );
};

export const MetaTags = memo(MetaTagsBase);
