import {
    DUST,
    ERROR,
    GENERICS,
    GREY,
    GREY_UPDATED,
    INFO,
    MEADOW,
    PUMPKIN,
    SEA,
    SKY_DEPRECATED,
    SUCCESS,
    TOMATO,
    WARNING,
} from '@lib/styles/partials/colors';
import { OPEN_SANS_FAMILY, SORA_FAMILY } from '@lib/styles/partials/typography';

import type { RawTheme, Theme } from './type';

const EXPANDABLE_LIST_BOX_SHADOW = '0 6px 12px rgba(0, 0, 0, 0.15)';
const INPUT_BOX_SHADOW = `0 0 0 2px ${DUST.x100}`;
const DATE_INPUT_SHADOW = '0 0 0 2px rgba(117, 117, 117, 0.2)';
const SUMMARY_CONTAINER_BOX_SHADOW = `0 2px 10px 0 ${DUST.x200}26`;
const AD_STATISTICS_TOOLTIP_BOX_SHADOW = `4px 8px 24px 0 ${GREY.x400}26`;
const LIGHT_BOX_SHADOW = '0 2px 10px 0 rgba(79, 88, 101, 0.15)';
const TOOLTIP_BOXSHADOW = '4px 4px 16px 0 rgba(117, 117, 117, 0.2)';
const MAP_CLUSTER_PIN_BOXSHADOW = '0 2px 9px rgba(188, 188, 188, 0.762292)';
const STICKY_BAR_BOXSHADOW = '0 0 20px 10px rgba(33, 33, 33, 0.1)';
const PLAY_BUTTON_DROP_SHADOW = `2px 2px 7px ${GENERICS.black}26`;

export const RAW_BASE: RawTheme = {
    globals: {
        fontFamily: OPEN_SANS_FAMILY,
        separator: {
            default: {
                color: GREY_UPDATED.x300,
            },
            light: {
                color: GREY_UPDATED.x200,
            },
        },
    },
    atoms: {
        alert: {
            icon: {
                default: {
                    color: PUMPKIN.x500,
                },
            },
        },
        heading: {
            h1: {
                color: GENERICS.text,
            },
            h2: {
                color: GENERICS.text,
            },
            h3: {
                color: GENERICS.text,
            },
            h4: {
                color: GENERICS.text,
            },
            h5: {
                color: GENERICS.text,
            },
        },
        button: {
            primary: {
                default: {
                    backgroundColor: MEADOW.x500,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                hover: {
                    backgroundColor: MEADOW.x400,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                focus: {
                    backgroundColor: MEADOW.x400,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                active: {
                    backgroundColor: MEADOW.x600,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                disabled: {
                    backgroundColor: GREY.x200,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
            },
            ghost: {
                default: {
                    color: DUST.x300,
                    backgroundColor: GENERICS.white,
                    borderColor: DUST.x300,
                },
                hover: {
                    color: DUST.x300,
                    backgroundColor: DUST.x50,
                    borderColor: DUST.x300,
                },
                focus: {
                    color: DUST.x300,
                    backgroundColor: DUST.x50,
                    borderColor: DUST.x300,
                },
                active: {
                    color: DUST.x300,
                    backgroundColor: DUST.x100,
                    borderColor: DUST.x300,
                },
                disabled: {
                    color: GREY_UPDATED.x300,
                    backgroundColor: GENERICS.white,
                    borderColor: GREY_UPDATED.x300,
                },
            },
            action: {
                default: {
                    backgroundColor: PUMPKIN.x500,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                hover: {
                    backgroundColor: PUMPKIN.x400,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                focus: {
                    backgroundColor: PUMPKIN.x400,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                active: {
                    backgroundColor: PUMPKIN.x600,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                disabled: {
                    backgroundColor: GREY.x200,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
            },
            secondary: {
                default: {
                    backgroundColor: DUST.x300,
                    color: GENERICS.white,
                    borderColor: DUST.x300,
                },
                hover: {
                    backgroundColor: DUST.x200,
                    color: GENERICS.white,
                    borderColor: DUST.x200,
                },
                focus: {
                    backgroundColor: DUST.x200,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                active: {
                    backgroundColor: DUST.x400,
                    color: GENERICS.white,
                    borderColor: DUST.x400,
                },
                disabled: {
                    backgroundColor: GREY.x200,
                    color: GENERICS.white,
                    borderColor: GREY.x200,
                },
            },
        },
        actionLink: {
            secondary: {
                default: { color: DUST.x300 },
                hover: { color: DUST.x300 },
                disabled: { color: GREY_UPDATED.x400 },
            },
        },
        checkbox: {
            default: {
                checked: {
                    base: {
                        borderColor: DUST.x300,
                        backgroundColor: DUST.x300,
                    },
                    hover: {
                        borderColor: GENERICS.transparent,
                        backgroundColor: DUST.x300,
                    },
                },
                unchecked: {
                    base: {
                        borderColor: GREY_UPDATED.x400,
                        backgroundColor: GENERICS.white,
                    },
                    hover: {
                        borderColor: GREY.x400,
                        backgroundColor: GENERICS.white,
                    },
                },
            },
            primary: {
                checked: {
                    base: {
                        borderColor: PUMPKIN.x500,
                        backgroundColor: PUMPKIN.x500,
                    },
                    hover: {
                        borderColor: PUMPKIN.x300,
                        backgroundColor: PUMPKIN.x300,
                    },
                },
                unchecked: {
                    base: {
                        borderColor: GREY_UPDATED.x400,
                        backgroundColor: GENERICS.white,
                    },
                    hover: {
                        borderColor: PUMPKIN.x300,
                        backgroundColor: GENERICS.white,
                    },
                },
            },
            invalid: {
                unchecked: {
                    borderColor: ERROR.x500,
                    backgroundColor: ERROR.x100,
                },
                checked: {
                    borderColor: ERROR.x500,
                    backgroundColor: ERROR.x500,
                },
            },
            disabled: {
                checked: {
                    borderColor: GREY_UPDATED.x400,
                    backgroundColor: GREY.x100,
                },
                unchecked: {
                    borderColor: GREY.x200,
                    backgroundColor: GREY.x100,
                },
            },
            focus: {
                boxShadow: INPUT_BOX_SHADOW,
            },
            icon: {
                color: GENERICS.white,
            },
        },
        dropdown: {
            base: {
                boxShadow: DUST.x100,
                borderColor: GREY_UPDATED.x400,
                backgroundColor: GENERICS.white,
                color: GREY_UPDATED.x700,
                invalid: {
                    borderColor: ERROR.x500,
                    backgroundColor: ERROR.x100,
                    color: ERROR.x500,
                },
                disabled: {
                    borderColor: GREY_UPDATED.x400,
                    backgroundColor: GREY_UPDATED.x200,
                    color: GREY_UPDATED.x400,
                    messageColor: GREY_UPDATED.x500,
                },
                active: {
                    borderColor: DUST.x200,
                    backgroundColor: GREY_UPDATED.x100,
                    color: GREY_UPDATED.x700,
                },
                filter: {
                    borderColor: PUMPKIN.x500,
                    backgroundColor: PUMPKIN.x100,
                    color: PUMPKIN.x500,
                },
            },
            icon: {
                color: DUST.x300,
                filter: {
                    color: DUST.x300,
                },
            },
        },
        modal: {
            root: {
                backgroundColor: GENERICS.white,
                boxShadow: '0 24px 64px 16px rgba(33, 33, 33, 0.2)',
                color: GREY_UPDATED.x700,
            },
            contentSeparator: {
                borderColor: GREY_UPDATED.x300,
            },
            backdrop: {
                backgroundColor: 'rgba(35,46,63,0.7)',
            },
            backdropOverlay: {
                backgroundColor: GENERICS.black,
            },
            closeIcon: {
                color: GREY_UPDATED.x400,
            },
        },
        notSupportedBrowserAlert: {
            backgroundColor: `${ERROR.x500}bb`,
            color: GENERICS.white,
        },
        passwordStrengthMeter: {
            inactiveIndicator: {
                backgroundColor: GREY.x200,
            },
            levels: {
                weak: {
                    color: '#e11c28',
                },
                medium: {
                    color: '#efb840',
                },
                strong: {
                    color: '#6ecb1d',
                },
            },
        },
    },
    pages: {
        login: {
            login: {
                forgotPasswordLink: {
                    default: {
                        color: MEADOW.x500,
                    },
                    hover: {
                        color: MEADOW.x400,
                    },
                    focus: {
                        color: MEADOW.x400,
                    },
                    active: {
                        color: MEADOW.x600,
                    },
                },
                form: {
                    color: GENERICS.white,
                    backgroundColor: GENERICS.text,
                },
                header: {
                    backgroundColor: GREY.x200,
                },
                error: {
                    color: PUMPKIN.x500,
                },
                buttonsSeparator: {
                    borderColor: GREY.x500,
                },
            },
            resetPassword: {
                label: {
                    color: GREY.x500,
                },
                goBackLink: {
                    default: {
                        color: MEADOW.x500,
                    },
                    hover: {
                        color: MEADOW.x400,
                    },
                    focus: {
                        color: MEADOW.x400,
                    },
                },
                error: {
                    color: PUMPKIN.x500,
                },
            },
            confirmPassword: {
                tooltip: {
                    color: GREY.x400,
                },
                error: {
                    color: PUMPKIN.x500,
                },
            },
        },
        manage: {
            newInvestment: {
                thankYou: {
                    color: GREY_UPDATED.x700,
                    container: {
                        backgroundColor: GENERICS.white,
                        boxShadow: LIGHT_BOX_SHADOW,
                    },
                    icon: {
                        color: '#02b54b',
                    },
                    separator: {
                        color: GREY_UPDATED.x300,
                    },
                },
            },
        },
    },
    templates: {
        accountLayout: {
            backgroundColor: DUST.x50,
            navRow: {
                backgroundColor: GENERICS.white,
            },
        },
        clipboardLayout: {
            backgroundColor: GENERICS.white,
            title: {
                color: GREY_UPDATED.x700,
            },
            noResultDescription: {
                color: GREY_UPDATED.x500,
            },
            filters: {
                color: GREY_UPDATED.x500,
            },
            footer: {
                color: GREY_UPDATED.x300,
            },
            icon: {
                color: DUST.x300,
            },
            label: {
                disabled: {
                    color: GREY_UPDATED.x500,
                },
            },
        },
    },
    domains: {
        subscription: {
            card: {
                backgroundColor: GENERICS.white,
                borderColor: DUST.x100,
                color: GREY_UPDATED.x700,
            },
        },
        shared: {
            addOns: {
                card: {
                    helpLink: {
                        color: GREY_UPDATED.x700,
                    },
                    tooltipIcon: {
                        color: GREY_UPDATED.x400,
                    },
                },
            },
            adPushedUpTooltip: {
                refreshedLabel: {
                    color: GREY_UPDATED.x500,
                },
            },
            locationSeoLinks: {
                base: {
                    color: GREY_UPDATED.x700,
                    borderColor: GREY.x200,
                },
                count: {
                    color: GREY_UPDATED.x700,
                },
            },
            featureIcon: {
                included: {
                    color: MEADOW.x500,
                },
                notIncluded: {
                    color: GREY_UPDATED.x400,
                },
            },
            specialOfferHeader: {
                discountHeader: {
                    backgroundColor: MEADOW.x100,
                    color: MEADOW.x600,
                },
            },
            listingMetaItem: {
                color: {
                    price: {
                        active: GREY_UPDATED.x700,
                        removed: GREY_UPDATED.x500,
                    },
                    params: {
                        active: GENERICS.black,
                        removed: GREY_UPDATED.x500,
                    },
                },
                priceBeforeDiscount: {
                    price: {
                        color: GREY_UPDATED.x500,
                    },
                    tooltip: {
                        color: GREY_UPDATED.x400,
                    },
                },
            },
            bundleCard: {
                borderColor: GREY_UPDATED.x300,
                subtitle: {
                    color: GREY_UPDATED.x500,
                },
                price: {
                    color: GREY_UPDATED.x700,
                },
            },
            input: {
                placeholder: {
                    color: GREY_UPDATED.x400,
                },
                default: {
                    borderColor: GREY_UPDATED.x400,
                    backgroundColor: GENERICS.white,
                },
                success: {
                    borderColor: MEADOW.x700,
                    backgroundColor: GENERICS.white,
                },
                invalid: {
                    borderColor: ERROR.x500,
                    backgroundColor: ERROR.x100,
                },
                hover: {
                    borderColor: DUST.x300,
                },
                focus: {
                    boxShadow: INPUT_BOX_SHADOW,
                },
                disabled: {
                    color: GREY_UPDATED.x400,
                    borderColor: GREY_UPDATED.x400,
                    backgroundColor: GREY_UPDATED.x200,
                },
                append: {
                    color: GREY_UPDATED.x700,
                    before: {
                        borderColor: GREY_UPDATED.x400,
                    },
                },
                clearButton: {
                    color: GREY_UPDATED.x500,
                },
            },
            textarea: {
                placeholder: {
                    color: GREY_UPDATED.x400,
                },
                default: {
                    borderColor: GREY_UPDATED.x400,
                    backgroundColor: GENERICS.white,
                    boxShadow: INPUT_BOX_SHADOW,
                },
                invalid: {
                    borderColor: ERROR.x500,
                    backgroundColor: ERROR.x100,
                    boxShadow: INPUT_BOX_SHADOW,
                },
                hover: {
                    borderColor: DUST.x300,
                },
                disabled: {
                    color: GREY_UPDATED.x400,
                    borderColor: GREY_UPDATED.x400,
                    backgroundColor: GREY_UPDATED.x200,
                },
            },
            addItemButton: {
                default: {
                    backgroundColor: MEADOW.x500,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                hover: {
                    backgroundColor: MEADOW.x400,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                focus: {
                    backgroundColor: MEADOW.x400,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
                active: {
                    backgroundColor: MEADOW.x600,
                    color: GENERICS.white,
                    borderColor: GENERICS.transparent,
                },
            },
            accountNavLink: {
                color: GREY_UPDATED.x700,
                backgroundColor: DUST.x100,
                active: {
                    backgroundColor: DUST.x100,
                },
                hover: {
                    backgroundColor: `${DUST.x100}80`,
                },
                icon: {
                    color: DUST.x300,
                    sm: {
                        color: GENERICS.white,
                        backgroundColor: DUST.x300,
                    },
                },
            },
            badge: {
                default: {
                    backgroundColor: DUST.x300,
                    color: GENERICS.white,
                },
                primary: {
                    backgroundColor: PUMPKIN.x500,
                    color: GENERICS.white,
                },
                success: {
                    backgroundColor: MEADOW.x500,
                    color: GENERICS.white,
                },
                info: {
                    backgroundColor: INFO.x500,
                    color: GENERICS.white,
                },
                error: {
                    backgroundColor: ERROR.x500,
                    color: GENERICS.white,
                },
                reversedColors: {
                    default: {
                        backgroundColor: DUST.x100,
                        color: DUST.x300,
                    },
                    primary: {
                        backgroundColor: PUMPKIN.x200,
                        color: PUMPKIN.x500,
                    },
                    success: {
                        backgroundColor: MEADOW.x100,
                        color: MEADOW.x600,
                    },
                    info: {
                        backgroundColor: INFO.x100,
                        color: INFO.x500,
                    },
                    error: {
                        backgroundColor: ERROR.x100,
                        color: ERROR.x500,
                    },
                },
            },
            radioButton: {
                default: {
                    backgroundColor: GENERICS.white,
                    borderColor: GREY_UPDATED.x400,
                    hover: {
                        borderColor: DUST.x300,
                    },
                    focus: {
                        boxShadow: INPUT_BOX_SHADOW,
                    },
                    disabled: {
                        backgroundColor: GREY_UPDATED.x200,
                        borderColor: GREY_UPDATED.x300,
                    },
                    checked: {
                        borderColor: DUST.x300,
                        disabled: {
                            borderColor: GREY_UPDATED.x300,
                            backgroundColor: GENERICS.white,
                        },
                    },
                    innerCircle: {
                        checked: {
                            backgroundColor: DUST.x300,
                            disabled: {
                                backgroundColor: GREY_UPDATED.x200,
                            },
                        },
                    },
                },
                primary: {
                    hover: {
                        borderColor: PUMPKIN.x500,
                    },
                    checked: {
                        borderColor: PUMPKIN.x500,
                    },
                    innerCircle: {
                        checked: {
                            backgroundColor: PUMPKIN.x500,
                        },
                    },
                },
                invalid: {
                    backgroundColor: ERROR.x100,
                    borderColor: ERROR.x500,
                },
            },
            paginationControls: {
                control: {
                    hover: {
                        borderColor: GREY.x500,
                    },
                    default: {
                        borderColor: GREY.x200,
                        backgroundColor: GENERICS.white,
                        color: GREY_UPDATED.x700,
                    },
                    active: {
                        borderColor: GREY.x200,
                        backgroundColor: GREY_UPDATED.x700,
                        color: GENERICS.white,
                    },
                    disabled: {
                        borderColor: GREY.x200,
                        backgroundColor: GENERICS.white,
                        color: GREY.x200,
                    },
                },
            },
            popover: {
                boxShadow: LIGHT_BOX_SHADOW,
                backgroundColor: GENERICS.white,
            },
            pricingButton: {
                disabled: {
                    color: GREY_UPDATED.x700,
                    backgroundColor: GREY_UPDATED.x100,
                },
            },
            pricingTooltip: {
                color: GENERICS.white,
                backgroundColor: DUST.x300,
            },
            tabs: {
                tabList: {
                    borderColor: GREY.x200,
                },
                tabPanel: {
                    borderColor: GREY.x200,
                    backgroundColor: GENERICS.white,
                },
                tab: {
                    backgroundColor: GENERICS.white,
                    borderColor: GREY.x200,
                    hover: {
                        backgroundColor: GREY.x50,
                    },
                    bottomLine: {
                        backgroundColor: GREY.x200,
                        active: { backgroundColor: GENERICS.white },
                    },
                    topLine: {
                        backgroundColor: GREY.x200,
                        active: { backgroundColor: PUMPKIN.x500 },
                    },
                },
            },
            sectionNavigation: {
                navigationList: {
                    backgroundColor: GENERICS.white,
                },
                link: {
                    color: GENERICS.black,
                    active: {
                        backgroundColor: DUST.x50,
                    },
                },
            },
            avatar: {
                borderColor: '#666',
                color: '#666',
            },
            textButton: {
                primary: {
                    color: MEADOW.x500,
                },
                secondary: {
                    color: DUST.x300,
                },
                disabled: {
                    color: GREY_UPDATED.x400,
                },
            },
            link: {
                primary: {
                    default: {
                        color: MEADOW.x500,
                    },
                    hover: {
                        color: MEADOW.x400,
                    },
                },
                secondary: {
                    default: {
                        color: DUST.x300,
                    },
                    hover: {
                        color: DUST.x200,
                    },
                },
                disabled: {
                    color: GREY_UPDATED.x400,
                },
            },
            dotsAnimation: {
                borderColor: DUST.x300,
                backgroundColor: GENERICS.white,
            },
            fieldError: {
                color: ERROR.x500,
            },
            fileDropOverlay: {
                backgroundColor: `${GREY_UPDATED.x100}f3`,
                borderColor: GREY_UPDATED.x400,
                color: GREY_UPDATED.x500,
            },
            toast: {
                label: {
                    color: GREY_UPDATED.x700,
                },
                closeIcon: {
                    color: GREY_UPDATED.x500,
                },
                loading: {
                    backgroundColor: GENERICS.white,
                    borderColor: GREY_UPDATED.x400,

                    icon: {
                        color: GREY_UPDATED.x300,
                    },
                },
                success: {
                    backgroundColor: SUCCESS.x100,
                    borderColor: `${SUCCESS.x500}66`,

                    icon: {
                        color: SUCCESS.x500,
                    },
                },
                error: {
                    backgroundColor: ERROR.x100,
                    borderColor: `${ERROR.x500}66`,

                    icon: {
                        color: ERROR.x500,
                    },
                },
                warning: {
                    backgroundColor: WARNING.x100,
                    borderColor: `${WARNING.x500}66`,

                    icon: {
                        color: WARNING.x500,
                    },
                },
                info: {
                    backgroundColor: INFO.x100,
                    borderColor: `${INFO.x500}66`,

                    icon: {
                        color: INFO.x500,
                    },
                },
            },
            alert: {
                error: {
                    backgroundColor: ERROR.x100,
                    borderColor: `${ERROR.x500}66`,

                    icon: {
                        color: ERROR.x500,
                    },
                },
                info: {
                    backgroundColor: INFO.x100,
                    borderColor: `${INFO.x500}66`,

                    icon: {
                        color: INFO.x500,
                    },
                },
                success: {
                    backgroundColor: SUCCESS.x100,
                    borderColor: `${SUCCESS.x500}66`,

                    icon: {
                        color: SUCCESS.x500,
                    },
                },
                warning: {
                    backgroundColor: WARNING.x100,
                    borderColor: `${WARNING.x500}66`,
                    icon: {
                        color: WARNING.x500,
                    },
                },
                label: {
                    color: GREY_UPDATED.x700,
                },
                closeIcon: {
                    color: GREY_UPDATED.x500,
                },
            },
            tooltipIcon: {
                tooltip: {
                    backgroundColor: GENERICS.white,
                    boxShadow: TOOLTIP_BOXSHADOW,
                    color: GREY_UPDATED.x700,
                },
            },
            filesListUpload: {
                default: {
                    backgroundColor: GREY_UPDATED.x100,
                    borderColor: GREY_UPDATED.x400,
                },
                error: {
                    borderColor: ERROR.x500,
                },
                trigger: {
                    hover: {
                        backgroundColor: GREY_UPDATED.x400,
                    },
                    link: {
                        color: DUST.x300,
                    },
                    heading: {
                        color: GREY_UPDATED.x700,
                    },
                    description: {
                        color: GREY_UPDATED.x500,
                    },
                },
                item: {
                    backgroundColor: GENERICS.white,
                    color: DUST.x300,
                },
                iconDelete: {
                    color: GENERICS.black,
                },
                iconUploading: {
                    color: GREY_UPDATED.x400,
                },
                iconUploaded: {
                    color: MEADOW.x500,
                },
            },
            toggle: {
                focus: {
                    boxShadow: INPUT_BOX_SHADOW,
                },
                switch: {
                    checked: { backgroundColor: MEADOW.x500 },
                    notChecked: { backgroundColor: GREY_UPDATED.x400 },
                    disabled: { backgroundColor: GREY.x200 },
                },
                thumb: {
                    backgroundColor: GENERICS.white,
                    boxShadow: '0 1px 2px 0 rgba(33, 33, 33, 0.15)',
                },
            },
            googleMap: {
                noExactLocationBar: {
                    color: GREY.x400,
                    boxShadow: EXPANDABLE_LIST_BOX_SHADOW,
                },
                areaCircle: {
                    color: TOMATO.x500,
                },
            },
            addContactModal: {
                title: {
                    color: GREY_UPDATED.x700,
                },
                uploadFileWrapper: {
                    backgroundColor: GREY_UPDATED.x100,
                    color: GREY_UPDATED.x500,
                    borderColor: GREY_UPDATED.x300,
                },
                uploading: {
                    backgroundColor: `${GREY_UPDATED.x200}c5`,
                },
                uploadContactPhoto: {
                    color: DUST.x300,
                },
                cameraIcon: {
                    color: GREY_UPDATED.x300,
                },
                asterisk: {
                    color: GREY_UPDATED.x500,
                },
            },
            formField: {
                label: {
                    default: {
                        color: GREY_UPDATED.x700,
                    },
                    inline: {
                        color: GREY_UPDATED.x600,
                    },
                    asterisk: {
                        color: GREY_UPDATED.x500,
                    },
                    tooltip: {
                        color: GREY_UPDATED.x400,
                    },
                },
            },
            modalInfoItem: {
                checkIcon: {
                    color: MEADOW.x500,
                },
                label: {
                    color: GREY_UPDATED.x500,
                },
                heading: { color: GREY_UPDATED.x700 },
            },
            consentPrompt: {
                boxShadow: '0px 15px 40px 10px rgba(33, 33, 33, 0.2)',
                backgroundColor: GENERICS.white,
            },
            pushNotificationsPrompt: {
                cookielike: {
                    color: GENERICS.white,
                    backgroundColor: DUST.x300,
                    closeIconColor: GREY_UPDATED.x500,
                },
            },
            dateInput: {
                icon: {
                    color: GREY.x400,
                },
                input: {
                    borderColor: GREY_UPDATED.x400,
                    color: '#22272a',
                    focus: {
                        borderColor: GREY.x400,
                        boxShadow: DATE_INPUT_SHADOW,
                    },
                },
            },
            clicktip: {
                description: {
                    boxShadow: TOOLTIP_BOXSHADOW,
                    dark: {
                        color: GENERICS.white,
                        backgroundColor: DUST.x300,
                    },
                    light: {
                        color: GREY_UPDATED.x700,
                        backgroundColor: GENERICS.white,
                    },
                },
                button: {
                    dark: {
                        color: GREY_UPDATED.x700,
                        focus: {
                            borderColor: DUST.x300,
                        },
                    },
                    light: {
                        color: GREY_UPDATED.x400,
                        focus: {
                            borderColor: GREY_UPDATED.x200,
                        },
                    },
                },
                icon: {
                    dark: {
                        color: GREY_UPDATED.x400,
                        hover: {
                            color: GREY_UPDATED.x500,
                        },
                        focus: {
                            color: DUST.x300,
                        },
                    },
                    light: {
                        color: GREY_UPDATED.x400,
                        hover: {
                            color: GREY_UPDATED.x200,
                        },
                        focus: {
                            color: GREY_UPDATED.x200,
                        },
                    },
                },
            },
            miniGallery: {
                index: {
                    backgroundColor: `${DUST.x300}30`,
                },
                bullet: {
                    base: {
                        backgroundColor: `${DUST.x300}30`,
                        borderColor: GENERICS.white,
                    },
                    small: {
                        backgroundColor: GENERICS.white,
                    },
                },
                background: `linear-gradient(180deg, rgba(35, 46, 63, 0) 72.92%, ${DUST.x300} 100%)`,
            },
            baseArrow: {
                backgroundColor: `${DUST.x300}30`,
                color: GENERICS.white,
                hover: {
                    color: DUST.x100,
                },
            },
            sortingDropdown: {
                buttonGroup: {
                    button: {
                        base: {
                            color: GREY_UPDATED.x700,
                            borderColor: GREY_UPDATED.x300,
                            backgroundColor: GENERICS.white,
                        },
                        hover: {
                            backgroundColor: PUMPKIN.x100,
                            color: PUMPKIN.x500,
                        },
                    },
                },
            },
            searchResultCount: {
                label: {
                    color: DUST.x200,
                },
                count: {
                    color: DUST.x400,
                },
            },
            modalWithSlides: {
                footer: {
                    borderColor: GREY_UPDATED.x200,
                },
                previousSlideButton: {
                    backgroundColor: DUST.x50,
                },
                slideIndicator: {
                    borderColor: GREY_UPDATED.x400,
                    backgroundColor: GENERICS.white,
                    active: {
                        backgroundColor: GREY_UPDATED.x400,
                    },
                },
            },
        },
        pricing: {
            addOns: {
                infoModal: {
                    rentPro: {
                        color: PUMPKIN.x500,
                    },
                },
            },
            banner: {
                title: {
                    color: GREY_UPDATED.x700,
                },
                message: {
                    color: GREY_UPDATED.x500,
                },
            },
            bottomSeparator: {
                backgroundColor: DUST.x50,
            },
            contentContainer: {
                backgroundColor: GENERICS.white,
            },
            headingContainer: {
                backgroundColor: GREY.x200,
            },
            helpText: {
                borderColor: '#e1e1e1',
                color: '#8f8f8f',
            },
            link: {
                color: MEADOW.x600,
            },
            workingHours: {
                color: GREY.x400,
            },
            pricingTabs: {
                tabs: {
                    default: {
                        color: GENERICS.white,
                        backgroundColor: '#8f8f8f',
                    },
                    selected: {
                        color: GENERICS.text,
                        backgroundColor: GENERICS.white,
                    },
                },
            },
            feedbackButton: {
                backgroundColor: MEADOW.x500,
                color: GENERICS.white,
                boxShadow: '0 0 7px 0 #222',
            },
            phoneNumber: {
                color: GREY_UPDATED.x700,
            },
            pricingBanner: {
                backgroundColor: DUST.x300,
                color: GENERICS.white,
            },
            pricingAccordion: {
                button: {
                    backgroundColor: DUST.x300,
                    color: GENERICS.white,
                },
            },
            pricingSwitcher: {
                default: {
                    color: DUST.x300,
                    backgroundColor: GENERICS.white,
                },
                selected: {
                    color: GENERICS.white,
                    backgroundColor: DUST.x300,
                },
            },
            regularPricing: {
                rentRoomBanner: {
                    container: {
                        borderColor: GREY_UPDATED.x300,
                    },
                    iconWrapper: {
                        fill: MEADOW.x500,
                        backgroundColor: MEADOW.x200,
                    },
                },
            },
            tabsSwitcher: {
                border: {
                    color: GREY_UPDATED.x300,
                },
                default: {
                    backgroundColor: GREY_UPDATED.x200,
                    color: GREY_UPDATED.x700,
                },
                selected: {
                    backgroundColor: GENERICS.white,
                    color: GREY_UPDATED.x700,
                },
                icon: {
                    color: MEADOW.x500,
                    backgroundColor: MEADOW.x200,
                },
            },
        },
        postingForm: {
            multimediaSection: {
                infoBox: { color: GREY_UPDATED.x500 },
            },
            pageHeading: {
                backgroundColor: GENERICS.white,
                color: GREY_UPDATED.x700,
            },
            formGroup: {
                description: {
                    color: GREY_UPDATED.x500,
                },
                descriptionHighlighted: {
                    color: GREY_UPDATED.x700,
                },
                separator: {
                    borderColor: '#d3d3d3',
                },
            },
            heading: {
                color: GREY_UPDATED.x700,
            },
            fieldHelper: {
                backgroundColor: GREY_UPDATED.x100,
                color: GREY_UPDATED.x500,
            },
            fieldCounter: {
                color: GREY_UPDATED.x500,
            },
            formButtonGroupField: {
                button: {
                    color: GREY_UPDATED.x600,
                    backgroundColor: GENERICS.white,
                    borderColor: GREY_UPDATED.x400,
                    focus: {
                        borderColor: DUST.x300,
                    },
                    hover: {
                        backgroundColor: GREY_UPDATED.x100,
                    },
                    checked: {
                        color: PUMPKIN.x500,
                        backgroundColor: PUMPKIN.x100,
                    },
                    invalid: {
                        borderColor: ERROR.x500,
                    },
                },
            },
            formCollapsible: {
                details: {
                    backgroundColor: GENERICS.white,
                },
                summary: {
                    color: GREY_UPDATED.x700,
                    arrowIcon: {
                        color: DUST.x300,
                    },
                    focus: {
                        borderColor: DUST.x300,
                    },
                },
                contentContainer: {
                    borderColor: GREY_UPDATED.x300,
                },
            },
            checkboxList: {
                separator: {
                    color: GREY_UPDATED.x400,
                },
                description: {
                    color: GREY_UPDATED.x500,
                },
            },
            imageGallery: {
                description: {
                    color: GREY_UPDATED.x500,
                },
                image: {
                    default: {
                        backgroundColor: GREY_UPDATED.x100,
                    },
                    main: {
                        borderColor: PUMPKIN.x500,
                    },
                    uploading: {
                        backgroundColor: `${GREY_UPDATED.x200}c5`,
                    },
                    layer: {
                        backgroundColor: `${GREY_UPDATED.x700}94`,
                    },
                    layerButton: {
                        backgroundColor: GENERICS.white,
                        color: DUST.x300,
                    },
                    label: {
                        backgroundColor: PUMPKIN.x500,
                        color: GENERICS.white,
                    },
                },
                orderDropPlaceholder: {
                    backgroundColor: GREY_UPDATED.x100,
                    borderColor: GREY_UPDATED.x400,
                },
                selectFiles: {
                    icon: {
                        backgroundColor: DUST.x300,
                        color: GENERICS.white,
                    },
                    default: {
                        borderColor: GREY_UPDATED.x400,
                        backgroundColor: GREY_UPDATED.x100,
                    },
                    error: {
                        borderColor: ERROR.x500,
                    },
                    hover: {
                        backgroundColor: GREY_UPDATED.x200,
                    },
                },
            },
            formVideoUrl: {
                description: {
                    color: GREY_UPDATED.x500,
                },
                icon: {
                    color: GREY_UPDATED.x300,
                },
                videoInput: {
                    borderColor: GREY_UPDATED.x400,
                    backgroundColor: GREY_UPDATED.x100,
                },
                instructions: {
                    color: GREY_UPDATED.x500,
                },
            },
            contactDetails: {
                addContactOption: {
                    color: MEADOW.x600,
                    borderColor: GREY_UPDATED.x200,
                },
                card: {
                    backgroundColor: GENERICS.white,
                    color: DUST.x300,
                },
                cardName: {
                    color: GREY_UPDATED.x700,
                },
                cardIcon: {
                    color: DUST.x200,
                },
            },
            locationPicker: {
                placeholderIcon: {
                    color: GREY_UPDATED.x500,
                },
                map: {
                    borderColor: GREY_UPDATED.x400,
                },
                mapRadius: {
                    backgroundColor: GREY_UPDATED.x500,
                },
            },
            moderationFormField: {
                iconButtonLock: {
                    default: {
                        color: DUST.x300,
                    },
                    hover: {
                        color: DUST.x400,
                    },
                },
                iconFieldLocked: {
                    color: GREY_UPDATED.x500,
                },
            },
        },
        search: {
            overlay: {
                backgroundColor: GENERICS.white,
            },
            mobileAppBanner: {
                color: GENERICS.white,
                backgroundColor: DUST.x300,
            },
            page: {
                backgroundColor: GENERICS.white,
            },
            noSearchResults: {
                backgroundColor: GENERICS.white,
                link: {
                    color: DUST.x300,
                },
            },
            investmentsWidget: {
                base: {
                    borderTop: GREY_UPDATED.x300,
                },
            },
            investmentUnit: {
                addToFavoritesIcon: {
                    color: GENERICS.white,
                },
                image: {
                    background: `linear-gradient(180deg, rgba(35, 46, 63, 0) 72.92%, ${DUST.x300} 100%)`,
                },
                infoContainer: {
                    backgroundColor: GENERICS.white,
                    color: GREY_UPDATED.x700,
                    businessName: {
                        color: GREY_UPDATED.x500,
                    },
                    location: {
                        color: GREY_UPDATED.x500,
                    },
                },
            },
            listing: {
                title: {
                    borderColor: GREY_UPDATED.x300,
                    color: TOMATO.x600,
                },
                titleLink: {
                    color: MEADOW.x500,
                    hover: {
                        color: MEADOW.x400,
                    },
                },
            },
            listingItem: {
                base: {
                    backgroundColor: GENERICS.white,
                    meta: {
                        map: {
                            color: GENERICS.black,
                        },
                    },
                    subtitle: {
                        color: GREY_UPDATED.x600,
                    },
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    actionIcon: {
                        primary: {
                            borderColor: PUMPKIN.x500,
                            fill: PUMPKIN.x500,
                        },
                        secondary: {
                            borderColor: GENERICS.black,
                            fill: MEADOW.x500,
                            backgroundColor: GENERICS.white,
                        },
                        ghost: {
                            borderColor: GENERICS.white,
                            fill: GENERICS.white,
                        },
                        disabled: {
                            color: GREY_UPDATED.x300,
                        },
                    },
                    openDays: {
                        backgroundColor: MEADOW.x500,
                        color: GENERICS.white,
                    },
                    dateCreatedBadge: {
                        backgroundColor: GENERICS.white,
                        color: DUST.x300,
                    },
                    icon: {
                        color: GREY_UPDATED.x500,
                        backgroundColor: GREY_UPDATED.x200,
                    },
                    remoteService: {
                        color: GREY_UPDATED.x700,
                    },
                    logo: {
                        backgroundColor: GENERICS.white,
                        borderColor: GREY_UPDATED.x300,
                        hover: {
                            borderColor: DUST.x400,
                        },
                    },
                    header: {
                        backgroundColor: DUST.x300,
                        color: GENERICS.white,
                    },
                },
                link: {
                    color: GENERICS.text,
                },
                mobile: {
                    price: {
                        color: GREY_UPDATED.x700,
                    },
                },
                desktop: {
                    price: {
                        backgroundColor: GENERICS.white,
                        color: GREY_UPDATED.x700,
                    },
                    owner: {
                        color: GREY_UPDATED.x500,
                        title: {
                            color: GREY_UPDATED.x700,
                        },
                    },
                },
                seenAd: {
                    font: {
                        color: GREY_UPDATED.x500,
                    },
                    image: {
                        default: {
                            backgroundColor: GENERICS.white,
                        },
                        seen: {
                            backgroundColor: GREY_UPDATED.x400,
                        },
                    },
                },
            },
            listingPanel: {
                goToMap: {
                    icon: {
                        color: GENERICS.white,
                    },
                },
                adsNumber: {
                    label: {
                        color: DUST.x200,
                    },
                    text: {
                        color: DUST.x400,
                    },
                },
                sorting: {
                    label: {
                        color: GREY_UPDATED.x700,
                    },
                    filterIcon: {
                        color: GREY_UPDATED.x500,
                    },
                },
                sortingInfoLink: {
                    color: '#4d5055',
                },
            },
            mobileFilterButton: {
                filteringButton: {
                    borderColor: GREY_UPDATED.x200,
                    backgroundColor: PUMPKIN.x100,
                    color: PUMPKIN.x500,
                },
                buttonGroup: {
                    borderColor: GREY_UPDATED.x300,
                    backgroundColor: GENERICS.white,
                    color: GREY_UPDATED.x700,
                },
                counter: {
                    backgroundColor: PUMPKIN.x500,
                    color: GENERICS.white,
                },
            },
            mapView: {
                topBar: {
                    base: {
                        wrapper: {
                            borderColor: GREY_UPDATED.x300,
                        },
                        crossIcon: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                },
                sideBar: {
                    base: {
                        backgroundColor: GREY_UPDATED.x100,
                    },
                    listingMapItem: {
                        base: {
                            backgroundColor: GENERICS.white,
                            openDays: {
                                backgroundColor: MEADOW.x500,
                                color: GENERICS.white,
                            },
                            logo: {
                                backgroundColor: GENERICS.white,
                                borderColor: GREY_UPDATED.x300,
                            },
                        },
                        link: {
                            color: GENERICS.text,
                            backgroundColor: GENERICS.white,
                        },
                        mobile: {
                            price: {
                                color: GREY_UPDATED.x700,
                            },
                        },
                        desktop: {
                            price: {
                                backgroundColor: GENERICS.white,
                                color: GREY_UPDATED.x700,
                            },
                            owner: {
                                color: GREY_UPDATED.x500,
                                title: {
                                    color: GREY_UPDATED.x700,
                                },
                            },
                        },
                    },
                },
                selectedAds: {
                    base: {
                        backgroundColor: GREY_UPDATED.x100,
                        boxShadow: LIGHT_BOX_SHADOW,
                        scrollBar: {
                            color: GREY_UPDATED.x200,
                            backgroundColor: GENERICS.white,
                        },
                    },
                },
            },
            map: {
                pins: {
                    cluster: {
                        color: GENERICS.white,
                        boxShadow: MAP_CLUSTER_PIN_BOXSHADOW,
                    },
                    circleColor: {
                        basic: {
                            fill: GENERICS.white,
                            stroke: DUST.x300,
                            textColor: DUST.x300,
                        },
                        active: {
                            fill: DUST.x300,
                            stroke: DUST.x300,
                            textColor: GENERICS.white,
                        },
                        visited: {
                            fill: '#D3D5D9',
                            stroke: GREY_UPDATED.x500,
                            textColor: GREY_UPDATED.x600,
                        },
                    },
                },
                loading: {
                    boxShadow: LIGHT_BOX_SHADOW,
                },
                controls: {
                    bottomBar: {
                        backgroundColor: GENERICS.white,
                    },
                },
            },
            searchForm: {
                titleWrapper: {
                    backgroundColor: GENERICS.white,
                },
                close: {
                    fill: GENERICS.text,
                    color: GENERICS.text,
                },
                fieldSectionSeparator: {
                    borderColor: GREY.x200,
                },
                mobileSearchAction: {
                    backgroundColor: GENERICS.white,
                },
                remoteServicesTooltip: {
                    color: GREY_UPDATED.x400,
                },
                specialOffersToggleTooltip: {
                    color: GREY_UPDATED.x400,
                },
                togglePush: {
                    icon: {
                        color: GREY_UPDATED.x400,
                    },
                    separator: {
                        color: GREY_UPDATED.x200,
                    },
                    closeIcon: {
                        color: GREY_UPDATED.x400,
                    },
                },
                dropdown: {
                    placeholder: {
                        color: GREY_UPDATED.x600,
                    },
                },
                saveSearchResults: {
                    color: GENERICS.white,
                    backgroundColor: GENERICS.white,
                    tooltip: {
                        color: GREY_UPDATED.x400,
                    },
                    checkIcon: {
                        color: GENERICS.white,
                    },
                    stickyBar: {
                        backgroundColor: GENERICS.white,
                        boxShadow: STICKY_BAR_BOXSHADOW,
                    },
                },
                rangeField: {
                    base: {
                        borderColor: GREY_UPDATED.x400,
                        backgroundColor: GENERICS.white,
                    },
                    active: {
                        backgroundColor: PUMPKIN.x100,
                    },
                    hover: {
                        borderColor: DUST.x300,
                    },
                    focus: {
                        boxShadow: INPUT_BOX_SHADOW,
                    },
                },
                expandedSearchFormButton: {
                    indicator: {
                        backgroundColor: PUMPKIN.x500,
                        color: GENERICS.white,
                    },
                },
                togglePushConsent: {
                    backgroundColor: DUST.x400,
                    color: DUST.x50,
                    boxShadow: TOOLTIP_BOXSHADOW,
                },
                specialOffer: {
                    color: GENERICS.text,
                },
            },
            dropdownWithCheckboxes: {
                base: {
                    borderColor: GREY_UPDATED.x400,
                    color: GREY_UPDATED.x600,
                    backgroundColor: GENERICS.white,
                },
                hover: {
                    borderColor: DUST.x300,
                },
                expanded: {
                    boxShadow: INPUT_BOX_SHADOW,
                },
                selected: {
                    backgroundColor: PUMPKIN.x100,
                },
                closeIcon: {
                    color: PUMPKIN.x500,
                },
                option: {
                    hover: {
                        backgroundColor: DUST.x50,
                    },
                },
            },
            notificationsReleaseModal: {
                heading: {
                    color: GREY_UPDATED.x700,
                },
                info: {
                    color: GREY_UPDATED.x600,
                },
                note: {
                    color: GREY_UPDATED.x700,
                },
                slideIndicator: {
                    borderColor: MEADOW.x500,
                    backgroundColor: GENERICS.transparent,
                    active: {
                        backgroundColor: MEADOW.x500,
                    },
                },
                generalInfoSecondPart: {
                    backgroundColor: DUST.x300,
                },
            },
            shortSubscriptionFlow: {
                forgotPasswordLink: {
                    default: {
                        color: MEADOW.x500,
                    },
                    hover: {
                        color: MEADOW.x400,
                    },
                },
            },
            priceBeforeDiscount: {
                price: {
                    color: GREY_UPDATED.x500,
                },
                tooltip: {
                    color: GREY_UPDATED.x400,
                },
            },
            breadcrumbs: {
                link: {
                    color: GENERICS.text,
                    textDecorationColor: GREY_UPDATED.x500,
                },
                label: {
                    color: GREY_UPDATED.x500,
                },
            },
            seoAdsDescription: {
                section: {
                    borderColor: GREY_UPDATED.x300,
                    color: GREY_UPDATED.x700,
                },
            },
        },
        myAccount: {
            payments: {
                invoiceDetailsModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    contentSeparator: {
                        borderColor: GREY_UPDATED.x300,
                    },
                },
                nextInvoiceTable: {
                    priceLabel: {
                        color: DUST.x300,
                    },
                    priceValue: {
                        color: SUCCESS.x500,
                    },
                },
                pastInvoiceTable: {
                    status: {
                        paid: {
                            color: SUCCESS.x500,
                        },
                        unpaid: {
                            color: ERROR.x500,
                        },
                    },
                    pdfIcon: {
                        color: TOMATO.x500,
                    },
                    labelCell: {
                        color: SUCCESS.x500,
                    },
                },
                sectionBanner: {
                    bannerContainer: {
                        boxShadow: SUMMARY_CONTAINER_BOX_SHADOW,
                    },
                },
                billingInfoBanner: {
                    bannerText: {
                        color: GREY.x400,
                    },
                },
                suspendedUserBanner: {
                    messageText: {
                        color: PUMPKIN.x600,
                    },
                    descriptionText: {
                        color: GENERICS.text,
                    },
                },
                sharedTables: {
                    tableRow: {
                        color: DUST.x300,
                    },
                    mobileLabel: {
                        color: DUST.x300,
                    },
                    tableHead: {
                        color: GREY.x400,
                    },
                    link: {
                        color: MEADOW.x500,
                    },
                },
                forecastDetailsSlideOver: {
                    detailsTab: {
                        button: {
                            color: GREY_UPDATED.x600,
                            backgroundColor: GENERICS.white,
                            borderColor: GREY_UPDATED.x400,
                            focus: {
                                borderColor: DUST.x300,
                            },
                            hover: {
                                backgroundColor: GREY_UPDATED.x100,
                            },
                            selected: {
                                color: PUMPKIN.x500,
                                backgroundColor: PUMPKIN.x100,
                            },
                        },
                    },
                    tabPanelTitle: {
                        color: DUST.x300,
                    },
                    tabPanelTitleHighlight: {
                        color: PUMPKIN.x500,
                    },
                },
                paymentSummary: {
                    heading: {
                        color: GREY_UPDATED.x700,
                    },
                    section: {
                        backgroundColor: GENERICS.white,
                    },
                    sectionHeading: {
                        color: GREY_UPDATED.x700,
                    },
                    sectionIconWrapper: {
                        backgroundColor: DUST.x50,
                    },
                    sectionIcon: {
                        color: DUST.x300,
                    },
                    billingDetails: {
                        detailsContainer: {
                            borderColor: GREY_UPDATED.x300,
                        },
                        billingDetailLabel: {
                            color: GREY_UPDATED.x700,
                        },
                        billingDetailValue: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                    paymentAmount: {
                        infoTextContainer: {
                            borderColor: GREY_UPDATED.x300,
                            color: GREY_UPDATED.x500,
                        },
                        summaryText: {
                            color: GREY_UPDATED.x700,
                        },
                    },
                },
                slideOver: {
                    title: {
                        color: DUST.x300,
                    },
                },
                summarySection: {
                    container: {
                        backgroundColor: GREY_UPDATED.x200,
                    },
                    innerContainer: {
                        backgroundColor: GENERICS.white,
                    },
                    text: {
                        borderColor: GREY_UPDATED.x300,
                    },
                    link: {
                        color: MEADOW.x500,
                    },
                },
                forecastSection: {
                    container: {
                        backgroundColor: DUST.x300,
                        boxShadow: LIGHT_BOX_SHADOW,
                    },
                    title: {
                        color: GENERICS.white,
                    },
                    amount: {
                        color: GENERICS.white,
                    },
                    billingPeriodText: {
                        color: GENERICS.white,
                    },
                    suspendedAccount: {
                        title: {
                            color: GENERICS.white,
                        },
                        description: {
                            color: GENERICS.white,
                        },
                    },
                    remainingDaysSection: {
                        color: GREY_UPDATED.x400,
                    },
                    tooltipIconInfo: {
                        color: INFO.x500,
                    },
                },
                invoicesTable: {
                    invoiceTableRow: {
                        color: GREY_UPDATED.x600,
                        unpaidInvoice: {
                            color: ERROR.x500,
                        },
                    },
                    status: {
                        paid: {
                            backgroundColor: MEADOW.x100,
                            color: MEADOW.x600,
                        },
                        unpaid: {
                            backgroundColor: PUMPKIN.x100,
                            color: ERROR.x500,
                        },
                    },
                    title: {
                        color: GREY_UPDATED.x500,
                    },
                    tooltipIconInfo: {
                        color: GREY_UPDATED.x500,
                    },
                    invoicesTableContainer: {
                        boxShadow: LIGHT_BOX_SHADOW,
                        background: GENERICS.white,
                    },
                    invoicesListHeader: {
                        background: GREY_UPDATED.x100,
                    },
                    headLabelCell: {
                        color: GREY_UPDATED.x500,
                    },
                    totalResultsLabel: {
                        color: DUST.x200,
                    },
                    totalResultsCount: {
                        color: DUST.x400,
                    },
                    downloadInvoiceIcon: {
                        color: DUST.x300,
                    },
                    noInvoicesLabel: {
                        color: GREY_UPDATED.x500,
                    },
                    noInvoicesIcon: {
                        color: GREY_UPDATED.x500,
                    },
                    noInvoicesContainer: {
                        color: GREY_UPDATED.x100,
                        backgroundColor: GENERICS.white,
                    },
                    pagination: {
                        container: {
                            borderColor: GREY_UPDATED.x300,
                        },
                        disabled: {
                            borderColor: GREY_UPDATED.x300,
                            backgroundColor: GENERICS.white,
                            color: GREY_UPDATED.x300,
                        },
                    },
                },
                noInvoices: {
                    heading: {
                        color: GREY_UPDATED.x500,
                    },
                },
                paymentsForm: {
                    sectionContainer: {
                        backgroundColor: DUST.x100,
                    },
                    errorMessage: {
                        color: ERROR.x500,
                    },
                },
                unifiedPageLayout: {
                    helpLinkContainer: {
                        color: GREY_UPDATED.x500,
                        borderColor: GREY_UPDATED.x300,
                    },
                },
                invoicesDetailsSlideOver: {
                    totalAmount: {
                        color: DUST.x300,
                    },
                    mainInformation: {
                        color: GREY_UPDATED.x600,
                    },
                    invoiceDetailsItemTotalPrice: { color: GREY_UPDATED.x400 },
                    invoiceDetailsItemCount: { color: GREY_UPDATED.x500 },
                    invoiceDetailsItemPrice: { color: GREY_UPDATED.x600 },
                    invoiceDetailsElement: { color: GREY_UPDATED.x600 },
                    collapsibleSummary: { backgroundColor: GREY_UPDATED.x200 },
                    collapsibleSummaryInformationLabel: {
                        color: GREY_UPDATED.x600,
                    },
                    collapsibleSummaryInformationValue: {
                        color: GREY_UPDATED.x700,
                    },
                },
                forecastedDetailsSlideOver: {
                    totalAmount: {
                        color: DUST.x300,
                    },
                    forecastedItemTotalPrice: { color: GREY_UPDATED.x400 },
                    forecastedItemCount: { color: GREY_UPDATED.x500 },
                    forecastedBundleInfo: { color: GREY_UPDATED.x500 },
                    forecastedItemPrice: { color: GREY_UPDATED.x600 },
                    forecastedElement: { color: GREY_UPDATED.x600 },
                    forecastedAmountDue: { color: MEADOW.x500 },
                    forecastedDiscountPrice: { color: MEADOW.x500 },
                },
                balanceSection: {
                    container: {
                        backgroundColor: DUST.x300,
                    },
                    heading: {
                        color: GENERICS.white,
                    },
                    invoicesInfoText: {
                        color: GREY_UPDATED.x300,
                    },
                    invoicesInfoIcon: {
                        success: {
                            color: SUCCESS.x500,
                        },
                        warning: {
                            color: ERROR.x500,
                        },
                    },
                },
                notificationsSection: {
                    heading: {
                        color: GREY_UPDATED.x500,
                    },
                    cardContainer: {
                        backgroundColor: GENERICS.white,
                    },
                    iconWrapper: {
                        backgroundColor: DUST.x100,
                        color: DUST.x300,
                    },
                    helpText: {
                        color: GREY_UPDATED.x500,
                    },
                    informationText: {
                        color: GREY_UPDATED.x500,
                    },
                },
                billingSection: {
                    heading: {
                        color: GREY_UPDATED.x500,
                    },
                    cardContainer: {
                        backgroundColor: GENERICS.white,
                    },
                    iconWrapper: {
                        backgroundColor: DUST.x100,
                        color: DUST.x300,
                    },
                    helpText: {
                        color: GREY_UPDATED.x500,
                        borderColor: GREY_UPDATED.x300,
                    },
                    informationText: {
                        color: GREY_UPDATED.x500,
                    },
                },
            },
            promote: {
                content: {
                    backgroundColor: DUST.x50,
                },
                promotionsInfoModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    description: {
                        color: GREY_UPDATED.x700,
                    },
                    detailsText: {
                        color: GREY_UPDATED.x700,
                    },
                    checkIcon: {
                        color: MEADOW.x500,
                    },
                    footerNote: {
                        color: GREY_UPDATED.x700,
                    },
                    footerLink: {
                        color: DUST.x300,
                    },
                },
                automaticVasSchedulingInfoModal: {
                    checkIcon: {
                        color: MEADOW.x500,
                    },
                    footerNote: {
                        color: GREY_UPDATED.x700,
                    },
                    footerLink: {
                        color: DUST.x300,
                    },
                    footer: {
                        borderColor: GREY_UPDATED.x200,
                    },
                    previousSlideButton: {
                        backgroundColor: DUST.x50,
                    },
                    slideIndicator: {
                        borderColor: GREY_UPDATED.x400,
                        backgroundColor: GENERICS.white,
                        active: {
                            backgroundColor: GREY_UPDATED.x400,
                        },
                    },
                },
                mainSection: {
                    advertSection: {
                        outerContainer: {
                            borderColor: GREY_UPDATED.x300,
                        },
                        autorenewalLabel: {
                            color: GREY_UPDATED.x700,
                            disabled: {
                                color: GREY_UPDATED.x400,
                            },
                        },
                        autorenewalTooltip: {
                            color: GREY_UPDATED.x400,
                        },
                        autorenewalDescription: {
                            color: GREY_UPDATED.x500,
                        },
                        dateLabel: {
                            color: GREY_UPDATED.x500,
                        },
                        date: {
                            color: GREY_UPDATED.x700,
                        },
                    },
                    priceTooltip: {
                        color: GREY_UPDATED.x400,
                    },
                    priceInfo: {
                        color: GREY_UPDATED.x500,
                    },
                    promotionsHeading: {
                        color: GREY_UPDATED.x700,
                    },
                    summarySidebar: {
                        backgroundColor: GENERICS.white,
                        boxShadow: LIGHT_BOX_SHADOW,
                    },
                    summaryHeading: {
                        color: GREY_UPDATED.x700,
                    },
                    pricesContainer: {
                        borderColor: GREY_UPDATED.x300,
                    },
                    subheading: {
                        color: GREY_UPDATED.x500,
                    },
                    priceItem: {
                        color: GREY_UPDATED.x700,
                    },
                    totalPrice: {
                        color: GREY_UPDATED.x700,
                    },
                    note: {
                        color: GREY_UPDATED.x500,
                    },
                    expandButton: {
                        color: GREY_UPDATED.x700,
                    },
                },
                olxPromotionSection: {
                    card: {
                        focus: {
                            outlineColor: GREY_UPDATED.x400,
                        },
                    },
                },
                packageCard: {
                    wrapper: {
                        outlineColor: DUST.x300,
                        backgroundColor: GENERICS.white,
                        color: GREY_UPDATED.x700,
                        disabled: {
                            color: GREY_UPDATED.x500,
                        },
                        invalid: {
                            outlineColor: ERROR.x500,
                        },
                    },
                    divider: {
                        backgroundColor: GREY_UPDATED.x200,
                    },
                    listItem: {
                        disabled: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                    stripe: {
                        basic: {
                            borderColor: '#3A77FF',
                            disabled: {
                                borderColor: '#98B6FB',
                            },
                        },
                        normal: {
                            borderColor: '#36B3C6',
                            disabled: {
                                borderColor: '#96D4DE',
                            },
                        },
                        premium: {
                            borderColor: '#FFC624',
                            disabled: {
                                borderColor: '#FADE8C',
                            },
                        },
                    },
                },
                promotionCard: {
                    dateMark: {
                        color: GREY_UPDATED.x500,
                    },
                    selectedPromotions: {
                        color: GREY_UPDATED.x500,
                    },
                    settingsLabel: {
                        color: GREY_UPDATED.x600,
                    },
                },
                promotionRow: {
                    activeLabel: {
                        backgroundColor: DUST.x100,
                        color: DUST.x300,
                    },
                    pendingLabel: {
                        backgroundColor: MEADOW.x100,
                        color: MEADOW.x600,
                    },
                    backgroundColor: GENERICS.white,
                    checked: {
                        borderColor: DUST.x300,
                    },
                    default: {
                        borderColor: GENERICS.white,
                    },
                    errorWrapper: {
                        color: ERROR.x500,
                    },
                    invalid: {
                        borderColor: ERROR.x500,
                    },
                    price: {
                        color: GREY_UPDATED.x700,
                    },
                    heading: {
                        color: GREY_UPDATED.x700,
                    },
                    description: {
                        color: DUST.x300,
                    },
                },
                sellWithOtodomCard: {
                    wrapper: {
                        backgroundColor: GENERICS.white,
                    },
                    statusBanner: {
                        submitted: {
                            backgroundColor: INFO.x500,
                        },
                        active: {
                            backgroundColor: MEADOW.x500,
                        },
                    },
                    separator: {
                        backgroundColor: GREY_UPDATED.x400,
                    },
                    dateLabel: {
                        color: GREY_UPDATED.x500,
                    },
                    date: {
                        color: GREY_UPDATED.x700,
                    },
                    statusBannerText: {
                        color: GENERICS.white,
                    },
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    expandableSectionItem: {
                        color: GREY_UPDATED.x700,
                    },
                    titleTooltip: {
                        color: GREY_UPDATED.x400,
                    },
                    listItemLabel: {
                        color: DUST.x300,
                    },
                    checkIcon: {
                        color: MEADOW.x500,
                    },
                    collapsibleSummary: {
                        color: MEADOW.x500,
                    },
                    expandableSection: {
                        borderColor: GREY_UPDATED.x300,
                    },
                    expandableSectionTitle: {
                        color: GREY_UPDATED.x700,
                    },
                },
                sellWithOtodomModal: {
                    heading: {
                        color: GREY_UPDATED.x700,
                    },
                    listItem: {
                        color: GREY_UPDATED.x700,
                    },
                    note: {
                        borderColor: GREY_UPDATED.x300,
                        color: GREY_UPDATED.x700,
                    },
                    link: {
                        color: DUST.x300,
                    },
                    label: {
                        color: GREY_UPDATED.x700,
                    },
                    offerPolicyLabel: {
                        color: GREY_UPDATED.x600,
                    },
                },
                reportSaleModal: {
                    heading: {
                        color: GREY_UPDATED.x700,
                    },
                    label: {
                        color: GREY_UPDATED.x700,
                    },
                },
                warningPromoteModal: {
                    content: {
                        color: GREY_UPDATED.x700,
                    },
                },
                schedulePromotionModal: {
                    label: {
                        color: GREY_UPDATED.x700,
                    },
                    suffixText: {
                        color: GREY_UPDATED.x600,
                    },
                },
            },
            paywall: {
                commonSection: {
                    backgroundColor: GENERICS.white,
                    mobileSeparator: {
                        color: GREY_UPDATED.x300,
                    },
                },
                bundleSection: {
                    card: {
                        text: {
                            color: GREY_UPDATED.x700,
                        },
                        selected: {
                            border: {
                                color: DUST.x300,
                            },
                        },
                        focused: {
                            outline: {
                                color: GREY_UPDATED.x500,
                            },
                        },
                        subtitle: {
                            color: GREY_UPDATED.x500,
                        },
                        badge: {
                            color: GENERICS.white,
                            backgroundColor: PUMPKIN.x500,
                        },
                        benefitDetailItem: {
                            before: {
                                backgroundColor: GREY_UPDATED.x700,
                            },
                        },
                    },
                    rentRoomBundle: {
                        iconWrapper: {
                            backgroundColor: MEADOW.x200,
                            fill: MEADOW.x500,
                        },
                    },
                },
                promotionRow: {
                    tooltipIcon: {
                        color: GREY_UPDATED.x400,
                    },
                },
                billingSection: {
                    address: {
                        color: GREY_UPDATED.x600,
                    },
                },
            },
            schedulePromote: {
                mainSection: {
                    heading: {
                        color: GREY_UPDATED.x700,
                    },
                },
                content: {
                    backgroundColor: DUST.x50,
                },
                selectedAdsList: {
                    table: {
                        boxShadow: '2px 2px 16px rgba(35, 46, 63, 0.15)',
                    },
                    tableHead: {
                        backgroundColor: GREY_UPDATED.x100,
                    },
                    tableRow: {
                        backgroundColor: GENERICS.white,
                        borderColor: GREY_UPDATED.x200,
                    },
                    tableHeadCell: {
                        color: GREY_UPDATED.x500,
                    },
                    tableCell: {
                        lightColor: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                    deleteIcon: {
                        color: GREY_UPDATED.x300,
                    },
                },
                removeAdModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                },
            },
            shared: {
                accountStatisticsReport: {
                    downloadLink: {
                        icon: { color: MEADOW.x500 },
                        label: { color: MEADOW.x500 },
                    },
                },
                confirmationModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                },
                dateRangeGroup: {
                    buttonWrapper: {
                        borderColor: GREY.x200,
                    },
                    rangeButton: {
                        default: {
                            backgroundColor: GENERICS.white,
                            color: GREY_UPDATED.x700,
                            borderColor: GREY.x200,
                        },
                        selected: {
                            backgroundColor: DUST.x300,
                            color: GENERICS.white,
                        },
                    },
                    rangePicker: {
                        selected: {
                            backgroundColor: GENERICS.white,
                            input: {
                                color: GREY_UPDATED.x700,
                                backgroundColor: GENERICS.white,
                            },
                            icon: {
                                color: GREY_UPDATED.x500,
                            },
                            separator: {
                                backgroundColor: GREY_UPDATED.x700,
                            },
                        },
                    },
                },
                myAccountTabs: {
                    tabList: {
                        borderColor: GREY.x200,
                    },
                    tabPanel: {
                        borderColor: GREY.x200,
                        backgroundColor: GENERICS.white,
                    },
                    tab: {
                        backgroundColor: GENERICS.white,
                        borderColor: GREY.x200,
                        hover: {
                            backgroundColor: GREY.x50,
                        },
                        bottomLine: {
                            backgroundColor: GREY.x200,
                            active: { backgroundColor: GENERICS.white },
                        },
                        topLine: {
                            backgroundColor: GREY.x200,
                            active: { backgroundColor: PUMPKIN.x500 },
                        },
                    },
                },
                promoteReleaseModal: {
                    heading: {
                        color: GREY_UPDATED.x700,
                    },
                    info: {
                        color: GREY_UPDATED.x600,
                    },
                    slideIndicator: {
                        borderColor: MEADOW.x500,
                        backgroundColor: GENERICS.transparent,
                        active: {
                            backgroundColor: MEADOW.x500,
                        },
                    },
                    generalInfoSecondPart: {
                        backgroundColor: DUST.x300,
                    },
                },
                warningBulkPromoteModal: {
                    content: {
                        color: GREY_UPDATED.x700,
                    },
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                },
                releaseModal: {
                    heading: {
                        color: GREY_UPDATED.x700,
                    },
                    note: {
                        color: GREY_UPDATED.x700,
                    },
                    description: {
                        color: GREY_UPDATED.x600,
                    },
                    secondPart: {
                        backgroundColor: DUST.x300,
                    },
                },
                pendingInvoiceModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    link: {
                        color: DUST.x300,
                        hover: {
                            color: DUST.x200,
                        },
                    },
                    subtitle: {
                        color: GREY_UPDATED.x700,
                    },
                    headRow: {
                        backgroundColor: GREY_UPDATED.x100,
                        headCell: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                    row: {
                        borderColor: GREY_UPDATED.x200,
                    },
                },
                collapsibleSection: {
                    summary: {
                        backgroundColor: GENERICS.white,
                        borderColor: GENERICS.transparent,
                        focus: {
                            borderColor: DUST.x300,
                        },
                    },
                    details: {
                        boxShadow: LIGHT_BOX_SHADOW,
                        backgroundColor: DUST.x50,
                    },
                    content: {
                        borderColor: GREY_UPDATED.x300,
                    },
                },
                iconContainer: {
                    backgroundColor: DUST.x50,
                },
                schedule: {
                    subtitle: {
                        color: GREY_UPDATED.x500,
                    },
                    calendarIcon: {
                        color: GREY_UPDATED.x600,
                    },
                    calendar: {
                        stylingWrapper: {
                            colHeaderCell: {
                                color: GREY_UPDATED.x500,
                            },
                            dayGridDay: {
                                backgroundColor: GENERICS.white,
                            },
                            dayAfterAdExpires: {
                                backgroundColor: `${GENERICS.white}80`,
                            },
                        },
                        headerText: {
                            color: GREY_UPDATED.x500,
                        },
                        descriptionWrapper: {
                            backgroundColor: GENERICS.white,
                        },
                        description: {
                            color: GREY_UPDATED.x600,
                        },
                        bulbIconWrapper: {
                            backgroundColor: PUMPKIN.x200,
                        },
                        bulbIcon: {
                            color: PUMPKIN.x500,
                        },
                    },
                    dayNumber: {
                        text: {
                            default: {
                                color: GREY_UPDATED.x500,
                            },
                            disabled: {
                                color: `${GREY_UPDATED.x500}80`,
                            },
                        },
                        currentDayMarker: {
                            backgroundColor: GREY_UPDATED.x200,
                        },
                    },
                    promotionEvent: {
                        featuredDevelopmentVas: {
                            background: WARNING.x100,
                        },
                        homepageVas: {
                            background: PUMPKIN.x100,
                        },
                        otherVas: {
                            background: `${SEA.x500}33`,
                        },
                        promotionStartIcon: {
                            default: {
                                backgroundColor: GENERICS.white,
                                borderColor: SEA.x500,
                            },
                            featuredDevelopment: {
                                backgroundColor: WARNING.x500,
                                borderColor: WARNING.x500,
                            },
                            homepage: {
                                backgroundColor: PUMPKIN.x400,
                                borderColor: PUMPKIN.x400,
                            },
                            top: {
                                backgroundColor: INFO.x500,
                                borderColor: INFO.x500,
                            },
                        },
                        text: {
                            color: GENERICS.text,
                        },
                        topVas: {
                            background: `${INFO.x500}33`,
                        },
                    },
                },
                accountBalanceAmount: {
                    amount: {
                        positive: {
                            color: MEADOW.x500,
                        },
                        negative: {
                            color: ERROR.x500,
                        },
                        zero: {
                            color: GENERICS.white,
                        },
                    },
                },
            },
            ads: {
                activatePromotionModal: {
                    bulbIcon: { color: GREY_UPDATED.x500 },
                    recommendedDaysNumber: { color: GREY_UPDATED.x500 },
                },
                adStatisticsChart: {
                    xAxis: {
                        tick: { stroke: GREY_UPDATED.x400, fill: GREY_UPDATED.x400 },
                    },
                    tickMonthWrapper: {
                        fill: GREY_UPDATED.x700,
                    },
                    tickDateWrapper: {
                        fill: GREY_UPDATED.x700,
                    },
                    tooltipWrapper: {
                        backgroundColor: GENERICS.white,
                        boxShadow: AD_STATISTICS_TOOLTIP_BOX_SHADOW,
                    },
                    tooltipDate: {
                        color: GREY_UPDATED.x700,
                    },
                    tooltipStatistic: {
                        color: GREY_UPDATED.x700,
                    },
                    chartGradient: {
                        color: DUST.x300,
                    },
                    yAxis: { tick: { fill: GREY_UPDATED.x700 } },
                    axis: { stroke: GREY.x200 },
                    chart: {
                        stroke: DUST.x300,
                        activeDot: {
                            fill: GENERICS.white,
                            stroke: DUST.x300,
                        },
                    },
                    tooltip: {
                        cursor: {
                            stroke: DUST.x300,
                        },
                    },
                    monthsSeparatorLine: {
                        stroke: GREY.x200,
                        label: {
                            fill: '#616161',
                        },
                    },
                    promotionLine: {
                        stroke: '#35B4C8',
                    },
                },
                adStatistics: {
                    exclusiveOffer: {
                        backgroundColor: '#FFEADF',
                    },
                    recommendationAlert: {
                        color: GENERICS.black,
                        icon: {
                            color: WARNING.x500,
                        },
                    },
                    tooltipLabel: {
                        color: DUST.x300,
                    },
                },
                adStatisticsTabs: {
                    label: {
                        color: GREY.x400,
                    },
                    tabTooltipIcon: {
                        color: GREY_UPDATED.x400,
                    },
                    value: {
                        color: GREY_UPDATED.x700,
                    },
                },
                datePicker: {
                    day: {
                        default: {
                            backgroundColor: PUMPKIN.x100,
                        },
                        selected: {
                            color: GENERICS.white,
                            backgroundColor: PUMPKIN.x400,
                        },
                    },
                    weekday: {
                        color: GREY.x500,
                    },
                    calendar: {
                        color: PUMPKIN.x400,
                    },
                },
                statisticsTimeline: {
                    tooltipWrapper: {
                        backgroundColor: GENERICS.white,
                        boxShadow: AD_STATISTICS_TOOLTIP_BOX_SHADOW,
                    },
                    tooltipText: {
                        color: GREY_UPDATED.x700,
                    },
                    axis: {
                        stroke: GREY.x200,
                    },
                    highlightDot: {
                        fill: GENERICS.white,
                        stroke: SKY_DEPRECATED.x400,
                    },
                    horizontalLine: {
                        /* eslint-disable @typescript-eslint/naming-convention -- `VasStrokeColor` enum conflict */
                        ExportToOlx: { stroke: '#00a7a0' },
                        TopAds: { stroke: '#3977ff' },
                        Homepage: { stroke: '#38d2d5' },
                        PushUp: { stroke: '#36B3C6' },
                    },
                    verticalLine: {
                        ExportToOlx: { stroke: 'rgba(0, 167, 160, 0.5)' },
                        TopAds: { stroke: 'rgba(57, 119, 255, 0.5)' },
                        Homepage: { stroke: 'rgba(56, 210, 213, 0.5)' },
                        PushUp: { stroke: '#36B3C6' },
                    },
                    vasLabel: {
                        ExportToOlx: { fill: '#00a7a0' },
                        TopAds: { fill: '#3977ff' },
                        Homepage: { fill: '#38d2d5' },
                        PushUp: { fill: '#36B3C6' },
                        /* eslint-enable @typescript-eslint/naming-convention */
                        default: { fill: GREY_UPDATED.x700 },
                    },
                },
                unitsHeading: {
                    color: GENERICS.text,
                    backgroundColor: GENERICS.white,
                },
                warningSellWithOtodomModal: {
                    content: {
                        color: GREY_UPDATED.x700,
                    },
                    link: {
                        color: DUST.x300,
                    },
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                },
                cancelAdModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    reasonLabel: {
                        color: GREY_UPDATED.x600,
                    },
                },
                deleteAdModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    footer: {
                        borderColor: GREY.x200,
                    },
                },
                moderatedAdModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    modalContent: {
                        color: GREY_UPDATED.x700,
                    },
                    doLaterButton: {
                        backgroundColor: GENERICS.transparent,
                        color: GREY.x400,
                    },
                },
                openDayModal: {
                    color: GREY_UPDATED.x700,
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    footer: {
                        borderTopColor: GREY.x100,
                    },
                    errorMessage: {
                        color: ERROR.x500,
                    },
                    unsetOpenDay: {
                        backgroundColor: GENERICS.transparent,
                        color: GREY.x400,
                    },
                    icon: {
                        color: GREY.x400,
                    },
                },
                specialOfferModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    footer: {
                        cancelButton: {
                            color: DUST.x300,
                        },
                        deleteButton: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                    endDate: {
                        alert: {
                            borderColor: ERROR.x500,
                            color: ERROR.x500,
                        },
                        borderColor: GREY_UPDATED.x400,
                        color: GENERICS.black,
                    },
                },
                adsList: {
                    headingWrapper: {
                        color: '#8f8f8f',
                    },
                    pagination: {
                        color: '#8f8f8f',
                    },
                    showFilters: {
                        color: MEADOW.x500,
                        hover: {
                            color: MEADOW.x400,
                        },
                    },
                    separator: {
                        backgroundColor: GREY_UPDATED.x300,
                    },
                    label: {
                        color: GREY_UPDATED.x700,
                    },
                    bulbIcon: {
                        color: WARNING.x500,
                    },
                },
                adsBulkActions: {
                    backgroundColor: GREY.x200,
                    color: DUST.x300,
                    button: {
                        color: DUST.x300,
                        disabled: {
                            color: GREY_UPDATED.x400,
                        },
                    },
                },
                adItem: {
                    recommendationAlert: {
                        icon: {
                            color: INFO.x500,
                        },
                    },
                    tooltip: {
                        borderBottomColor: GREY_UPDATED.x200,
                    },
                    statisticsActivationMessage: {
                        color: GREY_UPDATED.x500,
                    },
                    adCard: {
                        backgroundColor: GENERICS.white,
                        color: '#8f8f8f',
                    },
                    area: {
                        color: GREY_UPDATED.x700,
                    },
                    hiddenPrice: {
                        color: GREY_UPDATED.x500,
                    },
                    bottomBar: {
                        borderColor: GREY_UPDATED.x200,
                    },
                    headingLink: {
                        color: GREY_UPDATED.x700,
                    },
                    idLabel: {
                        color: GREY_UPDATED.x500,
                    },
                    location: {
                        color: GREY_UPDATED.x700,
                    },
                    adPrice: {
                        color: GENERICS.text,
                    },
                    icon: {
                        color: GREY.x400,
                    },
                    lineChartIconWrapper: {
                        backgroundColor: INFO.x100,
                    },
                    openDayButton: {
                        default: {
                            color: DUST.x300,
                        },
                        hasOpenDay: {
                            color: PUMPKIN.x500,
                        },
                    },
                    promotions: {
                        label: {
                            color: GREY_UPDATED.x500,
                        },
                        item: {
                            color: GREY_UPDATED.x400,
                        },
                        itemActive: {
                            color: '#f06d09',
                        },
                        tooltipDescription: {
                            color: GREY_UPDATED.x700,
                        },
                        expiredSpecialOffer: {
                            button: { color: GENERICS.black },
                        },
                    },
                    promotionsBar: {
                        borderColor: GREY_UPDATED.x200,
                        color: GREY_UPDATED.x500,
                    },
                    showStatisticsButton: {
                        color: MEADOW.x500,
                    },
                    specialOfferButton: {
                        default: {
                            color: DUST.x300,
                        },
                        hasSpecialOffer: {
                            color: PUMPKIN.x500,
                        },
                        disabled: {
                            color: GREY_UPDATED.x400,
                        },
                    },
                    statistics: {
                        borderColor: GREY.x100,
                    },
                    statisticsBar: {
                        label: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                    statisticsLabel: {
                        color: '#616161',
                    },
                    statisticsValue: {
                        color: GREY_UPDATED.x700,
                    },
                    hiddenPriceIcon: {
                        color: GREY_UPDATED.x400,
                    },
                    developerAdsDisplay: {
                        color: PUMPKIN.x500,
                    },
                    developerAdsButton: {
                        activeBackgroundColor: DUST.x100,
                        backgroundColor: GENERICS.white,
                        color: DUST.x300,
                        borderColor: DUST.x300,
                        boxShadow: LIGHT_BOX_SHADOW,
                        separator: {
                            backgroundColor: GREY.x100,
                        },
                        newUnitButton: {
                            color: MEADOW.x600,
                        },
                        adCategoryLink: {
                            backgroundColor: '#f2f4f5',
                        },
                    },
                    moderationBar: {
                        color: GENERICS.white,
                        backgroundColor: PUMPKIN.x500,
                    },
                },
                filtersBox: {
                    backgroundColor: GENERICS.white,
                    autocompleteLocation: {
                        placeholderIcon: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                    resetFilters: {
                        color: DUST.x300,
                    },
                },
                sellWithOtodomAlert: {
                    adsCounterButton: {
                        color: DUST.x200,
                    },
                    link: {
                        color: DUST.x200,
                    },
                },
                recommendedPromotion: {
                    backgroundColor: GENERICS.white,
                    borderColor: GREY_UPDATED.x300,
                    priceTooltip: {
                        color: GREY_UPDATED.x400,
                    },
                },
            },
            summary: {
                redReport: {
                    wrapper: {
                        boxShadow: SUMMARY_CONTAINER_BOX_SHADOW,
                        backgroundColor: GENERICS.white,
                    },
                    heading: {
                        backgroundColor: GREY_UPDATED.x100,
                        color: GREY_UPDATED.x500,
                    },
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    subtitle: {
                        color: GREY_UPDATED.x600,
                    },
                    reportFooter: {
                        wrapper: {
                            borderColor: GREY_UPDATED.x300,
                            color: GREY_UPDATED.x600,
                        },
                        note: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                    reportStatistics: {
                        dataBlock: {
                            borderColor: GREY_UPDATED.x200,
                        },
                        subText: {
                            color: GREY_UPDATED.x500,
                        },
                        link: {
                            color: MEADOW.x500,
                        },
                        importantNumber: {
                            dark: {
                                color: GENERICS.text,
                            },
                            light: {
                                color: PUMPKIN.x500,
                            },
                        },
                    },
                    pieChart: {
                        tooltipWrapper: {
                            backgroundColor: GENERICS.white,
                            boxShadow: LIGHT_BOX_SHADOW,
                        },
                        chart: {
                            duplicates: {
                                fill: PUMPKIN.x500,
                            },
                            unique: {
                                fill: GREY_UPDATED.x200,
                            },
                        },
                    },
                    adCard: {
                        container: {
                            boxShadow: LIGHT_BOX_SHADOW,
                        },
                        footer: {
                            borderColor: GREY_UPDATED.x200,
                        },
                        title: {
                            color: GREY_UPDATED.x700,
                        },
                        statistic: {
                            borderColor: GREY_UPDATED.x200,
                        },
                        linkToAd: {
                            color: GREY_UPDATED.x500,
                        },
                        linkIcon: {
                            color: GREY_UPDATED.x400,
                        },
                        statisticNumberReported: {
                            color: `${GREY_UPDATED.x700}80`,
                        },
                        statisticNumberTooltip: {
                            color: `${GREY_UPDATED.x700}80`,
                        },
                    },
                    helpBanner: {
                        bannerWrapper: {
                            backgroundColor: DUST.x300,
                            boxShadow: SUMMARY_CONTAINER_BOX_SHADOW,
                        },
                        title: {
                            color: GENERICS.white,
                        },
                        description: {
                            color: GREY_UPDATED.x300,
                        },
                    },
                    onboardingTooltip: {
                        iconButton: {
                            backgroundColor: GENERICS.none,
                            color: GENERICS.text,
                            disabled: {
                                color: GREY_UPDATED.x300,
                            },
                        },
                        tooltipWrapper: {
                            backgroundColor: GENERICS.white,
                        },
                    },
                },
                adREDReportModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    verticalLine: {
                        color: GREY_UPDATED.x400,
                    },
                    count: {
                        color: GREY_UPDATED.x700,
                    },
                    subtitle: {
                        color: GREY_UPDATED.x500,
                    },
                    adDetailsLabels: {
                        label: {
                            backgroundColor: GREY_UPDATED.x100,
                            color: GREY_UPDATED.x500,
                        },
                    },
                    adDetailsCard: {
                        adContainer: {
                            boxShadow: LIGHT_BOX_SHADOW,
                        },
                        title: {
                            default: {
                                color: GREY_UPDATED.x700,
                                backgroundColor: GENERICS.white,
                            },
                            active: {
                                color: GENERICS.white,
                                backgroundColor: DUST.x300,
                            },
                        },
                        adData: {
                            color: GREY_UPDATED.x700,
                            borderColor: GREY_UPDATED.x300,
                        },
                    },
                    reportAd: {
                        reportAdButton: {
                            backgroundColor: GENERICS.transparent,
                            color: GREY_UPDATED.x500,
                        },
                        alreadyReportedText: {
                            color: GREY_UPDATED.x500,
                        },
                        tooltipTitle: {
                            color: GREY_UPDATED.x700,
                        },
                        tooltipText: {
                            color: GREY_UPDATED.x700,
                        },
                        confirmationText: {
                            color: GREY_UPDATED.x700,
                        },
                        confirmButton: {
                            borderColor: DUST.x300,
                        },
                    },
                    information: {
                        color: GREY_UPDATED.x500,
                        icon: {
                            color: GREY_UPDATED.x400,
                        },
                    },
                },
                thirdPartyServicesModal: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    subtitle: {
                        color: GREY_UPDATED.x500,
                    },
                    serviceCard: {
                        content: {
                            borderColor: GREY_UPDATED.x200,
                            backgroundColor: GENERICS.white,
                        },
                        subtitle: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                    bonusCard: {
                        content: {
                            borderColor: GREY_UPDATED.x200,
                        },
                        subtitle: {
                            color: GREY_UPDATED.x500,
                        },
                    },
                },
                sectionHeader: {
                    color: GREY_UPDATED.x500,
                },
                addHighlights: {
                    background: GREY.x50,
                    border: GREY.x200,
                },
                agentHighlights: {
                    innerWrapper: {
                        background: GENERICS.white,
                        borderColor: GENERICS.transparent,
                        boxShadow: SUMMARY_CONTAINER_BOX_SHADOW,
                        color: GREY_UPDATED.x700,
                    },
                    tableHead: {
                        backgroundColor: GREY.x50,
                        color: GREY.x400,
                    },
                    footer: {
                        borderColor: GREY.x100,
                        color: GREY.x500,
                    },
                    footerLink: {
                        color: DUST.x300,
                    },
                    tableRow: {
                        borderColor: GREY.x100,
                        color: DUST.x200,
                    },
                    expiryListItem: {
                        color: GREY_UPDATED.x500,
                    },
                    tooltipIconInfo: {
                        color: GREY_UPDATED.x400,
                    },
                    mobileLabel: {
                        color: GREY_UPDATED.x400,
                    },
                },
                accountSummary: {
                    wrapper: {
                        backgroundColor: GENERICS.white,
                        boxShadow: 'rgba(79, 88, 101, 0.15) 0 2px 10px 0',
                    },
                    blockDescription: {
                        color: GREY.x400,
                    },
                    bundleDetails: {
                        borderColor: GREY.x200,
                    },
                    link: {
                        color: MEADOW.x500,
                    },
                    nextBundleText: {
                        color: GREY.x400,
                    },
                    featureStatusIcon: {
                        enabled: {
                            color: MEADOW.x500,
                        },
                        disabled: {
                            color: GREY_UPDATED.x400,
                        },
                    },
                    featureListHeading: {
                        color: GREY_UPDATED.x500,
                    },
                    featureListHeadingHighlight: {
                        color: GREY_UPDATED.x700,
                    },
                },
                agentsAccountSummary: {
                    bracketText: {
                        color: GENERICS.text,
                    },
                },
                developersAccountSummary: {
                    link: {
                        color: MEADOW.x500,
                    },
                    investmentTitle: {
                        color: DUST.x300,
                    },
                    adsListTooltip: {
                        color: GREY_UPDATED.x400,
                    },
                    tooltipInvestmentTitle: {
                        color: GREY_UPDATED.x500,
                    },
                    tooltipInvestmentAdsAmount: {
                        color: GREY_UPDATED.x700,
                    },
                },
                modals: {
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    text: {
                        color: GREY_UPDATED.x700,
                    },
                    tooltip: {
                        color: GREY_UPDATED.x400,
                    },
                },
                accountStatistics: {
                    container: { backgroundColor: GENERICS.white, boxShadow: SUMMARY_CONTAINER_BOX_SHADOW },
                    footer: {
                        color: '#404041',
                        borderColor: GREY.x200,
                    },
                    statisticsWrapper: { borderColor: GREY.x200 },
                    link: { color: DUST.x300 },
                },
                accountBalance: {
                    heading: {
                        color: GENERICS.white,
                    },
                    container: {
                        backgroundColor: DUST.x300,
                        boxShadow: SUMMARY_CONTAINER_BOX_SHADOW,
                    },
                    checkInvoicesButton: {
                        color: MEADOW.x500,
                    },
                    invoiceAlert: {
                        color: GREY.x200,
                        overpaymentIconColor: INFO.x500,
                        zeroInvoiceIconColor: MEADOW.x500,
                    },
                    footerText: {
                        color: GENERICS.white,
                    },
                },
                adsToBeExtendedDescription: {
                    link: {
                        color: MEADOW.x500,
                    },
                },
                addOnAnalyticsBanner: {
                    color: GENERICS.black,
                },
                addOnInfoBox: {
                    alert: {
                        backgroundColor: PUMPKIN.x100,
                        borderColor: PUMPKIN.x500,
                    },
                    contentWrapper: {
                        color: GREY_UPDATED.x700,
                    },
                    closeIcon: {
                        color: GREY_UPDATED.x500,
                    },
                },
            },
        },
        ad: {
            adAgencyBannerContent: {
                backgroundColor: GREY_UPDATED.x100,
                agencyName: {
                    color: MEADOW.x500,
                },
            },
            adUserAds: {
                color: MEADOW.x500,
            },
            adBreadcrumbs: {
                color: GREY_UPDATED.x600,
            },
            adDescription: {
                color: GREY_UPDATED.x700,
            },
            pagePreviewOverlay: {
                backgroundColor: DUST.x300,
                footer: {
                    backgroundColor: GENERICS.white,
                },
            },
            adHeader: {
                price: {
                    color: GREY_UPDATED.x700,
                },
                location: {
                    color: PUMPKIN.x500,
                },
                developer: {
                    primary: {
                        color: PUMPKIN.x500,
                    },
                    secondary: {
                        color: MEADOW.x500,
                    },
                },
                investment: {
                    tag: {
                        primary: {
                            backgroundColor: GREY_UPDATED.x700,
                            color: GENERICS.white,
                        },
                        secondary: {
                            color: GREY_UPDATED.x700,
                        },
                    },
                    link: {
                        primary: {
                            color: PUMPKIN.x500,
                        },
                        secondary: {
                            color: MEADOW.x500,
                        },
                    },
                },
                exclusiveOfferTag: {
                    primary: {
                        backgroundColor: GREY_UPDATED.x700,
                        color: GENERICS.white,
                    },
                    secondary: {
                        backgroundColor: PUMPKIN.x500,
                        color: GENERICS.white,
                    },
                },
                extras: {
                    color: GREY_UPDATED.x600,
                },
                logo: {
                    borderColor: GREY_UPDATED.x300,
                },
                negotiableBadge: {
                    backgroundColor: DUST.x100,
                },
                closeButton: {
                    default: {
                        color: DUST.x300,
                    },
                    active: {
                        color: DUST.x400,
                    },
                },
                suggestPriceButton: {
                    color: DUST.x300,
                },
            },
            adMediaGallery: {
                addToFavoritesWrapper: {
                    backgroundColor: GENERICS.white,
                },
                slideWrapper: {
                    backgroundColor: GREY_UPDATED.x100,
                },
                icon: {
                    color: GENERICS.white,
                },
                thumbnailInner: {
                    default: {
                        borderColor: GENERICS.transparent,
                    },
                    hover: {
                        borderColor: GENERICS.black,
                    },
                    focus: {
                        borderColor: GENERICS.black,
                    },
                },
                thumbnailImageIndex: {
                    backgroundColor: `${GENERICS.black}b3`,
                    color: GENERICS.white,
                },
                contentSlideWrapper: {
                    backgroundColor: GENERICS.black,
                },
                virtualWalkaround: {
                    base: {
                        backgroundColor: `${GENERICS.black}80`,
                        color: GENERICS.white,
                    },
                    closeBtn: {
                        color: GENERICS.white,
                    },
                    icon: {
                        close: {
                            color: GENERICS.black,
                        },
                        streetView: {
                            color: GENERICS.white,
                        },
                    },
                },
                reactImageGalleryWrapper: {
                    backgroundColor: GENERICS.black,
                },
                galleryItemContainer: {
                    backgroundColor: DUST.x300,
                },
            },
            imageGalleryFooter: {
                backgroundColor: GENERICS.black,
                borderColor: GREY_UPDATED.x500,
            },
            galleryImageIndexCounter: {
                color: GENERICS.white,
                backgroundColor: GENERICS.black,
            },
            projectMediaGallery: {
                slideWrapper: {
                    backgroundColor: GREY_UPDATED.x100,
                },
                thumbnail: {
                    hover: {
                        borderColor: DUST.x200,
                    },
                    active: {
                        borderColor: DUST.x300,
                    },
                },
                contentSlideWrapper: {
                    backgroundColor: GENERICS.black,
                },
                index: {
                    backgroundColor: `${DUST.x300}30`,
                },
            },
            adRemoteService: {
                titleIcon: {
                    color: MEADOW.x500,
                },
                tile: {
                    backgroundColor: GREY.x50,
                },
                tileButton: {
                    color: MEADOW.x500,
                },
                tileIcon: {
                    color: GREY.x400,
                },
            },
            adLeaderBanner: {
                backgroundColor: DUST.x50,
            },
            adMultimediaSection: {
                backgroundColor: DUST.x50,
                hover: {
                    backgroundColor: DUST.x100,
                },
                focus: {
                    backgroundColor: DUST.x100,
                },
                icon: {
                    color: DUST.x300,
                },
            },
            adReportAd: {
                color: GREY_UPDATED.x700,
            },
            adReportAdModal: {
                container: {
                    backgroundColor: GENERICS.white,
                },
                descriptionFieldLabel: {
                    color: ERROR.x500,
                },
                reasonDescriptionField: {
                    base: {
                        backgroundColor: GENERICS.white,
                        borderColor: DUST.x100,
                        color: GREY.x500,
                    },
                    invalid: {
                        backgroundColor: GREY.x50,
                        borderColor: ERROR.x500,
                    },
                },
                errorMessage: {
                    color: ERROR.x500,
                },
                successStatus: {
                    color: MEADOW.x500,
                },
            },
            contactForm: {
                mobileStickyFooter: {
                    backgroundColor: MEADOW.x500,
                    borderColor: `${GENERICS.white}80`,
                },
            },
            investmentUnitsList: {
                row: {
                    borderColor: GREY.x200,
                    backgroundColor: DUST.x50,
                },
                image: {
                    borderColor: GREY.x200,
                },
                link: {
                    color: MEADOW.x500,
                },
            },
            projectUnitsList: {
                header: {
                    sortingDropdown: {
                        label: {
                            color: GREY_UPDATED.x700,
                        },
                    },
                    numberOfAdsWrapper: {
                        color: DUST.x200,
                        numberOfAds: {
                            color: GREY_UPDATED.x700,
                        },
                    },
                },
                listItem: {
                    container: {
                        color: GREY_UPDATED.x700,
                        borderColor: GREY_UPDATED.x200,
                    },
                    mobileAddToFavorites: {
                        backgroundColor: GENERICS.white,
                    },
                    imageContainer: {
                        background: `linear-gradient(180deg, rgba(35, 46, 63, 0) 72.92%, ${DUST.x300} 100%)`,
                    },
                    pricePerMeterAndAreaRow: {
                        color: GREY_UPDATED.x500,
                    },
                },
                listItemPrice: {
                    discountedPrice: {
                        color: GREY_UPDATED.x500,
                    },
                },
            },
            investmentUnitsSorting: {
                color: DUST.x300,
                backgroundColor: GREY_UPDATED.x100,
                borderBottomColor: GREY_UPDATED.x300,
            },
            adPageStickyHeader: {
                backgroundColor: GENERICS.white,
                boxShadow: `${GREY_UPDATED.x300}  0 0 4px 0`,
                borderColor: GREY_UPDATED.x300,
                color: GREY_UPDATED.x700,
                icon: {
                    color: DUST.x200,
                },
            },
            phoneNumber: {
                showNumber: {
                    default: {
                        borderColor: PUMPKIN.x500,
                        color: PUMPKIN.x500,
                    },
                    active: {
                        borderColor: PUMPKIN.x500,
                        color: PUMPKIN.x500,
                        backgroundColor: PUMPKIN.x100,
                    },
                },
                fullPhoneNumber: {
                    color: GENERICS.text,
                },
            },
            adSectionHeading: {
                borderColor: GREY.x200,
                color: GREY_UPDATED.x700,
            },
            adSubscribe: {
                default: {
                    borderColor: PUMPKIN.x500,
                    color: PUMPKIN.x500,
                },
                hover: {
                    borderColor: PUMPKIN.x500,
                    color: PUMPKIN.x500,
                    backgroundColor: `${PUMPKIN.x500}1a`,
                },
                focus: {
                    borderColor: PUMPKIN.x500,
                    color: PUMPKIN.x500,
                    backgroundColor: `${PUMPKIN.x500}1a`,
                },
            },
            adLocation: {
                color: PUMPKIN.x500,
            },
            adOverview: {
                characteristic: {
                    color: GREY.x500,
                },
                table: {
                    tableItem: {
                        color: GREY_UPDATED.x600,
                        borderColor: GREY_UPDATED.x300,
                    },
                    tooltipInfoIcon: {
                        color: GREY_UPDATED.x500,
                    },
                },
            },
            adMeta: { color: GREY_UPDATED.x600 },
            subscribeAdSection: {
                backgroundColor: GREY_UPDATED.x100,
            },
            brandingHeader: {
                backgroundColor: GREY_UPDATED.x100,
                hover: {
                    backgroundColor: GREY_UPDATED.x200,
                },
            },
            showAllAdsLink: {
                color: MEADOW.x500,
            },
            brandingAgencyName: {
                color: DUST.x300,
            },
            openDay: {
                color: GREY_UPDATED.x700,
                backgroundColor: DUST.x50,
                mainIcon: {
                    color: DUST.x300,
                    backgroundColor: DUST.x100,
                },
                dateIcon: {
                    color: GREY_UPDATED.x500,
                },
            },
            adAvmModule: {
                backgroundColor: GREY_UPDATED.x100,
                title: {
                    color: GREY_UPDATED.x700,
                },
                estimatedRangeLine: {
                    backgroundColor: MEADOW.x500,
                },
                outsideEstimatedRangeLine: {
                    backgroundColor: GREY_UPDATED.x700,
                },
                oldOutsideEstimatedRangeLine: {
                    backgroundColor: `linear-gradient(270deg, ${GREY_UPDATED.x700} 50%, rgba(255, 255, 255, 0) 100%)`,
                },
                arrow: {
                    borderColor: `${GREY_UPDATED.x700} transparent transparent transparent`,
                },
                price: {
                    color: GREY_UPDATED.x700,
                },
                shadow: {
                    boxShadow: LIGHT_BOX_SHADOW,
                },
                explanation: {
                    color: GREY_UPDATED.x700,
                },
                trigger: {
                    color: MEADOW.x500,
                },
                currentPrice: {
                    color: GREY_UPDATED.x700,
                    before: {
                        borderColor: DUST.x300,
                        backgroundColor: MEADOW.x500,
                        boxShadow: `0 0 0 2px ${GREY_UPDATED.x200}`,
                    },
                    after: {
                        backgroundColor: GREY_UPDATED.x700,
                    },
                },
                betaTag: {
                    color: PUMPKIN.x500,
                    backgroundColor: PUMPKIN.x200,
                },
                feedback: {
                    color: GREY_UPDATED.x500,
                    backgroundColor: GREY_UPDATED.x100,
                },
                readMoreModal: {
                    separator: {
                        color: GREY_UPDATED.x300,
                    },
                },
                tooltipTrigger: {
                    color: GENERICS.black,
                },
            },
            adFinancialBanner: {
                backgroundColor: GREY_UPDATED.x100,
            },
            adMortgageSimulator: {
                backgroundColor: GREY_UPDATED.x100,
                borderColor: GREY_UPDATED.x300,
                installmentPrice: {
                    color: MEADOW.x500,
                },
            },
            projectAdHeader: {
                price: {
                    color: GREY_UPDATED.x700,
                },
                priceBeforeDiscount: {
                    price: {
                        color: GREY_UPDATED.x500,
                    },
                    tooltip: {
                        color: GREY_UPDATED.x400,
                    },
                },
                header: {
                    borderColor: GREY_UPDATED.x300,
                },
            },
            projectTopInformationBox: {
                icon: {
                    color: GREY_UPDATED.x500,
                },
            },
            videoPlayer: {
                backgroundColor: GREY_UPDATED.x300,
                playButton: { color: GENERICS.white, dropShadow: PLAY_BUTTON_DROP_SHADOW },
                player: {
                    backgroundColor: GENERICS.black,
                },
            },
            financeLead: {
                backgroundColor: GREY_UPDATED.x100,
            },
            financeExpertAdSection: {
                separator: {
                    color: GREY_UPDATED.x300,
                },
                text: {
                    color: GREY_UPDATED.x600,
                },
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
            },
            askAboutPrice: {
                hover: { color: DUST.x200 },
                focus: { color: DUST.x300 },
                overlay: {
                    backgroundColor: 'rgba(35, 46, 63, 0.7)',
                },
            },
            expiredAdAlert: {
                borderColor: ERROR.x500,
                backgroundColor: ERROR.x100,
                color: GREY_UPDATED.x700,
            },
            expiredAdContent: {
                borderColor: GREY_UPDATED.x400,
                color: GREY_UPDATED.x500,
            },
        },
        finance: {
            global: {
                fontFamily: SORA_FAMILY,
                color: GREY_UPDATED.x700,
            },
            shared: {
                highlightText: {
                    color: MEADOW.x500,
                },
            },
            landing: {
                backgroundColor: DUST.x50,
                title: {
                    color: DUST.x300,
                },
                subTitle: {
                    color: GREY_UPDATED.x600,
                },
                rangeSlider: {
                    before: {
                        backgroundColor: MEADOW.x500,
                    },
                    track: {
                        backgroundColor: `linear-gradient(0deg, ${GREY_UPDATED.x400} 1px, transparent 0)`,
                    },
                    thumb: {
                        backgroundColor: GENERICS.white,
                        borderColor: MEADOW.x500,
                        hover: {
                            boxShadow: `0 0 0 4px ${MEADOW.x200}`,
                        },
                    },
                    indicator: {
                        color: MEADOW.x500,
                    },
                    infoText: {
                        color: GREY_UPDATED.x600,
                    },
                    rangeLabel: {
                        color: GREY_UPDATED.x600,
                    },
                    step: {
                        backgroundColor: MEADOW.x500,
                        active: {
                            backgroundColor: GREY_UPDATED.x400,
                        },
                    },
                },
                questionItem: {
                    separator: {
                        color: GREY_UPDATED.x300,
                    },
                },
                support: {
                    explanationText: {
                        color: GREY_UPDATED.x600,
                    },
                },
                contact: {
                    boxShadow: LIGHT_BOX_SHADOW,
                    separator: {
                        color: GREY_UPDATED.x400,
                    },
                    rating: {
                        color: PUMPKIN.x400,
                    },
                    infoText: {
                        color: GREY_UPDATED.x600,
                    },
                },
                panel: {
                    backgroundColor: GENERICS.white,
                    boxShadow: LIGHT_BOX_SHADOW,
                },
            },
            callRequest: {
                text: {
                    color: GREY_UPDATED.x600,
                },
                hero: {
                    title: {
                        color: DUST.x300,
                        span: {
                            color: MEADOW.x500,
                        },
                    },
                    circle: {
                        backgroundColor: DUST.x300,
                    },
                    backgroundColor: DUST.x50,
                    bik: {
                        borderColor: GREY_UPDATED.x300,
                    },
                },
                leadForm: {
                    backgroundColor: GENERICS.white,
                    boxShadow: LIGHT_BOX_SHADOW,
                    title: {
                        color: GREY_UPDATED.x700,
                    },
                    subtitle: {
                        color: GREY_UPDATED.x600,
                    },
                    privacyPolicy: {
                        color: GREY_UPDATED.x500,
                    },
                    infoText: {
                        backgroundColor: DUST.x50,
                    },
                },
                opinions: {
                    backgroundColor: GENERICS.white,
                    checkMark: {
                        color: MEADOW.x500,
                    },
                },
                customerOpinion: {
                    backgroundColor: INFO.x100,
                    star: {
                        color: PUMPKIN.x400,
                    },
                },
                experts: {
                    backgroundColor: DUST.x50,
                },
                highlightText: {
                    color: MEADOW.x500,
                },
            },
            mortgageBrowser: {
                legalClause: {
                    color: GREY_UPDATED.x500,
                },
                installmentPrice: {
                    backgroundColor: '#e4f9ed',
                },
                rateType: {
                    fixed: {
                        color: GENERICS.white,
                        backgroundColor: DUST.x300,
                    },
                    variable: {
                        color: DUST.x300,
                        backgroundColor: DUST.x100,
                    },
                },
            },
        },
        companies: {
            advertItem: {
                backgroundColor: GENERICS.white,
                link: {
                    color: GENERICS.text,
                },
                addToFavIcon: {
                    secondary: {
                        borderColor: GENERICS.black,
                        fill: MEADOW.x500,
                        backgroundColor: GENERICS.white,
                    },
                },
                openDays: {
                    backgroundColor: MEADOW.x500,
                    color: GENERICS.white,
                },
            },
            businessCard: {
                container: {
                    backgroundColor: GENERICS.white,
                },
                phoneIcon: {
                    color: GREY_UPDATED.x400,
                },
            },
            navigationSection: {
                breadcrumbNavigation: {
                    color: GENERICS.white,
                },
            },
            introSection: {
                smallCompanyImage: {
                    backgroundColor: GENERICS.white,
                },
            },
            discoverySection: {
                topSection: {
                    borderColor: GREY_UPDATED.x300,
                },
                bottomSection: {
                    backgroundColor: DUST.x50,
                },
            },
            searchForm: {
                backgroundColor: GENERICS.white,
                divider: {
                    color: GREY_UPDATED.x300,
                },
            },
            gdprNote: {
                trigger: {
                    color: GREY_UPDATED.x500,
                },
                callToAction: {
                    color: GREY_UPDATED.x700,
                },
                popover: {
                    color: GENERICS.white,
                    backgroundColor: DUST.x300,
                },
            },
            detailsList: {
                backgroundColor: GENERICS.white,
                icon: {
                    color: GREY_UPDATED.x400,
                },
                text: {
                    color: GREY_UPDATED.x700,
                },
            },
            stickyActionButtons: {
                action: {
                    color: GENERICS.white,
                    backgroundColor: MEADOW.x500,
                    borderColor: GENERICS.white,
                    active: {
                        backgroundColor: MEADOW.x400,
                    },
                },
            },
        },
        savedAds: {
            card: {
                backgroundColor: GENERICS.white,
                color: GENERICS.black,
            },
            divider: {
                color: GREY_UPDATED.x300,
            },
            notificationSettings: {
                backgroundColor: GENERICS.white,
                boxShadow: EXPANDABLE_LIST_BOX_SHADOW,
                color: GREY.x500,
            },
            separator: {
                backgroundColor: GREY.x200,
            },
            notepad: {
                characterCounter: {
                    color: GREY_UPDATED.x500,
                },
                displayBorder: {
                    color: GREY_UPDATED.x400,
                },
            },
        },
        sitemap: {
            link: {
                color: GREY_UPDATED.x700,
            },
            headline: {
                borderColor: GREY_UPDATED.x300,
            },
        },
        investment: {
            promotedInvestments: {
                label: {
                    color: GREY_UPDATED.x400,
                },
            },
            topDevelopers: {
                image: {
                    backgroundColor: GREY_UPDATED.x100,
                },
                location: {
                    color: GREY_UPDATED.x500,
                },
            },
        },
        similarAdsContactForm: {
            backgroundColor: GREY_UPDATED.x100,
        },
    },
};

export const BASE: Theme = {
    deprecated: RAW_BASE,
};
