export const LAYER = {
    x0: '1',
    x1: '100',
    x2: '200',
    x3: '300',
    x4: '400',
    x5: '500',
    x6: '600',
    x7: '700',
    x8: '800',
    x9: '900',
    x10: '1000',
} as const;
