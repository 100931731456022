const NEWRELIC_IGNORED_ERROR_MESSAGES = [
    "Can't find variable: _AutofillCallbackHandler", // caused by Safari autofill
];

const shouldIgnoreError = (error: Error): boolean => {
    return NEWRELIC_IGNORED_ERROR_MESSAGES.some((message) => error?.message.includes(message));
};

export const newrelicBrowserErrorHandler = (): void => {
    if (typeof window === 'undefined' || !('newrelic' in window)) {
        return;
    }

    const newrelic = (window as { newrelic?: { setErrorHandler: (callback: (error: Error) => boolean) => void } })
        .newrelic;

    newrelic?.setErrorHandler(shouldIgnoreError);
};
