import type { FeatureFlags } from '@domains/shared/types/featureFlags';
import type { Locale } from '@lib/i18n/types/locale';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useMemo } from 'react';

export interface SiteSettingsConfig {
    featureFlags: FeatureFlags;
    lang: Locale;
}

export const SiteSettingsContext = createContext<SiteSettingsConfig | null>(null);

export const SiteSettingsContextProvider: FC<PropsWithChildren<SiteSettingsConfig>> = ({
    children,
    featureFlags,
    lang,
}) => {
    const value = useMemo(
        () =>
            Object.freeze({
                featureFlags,
                lang,
            }),
        [featureFlags, lang],
    );

    return <SiteSettingsContext.Provider value={value}>{children}</SiteSettingsContext.Provider>;
};
