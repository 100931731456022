export const SKY_DEPRECATED = {
    x50: '#EEFDFF',
    x100: '#B6EEF7',
    x200: '#80DEEC',
    x300: '#55CADC',
    x400: '#35B4C8',
    x500: '#1F9AAD',
    x600: '#108193',
    x700: '#086371',
    x800: '#033F49',
    x900: '#001B1F',
} as const;

export const SKY = {
    x100: '#DCFAFF',
    x200: '#B0F3FD',
    x400: '#00C1DE',
    x500: '#0399BB',
    x600: '#008FBE',
};

export const TOMATO = {
    x50: '#FFF1EE',
    x100: '#FEC8BF',
    x200: '#FAA091',
    x300: '#F47D68',
    x400: '#E96048',
    x500: '#DB4930',
    x600: '#C4361D',
    x700: '#A72711',
    x800: '#861C09',
    x900: '#631305',
} as const;

export const APPLE = {
    x100: '#FCF0ED',
    x200: '#F6CDC6',
    x400: '#EF8674',
    x500: '#F1654D',
    x600: '#D2503E',
} as const;

export const SEA = {
    x100: '#EAF7F9',
    x200: '#BCE6EB',
    x400: '#4CD5E4',
    x500: '#3EC1CF',
    x600: '#22A4B7',
} as const;

export const PUMPKIN = {
    x100: '#FFF4EE',
    x200: '#FFEADF',
    x300: '#FFA066',
    x400: '#FF8842',
    x500: '#FF7200',
    x600: '#E55D1A',
    x700: '#C64A12',
    x800: '#A33A0F',
    x900: '#802C0D',
} as const;

export const MEADOW = {
    x50: '#F2FBF6',
    x100: '#E5F7ED',
    x200: '#CCF0DB',
    x300: '#42E184',
    x400: '#1FCE65',
    x500: '#00B54B',
    x600: '#00923C',
    x700: '#007A32',
    x800: '#005222',
    x900: '#012912',
} as const;

export const BERRY = {
    x100: '#FFF1F0',
    x400: '#E9564D',
    x500: '#C23B33',
};

// error colors
export const ERROR = {
    x100: '#FFF3F3',
    x500: '#EE4047',
} as const;

// warning colors
export const WARNING = {
    x100: '#FFF9EC',
    x500: '#FFC624',
} as const;

// success colors
export const SUCCESS = {
    x100: '#E5F7ED',
    x500: '#00B54B',
} as const;

// info colors
export const INFO = {
    x100: '#EBF3FD',
    x500: '#3D8DEB',
} as const;

export const GENERICS = {
    white: '#ffffff',
    black: '#000000',
    text: '#1b232e',
    none: 'none',
    transparent: 'transparent',
} as const;

/* colors defined based on Azulejo | before 01.12.2020 */
/** @deprecated */
export const GREY = {
    /** @deprecated */
    x50: '#f8f8f8',
    /** @deprecated */
    x100: '#f0f0f0',
    /** @deprecated */
    x200: '#e0e0e0',
    /** @deprecated */
    x300: '#bdbdbd',
    /** @deprecated */
    x400: '#757575',
    /** @deprecated */
    x500: '#424242',
    /** @deprecated */
    x600: '#212121',
} as const;

/* colors defined after 01.03.2021 */
export const GREY_UPDATED = {
    x100: '#f8f8f8',
    x200: '#efefef',
    x300: '#e0e0e0',
    x400: '#bdbdbd',
    x500: '#757575',
    x600: '#404041',
    x700: '#212121',
} as const;

export const DUST = {
    x50: '#f2f3f4',
    x100: '#e4e6e8',
    x200: '#4f5865',
    x300: '#232e3f',
    x400: '#101620',
} as const;
