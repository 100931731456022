import { captureException } from '@sentry/nextjs';

type LoggingEnvironment =
    | 'development.browser'
    | 'development.server'
    | 'production.browser'
    | 'production.server'
    | 'test';
interface LogOptions {
    hideOnEnvs: LoggingEnvironment[]; // Array with envs where logs should be hidden
}
type LogData = Record<string, unknown>;
type Logger = (message: string, data?: LogData, options?: LogOptions) => void;
type FunctionName = 'log' | 'error' | 'info' | 'warn';
type ReusableLogger = (logFunctionName: FunctionName, ...rest: Parameters<Logger>) => void;

const SENTRY_IGNORED_USER_MESSAGES = ['Missing translation'];

const checkIsNetworkErrorToSkip = (message: string): boolean => {
    return [
        '[Network] Failed to fetch', // Chrome
        '[Network] Load failed', // Mobile Safari, Chrome Mobile iOS, Safari Mac
        '[Network] The operation was aborted',
        '[Network] NetworkError when attempting to fetch resource', // FF, Linux; FF Mobile
    ].some((errorMessage) => message.includes(errorMessage));
};

const shouldHideLog = (environmentsWithHiddenLogs: LoggingEnvironment[]): boolean => {
    const isServer = typeof window === 'undefined';
    const environment = process.env.NODE_ENV;
    const environmentLabel = isServer ? 'server' : 'browser';
    const currentEnv = environment === 'test' ? environment : `${environment}.${environmentLabel}`;

    return environmentsWithHiddenLogs.includes(currentEnv as LoggingEnvironment);
};

const prepareData = (data: LogData): LogData => {
    const { error } = data;

    if (Array.isArray(error) && error.length === 1) {
        return {
            ...data,
            error: error[0],
        };
    }

    return data;
};

export const getErrorType = (error: unknown): string => {
    const defaultErrorType = 'not_defined';

    if (!error) {
        return defaultErrorType;
    }
    const errorToCheck = Array.isArray(error) ? error[0] : error;

    if (typeof errorToCheck === 'string') {
        return errorToCheck || defaultErrorType;
    }
    if (typeof errorToCheck === 'object' && typeof errorToCheck?.message === 'string') {
        return errorToCheck?.message || defaultErrorType;
    }

    return defaultErrorType;
};

// eslint-disable-next-line unicorn/no-object-as-default-parameter -- temporarily skip refactoring necessity
const reusableLog: ReusableLogger = (logFunctionName, message, data = {}, options = { hideOnEnvs: [] }) => {
    if (options.hideOnEnvs.length > 0 && shouldHideLog(options.hideOnEnvs)) {
        return;
    }

    const { error } = prepareData(data);
    const valueToLog = {
        type: getErrorType(error).split(',', 2)[0], // Use only to value before first comma (for GraphQL it can be very long)
        data,
    };

    // send to Sentry
    if (
        logFunctionName === 'error' &&
        !SENTRY_IGNORED_USER_MESSAGES.some((ignoredMessage) => message.includes(ignoredMessage)) &&
        // Filter out errors which occurs on Abort - e.g. after log in (redirect from frontend-platform to Atlas)
        // example: Unable to load current user! {type: '[Network] Failed to fetch', data: {…}}
        !(error instanceof Error && error.name === 'CombinedError' && checkIsNetworkErrorToSkip(error.message))
    ) {
        // We use here captureException & `new Error` to capture stack trace
        captureException(new Error(message), {
            extra: { ...valueToLog, data: JSON.stringify(data), isConsoleError: true },
            level: 'error',
        });
    }

    if (typeof window === 'undefined') {
        // stringify for Kibana, server-side
        return console[logFunctionName](JSON.stringify({ message, ...valueToLog })); // eslint-disable-line no-console -- functions in this file are wrappers for console.*
    }

    // Print in browser console
    return console[logFunctionName](message, valueToLog); // eslint-disable-line no-console -- functions in this file are wrappers for console.*
};

export const log: Logger = (...parameters) => reusableLog('log', ...parameters);
export const logError: Logger = (...parameters) => reusableLog('error', ...parameters);
export const logInfo: Logger = (...parameters) => reusableLog('info', ...parameters);
export const logWarn: Logger = (...parameters) => reusableLog('warn', ...parameters);
