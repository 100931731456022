import { BASE } from './base';
import type { Theme } from './type';

const isObject = (item: unknown): boolean => {
    return !!item && typeof item === 'object' && !Array.isArray(item);
};

const ALLOWED_PROPERTIES = new Set([
    'background',
    'backgroundColor',
    'color',
    'borderColor',
    'outlineColor',
    'boxShadow',
    'fill',
    'stroke',
]);

type PartialTheme = Record<string, unknown>;

/* @TODO refactor this code - get rid of it if possible */
const mergeConfig = (oldConfig: PartialTheme, newConfig: PartialTheme): PartialTheme => {
    return Object.entries(newConfig).reduce((total: PartialTheme, [key, value]: [string, unknown]) => {
        if (isObject(value)) {
            total[key] = mergeConfig(total[key] as PartialTheme, value as PartialTheme);
        } else if (ALLOWED_PROPERTIES.has(key)) {
            total[key] = value;
        }

        return total;
    }, oldConfig);
};

export const extendTheme = (newTheme?: PartialTheme): Theme => {
    const theme: Theme = JSON.parse(JSON.stringify(BASE));

    if (newTheme && isObject(newTheme)) {
        return mergeConfig(theme as unknown as PartialTheme, newTheme) as unknown as Theme;
    }

    return theme;
};
