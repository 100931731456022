/* eslint-disable unicorn/filename-case -- treat filename fix separately */
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { createContext, memo, useEffect, useMemo, useState } from 'react';

export interface RWD {
    isDesktop: boolean | null;
    isTablet: boolean | null;
}

export const RWDContext = createContext<RWD>({ isDesktop: null, isTablet: null });

const MountRwdContextBase: FC<{
    setIsTablet: Dispatch<SetStateAction<boolean | null>>;
    setIsDesktop: Dispatch<SetStateAction<boolean | null>>;
}> = ({ setIsTablet, setIsDesktop }) => {
    useEffect(() => {
        const mqlSm = window.matchMedia(
            `(min-width: ${BREAKPOINT.sm}) and (max-width: ${Number.parseInt(BREAKPOINT.md, 10) - 1}px)`,
        );
        const mqlMd = window.matchMedia(`(min-width: ${BREAKPOINT.md})`);

        const onSmBreakpointChange = (event: MediaQueryListEvent): void => {
            setIsTablet(event.matches);
        };
        const onMdBreakpointChange = (event: MediaQueryListEvent): void => {
            setIsDesktop(event.matches);
        };

        if (mqlMd.addEventListener) {
            mqlSm.addEventListener('change', onSmBreakpointChange);
            mqlMd.addEventListener('change', onMdBreakpointChange);
        } else {
            mqlSm.addListener(onSmBreakpointChange);
            mqlMd.addListener(onMdBreakpointChange);
        }

        setIsTablet(mqlSm.matches);
        setIsDesktop(mqlMd.matches);

        return (): void => {
            // @ts-expect-error Description:
            // Typescript thinks that method 'addEventListener' always exist. Unluckily on Safari 13 it doesnt exist and application is crashing.
            // This is a workaround
            if (mqlMd.addEventListener) {
                mqlSm.removeEventListener('change', onSmBreakpointChange);
                mqlMd.removeEventListener('change', onMdBreakpointChange);
            } else {
                mqlSm.removeListener(onSmBreakpointChange);
                mqlMd.removeListener(onMdBreakpointChange);
            }
        };
    }, [setIsDesktop, setIsTablet]);

    return null;
};

const MountRwdContext = memo(MountRwdContextBase);

/**
 * This component will help displaying different components for mobile and desktop.
 * The challenge is that:
 * - we cannot rely on JS only, because one type of users will experience layout shifts.
 *   (We don't know user device type during SSR so we must assume one)
 * - we also cannot rely on CSS alone because DOM will contain duplicated content.
 *
 * The solution is to use hybrid approach:
 * 1. Render everything during SSR (mobile and desktop components)
 * 2. Use CSS for media-queries so user will see correct website version
 * 3. Once in browser use JS to unmount duplicated components.
 */
export const RWDContextProvider: FC<PropsWithChildren<{ initialIsDesktop?: RWD['isDesktop'] }>> = ({
    children,
    initialIsDesktop = false,
}) => {
    const [isTablet, setIsTablet] = useState<RWD['isTablet']>(false);
    const [isDesktop, setIsDesktop] = useState<RWD['isDesktop']>(initialIsDesktop);

    const value = useMemo(() => ({ isTablet, isDesktop }), [isDesktop, isTablet]);

    return (
        <RWDContext.Provider value={value}>
            <MountRwdContext setIsTablet={setIsTablet} setIsDesktop={setIsDesktop} />
            {children}
        </RWDContext.Provider>
    );
};
/* eslint-enable unicorn/filename-case */
