import type { FeatureFlags } from '@domains/shared/types/featureFlags';

export const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
    // Avm = Automated Valuation Model | For more information about this feature, see: frontend/domains/ad/components/AdAvmModule/README.md
    isAdAvmModuleEnabled: null,
    isAdFinanceBannerEnabled: null,
    isAdFinanceLinkEnabled: null,
    isAdMortgageSimulatorEnabled: null,
    isFeaturedDevelopmentVASEnabled: null,
    isListingRentPriceEnabled: null,
    isNewApartmentsForSalePostingFormEnabled: null,
    isOldSaveSearchQueryEnabled: null,
    isFinanceCheckboxEnabled: null,
    isObservedAdsPageEnabled: null,
    isBikPromotionEnabled: null,
    isSpecialOfferForUnitsExperimentEnabled: null,
    isSpecialOfferEnabled: null,
    isSeoAdsDescriptionEnabled: null,
    isPropertyEvaluationPageActive: null,
    isSettingsContactsTabMigrated: null,
    isExtendedBillingDataEnabled: null,
    isRESellerLeadsActive: null,
    isBadgesFeatureEnabled: null,
    isSettingsOurTeamTabEnabled: null,
    isHCIAMEnabled: null,
    isSellerLeadsMvpEnabled: null,
    isNexusSellerPageEnabled: null,
    isHCIAMAgencyRegistrationEnabled: null,
    isHCIAMAgentRegistrationEnabled: null,
    isHCIAMDeveloperRegistrationEnabled: null,
    isMessagingCenterEnabled: null,
    isMessagingCenterChatEnabled: null,
    isNexusInvestmentPageEnabled: null,
};
