import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { Fonts } from '@lib/styles/fonts/Fonts';
import { GlobalStyles } from '@lib/styles/GlobalStyles';
import { THEME } from '@lib/styles/themes/[[[SITECODE]]]';
import { theme as nexusTheme } from '@lib/styles/themes/nexus';
import type { Theme } from '@lib/styles/themes/type';
import { Provider as NexusThemeProvider } from '@nexus/lib-react/dist/core/Provider';
import type { FC, ReactNode } from 'react';

interface ThemeProviderProps {
    children: ReactNode;
    theme?: Theme;
}

const { staticAssetsPrefix } = getPublicEnvConfig();

export const ThemeProvider: FC<ThemeProviderProps> = ({ children, theme }) => {
    let mergedTheme = { ...nexusTheme, ...theme };

    if (!theme) {
        mergedTheme = { ...mergedTheme, ...THEME };
    }

    return (
        <NexusThemeProvider theme={mergedTheme}>
            <Fonts staticAssetsPrefix={staticAssetsPrefix} />
            <GlobalStyles />
            {children}
        </NexusThemeProvider>
    );
};
