export type Targeting = Record<string, number | string | string[]>;

export interface Baxter {
    queue: Array<() => void>;
    clear: () => void;
    load: () => void;
    consent: () => void;
    page: (name: string, params: Targeting) => void;
    set: (params: Record<string, unknown>, autoload: boolean) => void;
    setAll: (params: { page: string } & Targeting) => void;
    features: Record<string, Record<string, () => void>>;
}

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Used by 3rd party service
        GPT?: {
            targeting: Targeting;
        };
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Used by 3rd party service
        Baxter: Partial<Baxter>;
        baxterTargeting?: Targeting;
    }
}

let didInit = false;

export const init = (): void => {
    window.Baxter = window.Baxter || {};
    window.Baxter.queue = window.Baxter.queue || [];

    didInit = true;
};

export const clear = (): void => {
    window.Baxter = {};
};

export const getBaxterClient = (): Partial<Baxter> => {
    if (!didInit) {
        init();
    }

    return window.Baxter;
};

export const initSlots = (page: string, targeting?: unknown): void => {
    const baxterClient = getBaxterClient();

    baxterClient?.queue?.push(() => {
        if (baxterClient?.page) {
            baxterClient?.page?.(page, { ...(targeting as Record<string, unknown>), page });
            baxterClient?.set?.({}, true);
        }
    });
};
