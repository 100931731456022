import { SITE_CONFIG } from '@config/siteConfig';
import { logWarn } from '@domains/shared/helpers/logger';

const { shouldUnregisterServiceWorker } = SITE_CONFIG;

const SERVICE_WORKER_URL = '/statics/scripts/pushNotificationsServiceWorker.js';

export const unregisterServiceWorker = async (): Promise<'unavailable' | 'successful' | 'error'> => {
    const shouldUnregisterSW =
        shouldUnregisterServiceWorker &&
        typeof window !== 'undefined' &&
        typeof navigator !== 'undefined' &&
        'serviceWorker' in navigator;

    if (!shouldUnregisterSW) {
        return 'unavailable';
    }

    try {
        const registration = await navigator.serviceWorker?.getRegistration(SERVICE_WORKER_URL);
        registration?.unregister();
    } catch (error) {
        logWarn('[unregisterServiceWorker] failed', { error });

        return 'error';
    }

    return 'successful';
};
