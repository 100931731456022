import { getCurrentScope } from '@sentry/nextjs';
import type { NextRouter } from 'next/router';
import { useEffect } from 'react';

interface RouteChangeErrorType {
    cancelled: boolean;
}

export const handleRouteChangeStart = (): void => {
    getCurrentScope().setTag('loadStatus', 'spa_loading');
};
export const handleRouteChangeComplete = (): void => {
    // we are triggering this in case of SPA navigation change with page loading in progress
    // so the window.load event is not triggered yet
    getCurrentScope().setTag('loadStatus', 'spa_loaded');
};
export const handleRouteChangeError = (error: RouteChangeErrorType): void => {
    // if page load was cancelled we can track it
    if (error?.cancelled) {
        // the case when one SPA load is in progress and we navigate to another SPA page
        getCurrentScope().setTag('loadStatus', 'spa_cancelled');

        return;
    }

    // otherwise it's other type of error and we want to mark it as well
    getCurrentScope().setTag('loadStatus', 'spa_error');
};

export const useSentryCustomTagsMonitoring = (router: NextRouter): void => {
    useEffect(() => {
        // docs: https://nextjs.org/docs/pages/api-reference/functions/use-router#routerevents
        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        router.events.on('routeChangeError', handleRouteChangeError);

        return (): void => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
            router.events.off('routeChangeError', handleRouteChangeError);
        };
    }, [router]);
};
