// All properties are following typography guide https://app.zeplin.io/project/5f901d0f7477cd228fb5cd2d/screen/5f901d545d0eca60980bbc62
export const SIZE = {
    /** @deprecated **/
    x1: 13,
    /** @deprecated **/
    x2: 15,
    /** @deprecated **/
    x3: 16,
    /** @deprecated **/
    x4: 18,
    /** @deprecated **/
    x5: 21,
    /** @deprecated **/
    x6: 25,
    /** @deprecated **/
    x7: 32,
    /** @deprecated **/
    x8: 36,
    h1: '36px',
    h2: '32px',
    h3: '28px',
    h4: '24px',
    h5: '20px',
    h6: '16px',
    p1: '18px',
    p2: '16px',
    p3: '14px',
    p4: '12px',
    p5: '10px',
} as const;

export const WEIGHT = {
    regular: 400,
    semibold: 600,
    bold: 700,
} as const;

export const LINE_HEIGHT = {
    x1: '1.5',
    x2: '1.4',
} as const;

const FAMILY = {
    openSans: 'Open Sans',
    arial: 'Arial',
    sora: 'Sora',
} as const;

export const OPEN_SANS_FAMILY = `${FAMILY.openSans}, ${FAMILY.arial}, sans-serif`;

export const SORA_FAMILY = `${FAMILY.sora}, sans-serif`;

export const LETTER_SPACING = {
    x1: '0.35px',
};
