import { logError, logWarn } from '@domains/shared/helpers/logger';

/**
 * Utility functions for local storage
 * To be used only where useLocalStorageCache can't be used
 */

type StorageType = 'localStorage' | 'sessionStorage';

interface StorageOptions {
    storageType?: StorageType;
    keyPrefix?: string;
}

interface GetStorageOptions<T extends boolean> extends StorageOptions {
    shouldParse?: T;
}
type GetStorageItemValue<T> = T extends true ? unknown : string | null;

const DEFAULT_STORAGE_TYPE: StorageType = 'localStorage';
const DEFAULT_OPTIONS: StorageOptions = {
    storageType: DEFAULT_STORAGE_TYPE,
};

const getStorage = (storageType: StorageType): Storage | undefined => {
    if (typeof window === 'undefined') {
        logError('[getStorage] storage should not be used outside of effect');

        return;
    }
    try {
        return window[storageType];
    } catch (error) {
        if (error instanceof DOMException && error.name === 'SecurityError') {
            logWarn('[getStorage] storage is not accessible because of the browser settings', { error, storageType });

            return;
        }
        logError('[getStorage] storage is not accessible', { error });
    }
};

export const getStorageItemValue = <T extends boolean>(
    key: string,
    options: GetStorageOptions<T> = DEFAULT_OPTIONS,
): GetStorageItemValue<T> => {
    const { storageType = DEFAULT_STORAGE_TYPE, keyPrefix, shouldParse = true } = options;

    const storage = getStorage(storageType);
    if (!storage) {
        return null;
    }

    try {
        const data = storage.getItem(keyPrefix ? `${keyPrefix}${key}` : key);

        if (data) {
            return shouldParse ? JSON.parse(data) : data;
        }
    } catch (error) {
        logError('[getStorageItemValue] error when getting an item', { key, error });
    }

    return null;
};

export const setStorageItemValue = (key: string, value: unknown, options: StorageOptions = DEFAULT_OPTIONS): void => {
    const { storageType = DEFAULT_STORAGE_TYPE, keyPrefix } = options;

    const storage = getStorage(storageType);
    if (!storage) {
        return;
    }

    try {
        storage.setItem(keyPrefix ? `${keyPrefix}${key}` : key, JSON.stringify(value));
    } catch (error) {
        logError('[setStorageItemValue] error when setting an item', { key, error });
    }
};

export const removeStorageItem = (key: string, options: StorageOptions = DEFAULT_OPTIONS): void => {
    const { storageType = DEFAULT_STORAGE_TYPE, keyPrefix } = options;

    const storage = getStorage(storageType);
    if (!storage) {
        return;
    }

    try {
        storage.removeItem(keyPrefix ? `${keyPrefix}${key}` : key);
    } catch (error) {
        logError('[removeStorageItem] error when removing an item', { key, error });
    }
};
