export const ESTATE = {
    all: 'ALL',
    flat: 'FLAT',
    studioFlat: 'STUDIO_FLAT',
    house: 'HOUSE',
    room: 'ROOM',
    terrain: 'TERRAIN',
    commercialProperty: 'COMMERCIAL_PROPERTY',
    hall: 'HALL',
    garage: 'GARAGE',
    investment: 'INVESTMENT',
} as const;

export type Estate = ObjectValues<typeof ESTATE>;
