import { WEB_VITALS_SCRIPT } from '@config/headLinksConfig';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import { normalizePathname } from '@lib/routes/normalizePathname';
import type { NextWebVitalsMetric } from 'next/dist/shared/lib/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export interface Metric {
    /**
     * The name of the metric (in acronym form).
     */
    name: 'CLS' | 'FCP' | 'FID' | 'INP' | 'LCP' | 'TTFB';

    /**
     * The current value of the metric.
     */
    value: number;

    /**
     * The rating as to whether the metric value is within the "good",
     * "needs improvement", or "poor" thresholds of the metric.
     */
    rating: 'good' | 'needs-improvement' | 'poor';

    /**
     * The delta between the current value and the last-reported value.
     * On the first report, `delta` and `value` will always be the same.
     */
    delta: number;

    /**
     * A unique ID representing this particular metric instance. This ID can
     * be used by an analytics tool to dedupe multiple values sent for the same
     * metric instance, or to group multiple deltas together and calculate a
     * total. It can also be used to differentiate multiple different metric
     * instances sent from the same page, which can happen if the page is
     * restored from the back/forward cache (in that case new metrics object
     * get created).
     */
    id: string;

    /**
     * Any performance entries relevant to the metric value calculation.
     * The array may also be empty if the metric value was not based on any
     * entries (e.g. a CLS value of 0 given no layout shifts).
     */
    entries: unknown[];

    /**
     * The type of navigation.
     *
     * This will be the value returned by the Navigation Timing API (or
     * `undefined` if the browser doesn't support that API), with the following
     * exceptions:
     * - 'back-forward-cache': for pages that are restored from the bfcache.
     * - 'prerender': for pages that were prerendered.
     * - 'restore': for pages that were discarded by the browser and then
     * restored by the user.
     */
    navigationType: 'navigate' | 'reload' | 'back-forward' | 'back-forward-cache' | 'prerender' | 'restore';
}

type WebVitalsCallback = (metric: Metric) => void;
interface ReportOptions {
    reportAllChanges?: boolean;
    durationThreshold?: number;
}

declare global {
    interface Window {
        webVitals?: {
            onCLS?: (callback: WebVitalsCallback, options?: ReportOptions) => void;
            onFCP?: (callback: WebVitalsCallback, options?: ReportOptions) => void;
            onINP?: (callback: WebVitalsCallback, options?: ReportOptions) => void;
            onLCP?: (callback: WebVitalsCallback, options?: ReportOptions) => void;
            onTTFB?: (callback: WebVitalsCallback, options?: ReportOptions) => void;
        };
        reIsWebVitalLoaded?: boolean;
    }
}

/**
 * Browser monitoring metric - based on Optimus Web `OptimusMetric` interface
 */
interface BrowserMonitoringMetric {
    /**
     * Label
     */
    l: NextWebVitalsMetric['label'];
    /**
     * Name
     */
    n: NextWebVitalsMetric['name'];
    /**
     * Value
     */
    v: NextWebVitalsMetric['value'];
    /**
     * Url/RouteId
     */
    u: string;
    /**
     * Referer Url/RouteId
     */
    r: string;
    /**
     * Version
     */
    h: string;
    /**
     * Laquesis
     */
    q: string;
    /**
     * User type
     */
    s: 'private' | 'professional' | '';
}

interface CustomRef {
    current: string;
}

export const PREVIOUS_PAGE_PATHNAME: CustomRef = {
    current: 'unknown',
};

export const CURRENT_PAGE_PATHNAME: CustomRef = {
    current: 'init',
};

const EXPERIMENTS: CustomRef = {
    current: '',
};

const { env, webVitalsReportingMode } = getPublicEnvConfig();

// This export is used for testing purpose - to mock different environments
export const ENVIRONMENT = { value: env };

const reportWebVitals = (metric: Metric): void => {
    if (ENVIRONMENT.value === 'PRD' || ENVIRONMENT.value === 'STG') {
        // send data only on STG & PRD environments
        const metricsPayload: BrowserMonitoringMetric = {
            l: 'web-vital', // In the experimental phase, we support only `web-vital`
            n: metric.name,
            v: metric.value,
            u: `r:${CURRENT_PAGE_PATHNAME.current}`,
            r: PREVIOUS_PAGE_PATHNAME.current,
            h: (process.env.NEXT_PUBLIC_SENTRY_RELEASE || '').replace(/\D/g, '') || 'unknown',
            q: EXPERIMENTS.current,
            s: '', // In the experimental phase, we do not recognize user type
        };

        const body = JSON.stringify(metricsPayload, undefined, 0);

        if (navigator.sendBeacon) {
            navigator.sendBeacon('/api/bm', body);
        } else {
            // Fallback
            fetch('/api/bm', {
                body,
                method: 'POST',
                keepalive: true,
            });
        }
    } else if (ENVIRONMENT.value === 'web-vitals') {
        // eslint-disable-next-line no-console -- used on purpose only in the local env to display simplified view
        console.info(`WEBVITALSCHECK:${metric.name}:${Math.round(metric.value * 100) / 100}`);
    }

    if (webVitalsReportingMode.value === 'verbose') {
        // In local environment print info to console
        // eslint-disable-next-line no-console -- used on purpose only in the local env to display simplified view
        console.info(`[Web Vitals] ${metric.name}: ${metric.value} (${metric.rating})`, metric);
    }
};

const mapExperimentsToString = (experiments: LaquesisPageProps['experiments']): string => {
    return Object.entries(experiments || {})
        .filter(([, value]) => !!value)
        .map(([key, value]) => `${key}@${value}`)
        .join('#')
        .toLowerCase();
};

/**
 * Normalize referer. The service accepts only 4-chars referer
 */
const normalizeReferer = (pathname: string): string => {
    return pathname.replace('/[lg]', '').slice(0, 4);
};

// Based on https://github.com/vercel/next.js/blob/a3f4ad9887f2e460a6833b78445bc903c3107d8a/packages/next/src/client/web-vitals.ts
export const useReportWebVitals = ({ experiments }: { experiments: LaquesisPageProps['experiments'] }): void => {
    const { pathname } = useRouter();

    useEffect(() => {
        // Mark currently visible page as a reference
        PREVIOUS_PAGE_PATHNAME.current = normalizeReferer(CURRENT_PAGE_PATHNAME.current);
        CURRENT_PAGE_PATHNAME.current = normalizePathname(pathname);
    }, [pathname]);

    useEffect(() => {
        if (!window.performance && !window.PerformanceObserver) {
            // performance is not available - skip loading the package
            return;
        }
        if (!webVitalsReportingMode?.value) {
            return;
        }

        // Update experiments
        EXPERIMENTS.current = mapExperimentsToString(experiments);

        if (window.reIsWebVitalLoaded) {
            // web-vitals watch was already enabled
            return;
        }
        window.reIsWebVitalLoaded = true;

        // Load script
        const script = document.createElement('script');
        script.async = true;
        script.crossOrigin = 'anonymous';
        script.src = WEB_VITALS_SCRIPT;
        script.id = 'web-vitals-script';
        script.addEventListener('load', (): void => {
            const config = ENVIRONMENT.value === 'web-vitals' ? { reportAllChanges: true } : undefined;

            // When loading `web-vitals` using a classic script, all the public
            // methods can be found on the `webVitals` global namespace.
            window.webVitals?.onCLS?.(reportWebVitals, config);
            window.webVitals?.onLCP?.(reportWebVitals, config);
            window.webVitals?.onINP?.(reportWebVitals, config);
            window.webVitals?.onFCP?.(reportWebVitals, config);
            window.webVitals?.onTTFB?.(reportWebVitals, config);
        });
        document.head.append(script);
    }, [experiments]);
};
