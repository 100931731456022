import { ROUTE_SEGMENT } from '@config/routes';
import type { LocalRoute } from '@config/routes/type';
import { reverseRouteSegmentTranslation } from '@lib/routes/reverseRouteSegmentTranslation';

/**
 * Reverses translated pathname to translation key
 */
export const normalizePathname = (pathname: string): string => {
    const segments = pathname.split('/');
    segments.shift(); // Remove the first item - it is always empty

    const lang = /^[a-z]{2}$/.test(segments[0]) ? segments.shift() : null;
    const keysTakenIntoAccount = Object.keys(ROUTE_SEGMENT) as LocalRoute[];
    const reverseMapping = (segment: string): string =>
        segment && !segment.startsWith('[')
            ? reverseRouteSegmentTranslation(segment, keysTakenIntoAccount) ?? segment
            : segment;

    return ['', ...(lang ? ['[lg]'] : []), ...segments.map(reverseMapping)].join('/');
};
