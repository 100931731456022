import { SITE_CONFIG } from '@config/siteConfig';
// eslint-disable-next-line import/no-restricted-paths -- getEnvConfig will be moved to @lib/pages
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import type { HeadLinksItems } from '@lib/pages/document/HeadLinks/HeadLinks';

const { staticAssetsPrefix, webVitalsReportingMode } = getPublicEnvConfig();

export const WEB_VITALS_SCRIPT = `${staticAssetsPrefix}/scripts/web-vitals-4.2.3.iife.js`;

/**
 * All site codes head <link> configuration.
 */
export const HEAD_LINKS_CONFIG: HeadLinksItems = [
    // Generic
    { type: 'preconnect', href: SITE_CONFIG.metadata.preconnectStaticsCDN },
    { type: 'preconnect', href: 'https://ireland.apollo.olxcdn.com/' },
    ...(webVitalsReportingMode.value
        ? [
              {
                  type: 'preload',
                  href: WEB_VITALS_SCRIPT,
                  as: 'script',
                  crossOrigin: 'anonymous',
              } as const,
          ]
        : []),
    // Tracking
    {
        type: 'preload',
        href: 'https://ninja.data.olxcdn.com/ninja-real-estate.js',
        as: 'script',
    },
    // Ads
    // Baxter script is lazy-loaded, preload stylesheet only as it affects rendering
    { type: 'preload', href: SITE_CONFIG.baxter.urlStyling, as: 'style' } as const,
    // Additional Ninja related functionality
    { type: 'preconnect', href: 'https://tracking.olx-st.com/' },
    { type: 'preconnect', href: 'https://laquesis.data.olxcdn.com/' },
];
