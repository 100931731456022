export const LOCALE = {
    en: 'en',
    pl: 'pl',
    pt: 'pt',
    ro: 'ro',
} as const;

export type Locale = ObjectValues<typeof LOCALE>;

export const UPPER_CASE_LOCALE: Readonly<Record<Locale, Uppercase<Locale>>> = {
    en: 'EN',
    pl: 'PL',
    pt: 'PT',
    ro: 'RO',
} as const;

export type UppercaseLocale = ObjectValues<typeof UPPER_CASE_LOCALE>;
