import type { Environment } from '@type/server/environment';
import getConfig from 'next/config';

export interface ServerEnvConfig {
    isNewRelicBrowserMonitoringEnabled: boolean;
    newrelicBrowserMonitoringSampleRate: number;
    nodeGraphQlUrl: string;
    sentrySampleRateServer: number;
    sentryTracesSampleRateServer: number;
    googleApiKey: string;
    googleApiSecret: string;
    googleApiServerKey: string;
}

export interface PublicEnvConfig {
    appleCognitoLoginUrl: string | null;
    ninjaEnvironment: 'production' | 'staging' | 'testing';
    env: Environment;
    fbCognitoLoginUrl: string | null;
    fileUploadServiceUrl: string;
    googleApiKey: string;
    isCookieSDKEnabled: boolean;
    isOneTrustAutoDeleteEnabled: boolean;
    isOneTrustEnabled: boolean;
    webVitalsReportingMode: { value: 'verbose' | 'silent' | null }; // `silent` - report but do not console-log
    isSentryEnabled: boolean;
    googleCognitoLoginUrl: string | null;
    socialLoginRedirectURL: string | null;
    oneTrustSiteId: string;
    nodeEnv: 'production' | 'dev' | 'test' | undefined;
    sentryDsn?: string;
    sentryEnvironment?: string;
    sentrySampleRateClient: number;
    sentryTracesSampleRateClient: number;
    staticAssetsPrefix: string;
    /** Application date/time timezone */
    tz: string;
    hciamUrl: string;
    hciamClientId: string;
}

/**
 * Wrapper over `import getConfig from 'next/config'` for publicly available env variables with defined TS types.
 *
 * To be used in the client side.
 */
export const getPublicEnvConfig = (): PublicEnvConfig => {
    return getConfig().publicRuntimeConfig;
};

/**
 * Wrapper over `import getConfig from 'next/config'` for server available env variables with defined TS types.
 *
 * To be used in the server side, e.g. in `getServerSideProps`.
 */
export const getServerEnvConfig = (): ServerEnvConfig & PublicEnvConfig => {
    const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

    return { ...publicRuntimeConfig, ...serverRuntimeConfig };
};
