export const COMMERCIAL_PROPERTY_USE_TYPE = {
    gastronomy: 'GASTRONOMY',
    hotel: 'HOTEL',
    industrial: 'INDUSTRIAL',
    office: 'OFFICE',
    retail: 'RETAIL',
    services: 'SERVICES',
} as const;
const HALLS_USE_TYPE = {
    stock: 'STOCK',
    manufacturing: 'MANUFACTURING',
    office: 'OFFICE',
    commercial: 'COMMERCIAL',
} as const;

const COMMERCIAL_PROPERTY_USE_TYPE_VARIANTS = [
    COMMERCIAL_PROPERTY_USE_TYPE.gastronomy,
    COMMERCIAL_PROPERTY_USE_TYPE.hotel,
    COMMERCIAL_PROPERTY_USE_TYPE.industrial,
    COMMERCIAL_PROPERTY_USE_TYPE.office,
    COMMERCIAL_PROPERTY_USE_TYPE.retail,
    COMMERCIAL_PROPERTY_USE_TYPE.services,
];

const HALLS_USE_TYPE_VARIANTS = [
    HALLS_USE_TYPE.stock,
    HALLS_USE_TYPE.manufacturing,
    HALLS_USE_TYPE.office,
    HALLS_USE_TYPE.commercial,
];
export type UseTypes =
    | (typeof COMMERCIAL_PROPERTY_USE_TYPE_VARIANTS)[number]
    | (typeof HALLS_USE_TYPE_VARIANTS)[number];
