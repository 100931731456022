import { DFP_USER_ID_COOKIE } from '@domains/shared/components/Advertising/consts/constants';
import { logError, logInfo } from '@domains/shared/helpers/logger';
import { getBaxterClient } from '@lib/baxter/baxter';
import type { CookiesCategorisation } from '@olxeu-eprivacy-storage/react';
import {
    isConsentGivenForCookie,
    subscribeToConsentChange,
    unsubscribeFromConsentChange,
    useCookieStorage,
    waitForUserDecision,
} from '@olxeu-eprivacy-storage/react';
import { memo, useEffect } from 'react';

declare global {
    interface Window {
        reDfpUserId?: string;
    }
}

const createUUID = (): string => {
    let uuid = '';
    try {
        uuid = window.crypto.randomUUID();
    } catch {
        try {
            const intArray = new Uint8Array(16);
            window.crypto.getRandomValues(intArray);

            intArray[6] = (intArray[6] & 0x0f) | 0x40;
            intArray[8] = (intArray[8] & 0x3f) | 0x80;

            const hexChars = '0123456789abcdef';
            for (let i = 0; i < 16; i++) {
                const byte = intArray[i];
                uuid += hexChars[(byte >> 4) & 0x0f] + hexChars[byte & 0x0f];

                if (i === 3 || i === 5 || i === 7 || i === 9) {
                    uuid += '-';
                }
            }
        } catch (error) {
            logError('[AdvertisingConsent] Error creating uuid', { error });
        }
    }

    return uuid;
};

export const BaseAdvertisingConsent = ({ cookieCategoryMap }: { cookieCategoryMap: CookiesCategorisation }): null => {
    const cookieStorage = useCookieStorage();

    useEffect(() => {
        let isMounted = true;
        const afterCookieConsentAcceptanceOrChange = (): void => {
            // Create cookies required by Baxter
            const dfpCookieValue = cookieStorage.get(DFP_USER_ID_COOKIE);
            if (!isConsentGivenForCookie(cookieCategoryMap, DFP_USER_ID_COOKIE)) {
                cookieStorage.remove(DFP_USER_ID_COOKIE);
            } else if (dfpCookieValue) {
                window.reDfpUserId = dfpCookieValue;
            } else {
                const expiresDate = new Date();
                expiresDate.setFullYear(expiresDate.getFullYear() + 1);
                const uuid = createUUID();
                cookieStorage.set(DFP_USER_ID_COOKIE, uuid, { expires: expiresDate });
                window.reDfpUserId = uuid;
            }

            // Initialize Baxter
            const baxter = getBaxterClient();

            baxter.queue?.push(() => {
                // @link https://git.naspersclassifieds.com/shared-services/payment-solutions/advertising/baxter/slots-cdn
                window.Baxter.consent?.();
            });
        };
        const onConsentChange = (): void => {
            logInfo('[AdvertisingConsent] Cookie consent changed');
            afterCookieConsentAcceptanceOrChange();
        };

        const waitForConsentAcceptance = async (): Promise<void> => {
            let action = 'closed';
            try {
                await waitForUserDecision();
            } catch {
                action = 'timeout';
            }
            logInfo(`[AdvertisingConsent] Cookie consent first view ${action}`);
            if (!isMounted) {
                return;
            }
            afterCookieConsentAcceptanceOrChange();
            subscribeToConsentChange(onConsentChange);
        };
        waitForConsentAcceptance();

        return (): void => {
            isMounted = false;
            unsubscribeFromConsentChange(onConsentChange);
        };
    }, [cookieCategoryMap, cookieStorage]);

    return null;
};

// Below `propsAreEqual` returns `true` on purpose. We don't want to re-render this component. It should be initialised once.
export const AdvertisingConsent = memo(BaseAdvertisingConsent, () => true);
