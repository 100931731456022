import type { SerializedStyles } from '@emotion/react';
import { css, Global } from '@emotion/react';
import { config } from '@fortawesome/fontawesome-svg-core';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LAYER } from '@lib/styles/partials/layers';
import { theme } from '@lib/styles/themes/nexus';
import type { Theme } from '@lib/styles/themes/type';
import { useTheme } from '@nexus/lib-react/dist/theme/emotionUtils';
import type { JSX } from 'react';

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

const ONE_TRUST_STYLES = css`
    // Turn off OneTrust backdrop fade-in animation
    .onetrust-pc-dark-filter {
        animation: none !important;
        transition: none !important;
    }
    // Almost turn off OneTrust message slide-up animation. It's being steered not by "transition" or "animation" property,
    // but "bottom" is being increased inside inline styles directly in jQuery way.
    #onetrust-banner-sdk {
        bottom: 0 !important;
        transition: none !important;
    }

    // Overrided One Trust cookie panel styles for more responsive design
    #onetrust-consent-sdk {
        #onetrust-banner-sdk {
            .ot-sdk-container {
                padding: 16px;
                width: 100%;
                #onetrust-group-container {
                    #onetrust-policy {
                        margin: 0;
                        #onetrust-policy-title,
                        .ot-dpd-title {
                            font-size: 12px;
                        }
                        #onetrust-policy-title {
                            margin-bottom: 0;
                        }
                        .ot-dpd-title {
                            margin: 0;
                        }
                        p {
                            font-size: 10px;
                            &#onetrust-policy-text {
                                margin: 4px 0 8px;
                                line-height: 1.2;
                                @media (max-width: ${BREAKPOINT.xs}) {
                                    font-size: 12px !important;
                                }
                            }
                            &.ot-dpd-desc {
                                margin: 4px 0 8px;
                                line-height: 1.3;
                                @media (max-width: ${BREAKPOINT.xs}) {
                                    font-size: 12px !important;
                                }
                            }
                            @media (max-width: ${BREAKPOINT.xs}) {
                                font-size: 12px !important;
                            }
                        }
                    }
                }
                #onetrust-button-group-parent {
                    #onetrust-button-group {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: row-reverse;
                        button {
                            margin-bottom: 0;
                            &#onetrust-accept-btn-handler {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    #onetrust-pc-sdk .ot-btn-container {
        padding-left: 16px;
        padding-right: 16px;
    }
    #onetrust-consent-sdk,
    #onetrust-banner-sdk {
        #onetrust-accept-btn-handler,
        #onetrust-reject-all-handler {
            background-color: #232e3f !important;
            border-color: #232e3f !important;
        }
    }
    #onetrust-banner-sdk.ot-iab-2 #onetrust-button-group button {
        width: 100%;
    }
    #onetrust-pc-sdk {
        #ot-pc-content {
            .ot-cat-grp {
                .ot-always-active {
                    color: #00b54b;
                }
            }
            .ot-tgl {
                input {
                    &:checked {
                        & + .ot-switch {
                            .ot-switch-nob {
                                background-color: #00b54b;
                                border: 1px solid #00b54b;
                                &:before {
                                    background-color: #fff;
                                    border-color: #fff;
                                }
                            }
                        }
                    }
                    &:focus {
                        & + .ot-switch {
                            outline: #00b54b solid 1px;
                        }
                    }
                }
            }
        }
        #ot-pc-lst {
            .ot-chkbox {
                input {
                    &:checked {
                        & ~ label {
                            &::before {
                                background-color: #000;
                            }
                        }
                    }
                }
                label {
                    &::before {
                        border: 1px solid #000;
                    }
                }
            }
            #filter-btn-handler {
                background-color: #000;
            }
        }
    }
`;

const LAQUESIS_SURVEY_STYLES = css`
    div.laq-survey * {
        color: ${theme.colors['text-global-primary']};
        font-family: Sora, sans-serif !important;
        z-index: ${theme.zIndices.modal};
    }

    .laq-survey label {
        font-weight: ${theme.fontWeights.bold};
    }

    .laq-layout--default
        .laq-question--radio_group
        .laq-layout__content
        .laq-radio-group__item
        .item__content
        input[type='radio'],
    .laq-layout--default
        .laq-question--radio_group
        .laq-layout__content
        .laq-radio-group__item
        .item__content
        input[type='radio']:checked,
    .laq-layout--default
        .laq-question--checkbox_group
        .laq-layout__content
        .laq-checkbox-group__item
        .item__content
        input[type='checkbox']:checked:after,
    .laq-layout--default
        .laq-question--checkbox_group
        .laq-layout__content
        .laq-checkbox-group__item
        .item__content
        input[type='checkbox'],
    .laq-layout--default
        .laq-question--checkbox_group
        .laq-layout__content
        .laq-checkbox-group__item
        .item__content
        input[type='checkbox']:checked {
        border-color: ${theme.colors['background-global-inverse']} !important;
    }

    .laq-layout--default
        .laq-question--radio_group
        .laq-layout__content
        .laq-radio-group__item
        .item__content
        input[type='radio']:checked:after,
    .laq-layout--default
        .laq-question--checkbox_group
        .laq-layout__content
        .laq-checkbox-group__item
        .item__content
        input[type='checkbox']:checked:after {
        background-color: ${theme.colors['background-global-inverse']} !important;
    }

    .laq-survey
        .laq-layout--default
        .laq-question--checkbox_group
        .laq-layout__content
        .laq-checkbox-group__item
        .item__content,
    .laq-survey
        .laq-layout--default
        .laq-question--checkbox_group
        .laq-layout__content
        .laq-checkbox-group__item--active,
    .laq-survey
        .laq-layout--default
        .laq-question--radio_group
        .laq-layout__content
        .laq-radio-group__item
        .item__content,
    .laq-survey .laq-layout--default .laq-question--radio_group .laq-layout__content .laq-radio-group__item--active {
        background-color: ${theme.colors['background-global-primary']} !important;
    }

    .laq-survey .laq-layout--welcome .laq-layout__actions--accept {
        color: ${theme.colors['text-global-inverse']};
        background-color: ${theme.colors['background-brand-secondary']};
        border-color: ${theme.colors['background-brand-secondary']};
    }

    .laq-survey .laq-layout--welcome .laq-layout__actions--decline {
        color: ${theme.colors['text-global-inverse']};
        background-color: ${theme.colors['background-global-inverse']};
        border-color: ${theme.colors['background-global-inverse']};
    }

    .laq-survey .laq-top-nav__step {
        background-color: ${theme.colors['background-brand-secondary']};
    }
`;

const getBodyStyles = (theme: Theme): SerializedStyles => css`
    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }
    body {
        margin: 0;
        padding: 0;
        background-color: #fff;
        color: #1b232e;
        font-family: ${theme.deprecated.globals.fontFamily};
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: calc(20 / 14);
    }

    input,
    textarea,
    button {
        font-family: inherit;
    }

    ${ONE_TRUST_STYLES}

    #LeadboosterContainer[role='complementary'] {
        z-index: ${LAYER.x2} !important;
    }

    ${LAQUESIS_SURVEY_STYLES}
`;

export const GlobalStyles = (): JSX.Element => {
    const theme = useTheme();

    return <Global styles={getBodyStyles(theme)} />;
};
