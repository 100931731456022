import type { LocalRoutes } from './type';

export const ROUTE_SEGMENT: LocalRoutes = {
    account: 'konto',
    activate: 'aktywuj',
    active: 'aktywne',
    ad: 'oferta',
    agencies: 'posrednicy-nieruchomosci',
    agencyCompanies: 'biura-nieruchomosci',
    agents: 'agenci-nieruchomosci',
    archived: 'zaarchiwizowane',
    business: 'business',
    callRequest: 'zamow-rozmowe',
    category: 'kategoria',
    clipboard: 'zapisane',
    companies: 'firmy',
    confirmPassword: 'potwierdz-haslo',
    contacts: 'kontakty',
    developerCompanies: 'deweloperzy',
    developers: 'deweloperzy',
    domainSearch: 'wyniki',
    editAd: 'edytuj-ogloszenie',
    emailNotifications: 'powiadomienia-email',
    emails: 'emails',
    evaluate: 'wycen',
    fbLoginCallbackPage: 'social-login-redirect',
    finance: 'finanse',
    history: 'historia',
    investment: 'inwestycja',
    investments: 'inwestycje',
    invoiceData: 'dane-do-faktury',
    invoices: 'faktury',
    locationsMap: 'miejscowosci',
    login: 'login',
    manage: 'zarzadzaj',
    moderated: 'zmoderowane',
    mortgageBrowser: 'kredyty-hipoteczne',
    myaccount: 'mojekonto',
    newAd: 'nowe-ogloszenie',
    newInvestment: 'nowainwestycja',
    passwordChanged: 'haslo-zmienione',
    payments: 'platnosci',
    preview: 'podglad',
    pricing: 'cennik',
    privateUsers: 'uzytkownicy-indywidualni',
    profile: 'profil',
    profileActivation: 'aktywacja-profilu',
    prolong: 'przedluz',
    promote: 'promuj',
    propertyEvaluation: 'wycena-nieruchomosci',
    register: 'rejestracja',
    registerAgent: 'agent-nieruchomosci',
    registerBusiness: 'rejestracja-firmy',
    registerBusinessAgency: 'biuro-nieruchomosci',
    renting: 'wynajem',
    reset: 'reset',
    restored: 'przywrocono',
    savedAds: 'ogloszenia',
    savedSearches: 'wyszukiwania',
    schedulePromote: 'zaplanuj-promowanie',
    search: 'oferty',
    searchNotFound: 'brak-wyszukiwania',
    selling: 'sprzedaz',
    settings: 'ustawienia',
    sitemap: 'mapa-kategorii',
    sublisting: 'ogloszenia',
    subscription: 'subskrypcja',
    success: 'sukces',
    summary: 'podsumowanie',
    thankYou: 'dziekujemy',
    unsubscribe: 'zrezygnuj',
    unsubscribed: 'zrezygnowano',
    updated: 'zaktualizowano',
    waiting: 'oczekujace',
    wallet: 'portfel',
    cityLanding: 'nieruchomosci',
    crm: 'crm',
    authorization: 'authorization',
    status: 'status',
};
