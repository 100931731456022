import type { SerializedStyles } from '@emotion/react';
import { css, Global } from '@emotion/react';
import Head from 'next/head';
import type { JSX } from 'react';
import { memo, useMemo } from 'react';

export const FONT_FAMILY = {
    openSans: 'Open Sans',
    sora: 'Sora',
};

type FontFamily = ObjectValues<typeof FONT_FAMILY>;

const FONT_FOLDER_NAME = {
    [FONT_FAMILY.openSans]: 'OpenSans',
    [FONT_FAMILY.sora]: 'Sora',
};

interface ProduceFontFaceWithPreloadLinkProps {
    staticAssetsPrefix: string;
    family: FontFamily;
    filename: string;
    weight: number;
}

const produceFontFaceWithPreloadLink = ({
    staticAssetsPrefix,
    family,
    filename,
    weight,
}: ProduceFontFaceWithPreloadLinkProps): {
    styles: SerializedStyles;
    preloadLink: JSX.Element;
} => {
    const fontAssetUrl = `${staticAssetsPrefix}/fonts/${FONT_FOLDER_NAME[family]}/${filename}`;

    return {
        styles: css`
            @font-face {
                font-family: '${family}';
                font-weight: ${weight};
                src: url('${fontAssetUrl}');
                font-display: swap;
            }
        `,
        preloadLink: <link rel="preload" href={fontAssetUrl} as="font" type="font/woff2" crossOrigin="" />,
    };
};

const getGlobalFontStylesWithPreloadLinks = (
    staticAssetsPrefix: string,
    family: FontFamily,
): {
    styles: SerializedStyles;
    preloadLinks: JSX.Element;
} => {
    const regular = produceFontFaceWithPreloadLink({
        staticAssetsPrefix,
        family,
        filename: 'Regular.woff2',
        weight: 400,
    });
    const medium = produceFontFaceWithPreloadLink({
        staticAssetsPrefix,
        family,
        filename: 'SemiBold.woff2',
        weight: 600,
    });
    const bold = produceFontFaceWithPreloadLink({
        staticAssetsPrefix,
        family,
        filename: 'Bold.woff2',
        weight: 700,
    });

    return {
        styles: css`
            ${regular.styles}
            ${medium.styles}
            ${bold.styles}
        `,
        preloadLinks: (
            <>
                {regular.preloadLink}
                {medium.preloadLink}
                {bold.preloadLink}
            </>
        ),
    };
};

interface Props {
    staticAssetsPrefix: string;
    family?: FontFamily;
}

const BaseFonts = ({ staticAssetsPrefix, family = FONT_FAMILY.openSans }: Props): JSX.Element => {
    const fontsConcatenated = useMemo(
        () => getGlobalFontStylesWithPreloadLinks(staticAssetsPrefix, family),
        [staticAssetsPrefix, family],
    );

    return (
        <>
            <Head>{fontsConcatenated.preloadLinks}</Head>
            <Global styles={fontsConcatenated.styles} />
        </>
    );
};

export const Fonts = memo(BaseFonts);
