import type { SiteConfig } from '@config/type';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { COMMERCIAL_PROPERTY_USE_TYPE } from '@type/search/filters/useTypes';

export * from '@config/type';

export const SITE_CONFIG: SiteConfig = {
    metadata: {
        preconnectStaticsCDN: 'https://statics.otodom.pl',
        siteURL: 'www.otodom.pl',
        siteName: 'Otodom',
        defaultLanguage: 'pl',
        name: {
            robots: 'index, follow',
            description:
                'Najpopularniejszy serwis nieruchomosci. Sprzedaz i wynajem w kategoriach mieszkania, domy, dzialki, lokale uzytkowe. Oferty biur nieruchomosci, bezposrednie oraz od deweloperów. Znajdz swój dom marzen razem z nami - otoDom.pl',
            'google-play-app': 'app-id=pl.otodom',
            'theme-color': '#464b53',
            'msapplication-TileColor': '#464b53',
        },
        property: {
            'fb:app_id': '1490856384482695',
            'og:title': 'Otodom: Ogloszenia Nieruchomosci, Mieszkania, Domy, Dzialki',
            'og:image':
                'https://statics.otodom.pl/static/otodompl/naspersclassifieds-regional/verticalsre-atlas-web-otodompl/static/img/fb/fb-image200x200.png?t=20-11-09',
            'og:type': 'website',
            'og:url': 'https://www.otodom.pl/',
            'og:site_name': 'www.otodom.pl/',
            'al:web:url': 'https://www.otodom.pl/',
            'al:web:should_fallback': 'true',
        },
        links: [
            {
                rel: 'canonical',
                href: 'https://www.otodom.pl/',
            },
            {
                rel: 'icon',
                href: 'https://statics.otodom.pl/static/otodompl/naspersclassifieds-regional/verticalsre-atlas-web-otodompl/static/img/favicon.svg',
            },
            {
                rel: 'apple-touch-icon',
                href: 'https://statics.otodom.pl/static/otodompl/naspersclassifieds-regional/verticalsre-atlas-web-otodompl/static/img/app-icon.png',
            },
            {
                rel: 'android-touch-icon',
                href: 'https://statics.otodom.pl/static/otodompl/naspersclassifieds-regional/verticalsre-atlas-web-otodompl/static/img/app-icon.png',
            },
        ],
        sellWithUs: true,
    },
    locales: [
        {
            code: 'pl',
            label: 'frontend.navbar.language-switcher.pl-short',
        },
    ],
    helpCenterLinks: [
        {
            code: 'pl',
            link: 'https://pomoc.otodom.pl/otodomhelpcenter/s/topic/0TO09000000kfeHGAQ',
        },
    ],
    helpCenterPaymentPageLinks: {
        partner: 'https://partner.otodom.pl/',
        learnMore: 'https://pomoc.otodom.pl/otodomhelpcenter',
        learnAboutAds: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/co-to-jest-wystawienie-V40-odo',
    },
    settingsPageLink: '/[lang]/mojekonto/ustawienia',
    helpCenterSortingInfoLinks: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/kA009000001v1h6CAA',
    helpCenterRegulationsLinks: {
        agency: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/regulamin-dla-biur-nieruchomo%C5%9Bci-V36-odo/',
        developer: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/regulamin-dla-deweloperw-V49-odo/',
        private:
            'https://pomoc.otodom.pl/otodomhelpcenter/s/article/regulamin-dla-u%C5%BCytkownikw-indywidualnych-V17-odo?_gl=1*1sbom0u*_gcl_au*ODcxNzU4MDYxLjE3MjQ3NDE3MjY.*_ga*NjA4ODgzNzYuMTcyNDc0MTcyNg..*_ga_6PZTQNYS5C*MTcyNDc2MDg2NS40LjEuMTcyNDc2MjU0NS42MC4wLjA./',
    },
    defaultUnits: {
        distance: 'm',
        area: 'm²',
        currency: 'zł',
    },
    dfp: {
        networkId: '/55100874/Otodom_pl',
        type: {
            adPage: {
                halfpage: 'AdPage/HalfPage',
                halfpageSec: 'AdPage/HalPage_sec',
                billboard: 'AdPage/Billboard',
                textLink: 'Mobile/link_txt_middle',
                textBottom: 'AdPage/TextAd_BOTTOM',
                textTop: 'AdPage/TextAd_TOP',
                calculator: 'AdPage/financial_calc',
            },
            searchPage: {
                additionalBox: 'Listing/AdditionalBox',
                rectangle: 'Listing/Rectangle_2',
                calculator: 'Listing/financial_calc',
                billboardBottom: 'Listing/Billboard_BOTTOM',
                topBoard: 'Listing/Top_board',
            },
            myAccountPage: {
                topBanner: 'MyAccount/Panel_banner',
            },
        },
    },
    tracking: {
        ninjaScriptUrl: 'https://ninja.data.olxcdn.com/ninja-real-estate.js',
        siteUrl: `${getPublicEnvConfig().env === 'PRD' ? 'www' : 'stg'}.otodom.pl`,
    },
    phoneNumbers: {
        countryCode: '48',
        country: 'PL',
        format: /^(?!0{9})\d{9}$/,
    },
    images: {
        logo: '/images/logo/otodom_logo_2021_white.svg',
        nexusLogo: '/images/logo/otodom_logo_2021_black.svg',
        homepageBackground: '/images/homepage/otodomHomepageBgr20240820.webp',
        investmentsPageBackground: '/images/investments/otodomInvestmentsBackground.webp',
    },
    navbar: [
        {
            label: {
                default: 'frontend.navbar.menu.sell',
            },
            id: 'sell',
            tracking: {
                eventName: 'for_sale_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.estate',
                    },
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_categories',
                        categoryName: 'sale',
                    },
                    id: 'sellEstate',
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-apartments',
                            },
                            id: 'sellEstateApartments',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie/cala-polska',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garsoniera',
                            },
                            id: 'sellEstateStudioFlat',
                            href: '/[lang]/wyniki/sprzedaz/kawalerka/cala-polska',
                            searchData: {
                                estate: ESTATE.studioFlat,
                                transaction: TRANSACTION.sell,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-house',
                            },
                            id: 'sellEstateHouse',
                            href: '/[lang]/wyniki/sprzedaz/dom/cala-polska',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.sell,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-plots',
                            },
                            id: 'sellEstatePlots',
                            href: '/[lang]/wyniki/sprzedaz/dzialka/cala-polska',
                            searchData: {
                                estate: ESTATE.terrain,
                                transaction: TRANSACTION.sell,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-commercials',
                            },
                            id: 'sellEstateCommercials',
                            href: '/[lang]/wyniki/sprzedaz/lokal/cala-polska',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-office',
                            },
                            id: 'sellEstateOffice',
                            href: '/[lang]/wyniki/sprzedaz/lokal,biuro/cala-polska',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                location: 'cala-polska',
                                useTypes: [COMMERCIAL_PROPERTY_USE_TYPE.office],
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-halls',
                            },
                            id: 'sellEstateHalls',
                            href: '/[lang]/wyniki/sprzedaz/haleimagazyny/cala-polska',
                            searchData: {
                                estate: ESTATE.hall,
                                transaction: TRANSACTION.sell,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garage',
                            },
                            id: 'sellEstateGarage',
                            href: '/[lang]/wyniki/sprzedaz/garaz/cala-polska',
                            searchData: {
                                estate: ESTATE.garage,
                                transaction: TRANSACTION.sell,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.popular-location',
                    },
                    id: 'sellPopularLocation',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_popular_locations',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-warszawa',
                            },
                            id: 'sellPopularLocationWarszawa',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie/mazowieckie/warszawa/warszawa/warszawa',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'mazowieckie/warszawa/warszawa/warszawa',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-wroclaw',
                            },
                            id: 'sellPopularLocationWroclaw',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie/dolnoslaskie/wroclaw/wroclaw/wroclaw',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'dolnoslaskie/wroclaw/wroclaw/wroclaw',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-krakow',
                            },
                            id: 'sellPopularLocationKrakow',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie/malopolskie/krakow/krakow/krakow',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'malopolskie/krakow/krakow/krakow',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-poznan',
                            },
                            id: 'sellPopularLocationPoznan',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie/wielkopolskie/poznan/poznan/poznan',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'wielkopolskie/poznan/poznan/poznan',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-gdansk',
                            },
                            id: 'sellPopularLocationGdansk',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie/pomorskie/gdansk/gdansk/gdansk',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'pomorskie/gdansk/gdansk/gdansk',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-lodz',
                            },
                            id: 'sellPopularLocationLodz',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie/lodzkie/lodz/lodz/lodz',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'lodzkie/lodz/lodz/lodz',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-gdynia',
                            },
                            id: 'sellPopularLocationGdynia',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie/pomorskie/gdynia/gdynia/gdynia',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'pomorskie/gdynia/gdynia/gdynia',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-katowice',
                            },
                            id: 'sellPopularLocationKatowice',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie/slaskie/katowice/katowice/katowice',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'slaskie/katowice/katowice/katowice',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.sell-investment',
                    },
                    id: 'sellInvestment',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_investments',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-new-apartment',
                            },
                            id: 'sellInvestmentApartment',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/cala-polska',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_investments',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-new-house',
                            },
                            id: 'sellInvestmentHouse',
                            href: '/[lang]/wyniki/sprzedaz/dom,rynek-pierwotny/cala-polska',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_investments',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-new-locals',
                            },
                            id: 'sellInvestmentLocals',
                            href: '/[lang]/wyniki/sprzedaz/lokal,rynek-pierwotny/cala-polska',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_investments',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.sell-guide-header',
                    },
                    id: 'sellGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.report-article',
                            },
                            id: 'sellReportArticle',
                            href: '/wiadomosci/pobierz/raporty/raport-szczesliwy-dom-mieszkanie-na-osi-czasu',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'sale',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.advice-article',
                            },
                            id: 'sellAdviceArticle',
                            href: '/wiadomosci/kupuje/psiarz-kupuje-dom-na-co-zwrocic-uwage',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'sale',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.loans-guide-article',
                            },
                            id: 'sellLoansGuideArticle',
                            href: '/wiadomosci/pobierz/e-booki/praktyczny-przewodnik-po-kredytach-hipotecznych',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'sale',
                            },
                            target: '_blank',
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.sell-guide-header',
                        },
                        id: 'sellGuideSeeAll',
                        href: '/wiadomosci/kupuje/krok-po-kroku',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                            categoryName: 'sale',
                        },
                        target: '_blank',
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.rent',
            },
            id: 'rent',
            tracking: {
                eventName: 'for_rent_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.estate',
                    },
                    id: 'rentEstate',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_categories',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-apartments',
                            },
                            id: 'rentEstateApartments',
                            href: '/[lang]/wyniki/wynajem/mieszkanie/cala-polska',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garsoniera',
                            },
                            id: 'rentEstateStudioFlat',
                            href: '/[lang]/wyniki/wynajem/kawalerka/cala-polska',
                            searchData: {
                                estate: ESTATE.studioFlat,
                                transaction: TRANSACTION.rent,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-house',
                            },
                            id: 'rentEstateHouse',
                            href: '/[lang]/wyniki/wynajem/dom/cala-polska',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.rent,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-room',
                            },
                            id: 'rentEstateRoom',
                            href: '/[lang]/wyniki/wynajem/pokoj/cala-polska',
                            searchData: {
                                estate: ESTATE.room,
                                transaction: TRANSACTION.rent,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-plots',
                            },
                            id: 'rentEstatePlots',
                            href: '/[lang]/wyniki/wynajem/dzialka/cala-polska',
                            searchData: {
                                estate: ESTATE.terrain,
                                transaction: TRANSACTION.rent,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-commercials',
                            },
                            id: 'rentEstateCommercials',
                            href: '/[lang]/wyniki/wynajem/lokal/cala-polska',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.rent,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-office',
                            },
                            id: 'rentEstateOffice',
                            href: '/[lang]/wyniki/wynajem/lokal,biuro/cala-polska',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.rent,
                                location: 'cala-polska',
                                useTypes: [COMMERCIAL_PROPERTY_USE_TYPE.office],
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-halls',
                            },
                            id: 'rentEstateHalls',
                            href: '/[lang]/wyniki/wynajem/haleimagazyny/cala-polska',
                            searchData: {
                                estate: ESTATE.hall,
                                transaction: TRANSACTION.rent,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garage',
                            },
                            id: 'rentEstateGarage',
                            href: '/[lang]/wyniki/wynajem/garaz/cala-polska',
                            searchData: {
                                estate: ESTATE.garage,
                                transaction: TRANSACTION.rent,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.popular-location',
                    },
                    id: 'rentPopularLocation',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_popular_locations',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-warszawa',
                            },
                            id: 'rentPopularLocationWarszawa',
                            href: '/[lang]/wyniki/wynajem/mieszkanie/mazowieckie/warszawa/warszawa/warszawa',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'mazowieckie/warszawa/warszawa/warszawa',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-wroclaw',
                            },
                            id: 'rentPopularLocationWroclaw',
                            href: '/[lang]/wyniki/wynajem/mieszkanie/dolnoslaskie/wroclaw/wroclaw/wroclaw',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'dolnoslaskie/wroclaw/wroclaw/wroclaw',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-krakow',
                            },
                            id: 'rentPopularLocationKrakow',
                            href: '/[lang]/wyniki/wynajem/mieszkanie/malopolskie/krakow/krakow/krakow',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'malopolskie/krakow/krakow/krakow',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-poznan',
                            },
                            id: 'rentPopularLocationPoznan',
                            href: '/[lang]/wyniki/wynajem/mieszkanie/wielkopolskie/poznan/poznan/poznan',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'wielkopolskie/poznan/poznan/poznan',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-gdansk',
                            },
                            id: 'rentPopularLocationGdansk',
                            href: '/[lang]/wyniki/wynajem/mieszkanie/pomorskie/gdansk/gdansk/gdansk',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'pomorskie/gdansk/gdansk/gdansk',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-lodz',
                            },
                            id: 'rentPopularLocationLodz',
                            href: '/[lang]/wyniki/wynajem/mieszkanie/lodzkie/lodz/lodz/lodz',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'lodzkie/lodz/lodz/lodz',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-gdynia',
                            },
                            id: 'rentPopularLocationGdynia',
                            href: '/[lang]/wyniki/wynajem/mieszkanie/pomorskie/gdynia/gdynia/gdynia',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'pomorskie/gdynia/gdynia/gdynia',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-katowice',
                            },
                            id: 'rentPopularLocationKatowice',
                            href: '/[lang]/wyniki/wynajem/mieszkanie/slaskie/katowice/katowice/katowice',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'slaskie/katowice/katowice/katowice',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.rent-popular-agency',
                    },
                    id: 'rentPopularAgency',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_agencies',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-warszawa',
                                mobile: 'frontend.navbar.menu.location-warszawa',
                            },
                            id: 'rentPopularAgencyWarszawa',
                            href: '/warszawa/firmy/biura-nieruchomosci/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-wroclaw',
                                mobile: 'frontend.navbar.menu.location-wroclaw',
                            },
                            id: 'rentPopularAgencyWroclaw',
                            href: '/wroclaw/firmy/biura-nieruchomosci/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-krakow',
                                mobile: 'frontend.navbar.menu.location-krakow',
                            },
                            id: 'rentPopularAgencyKrakow',
                            href: '/krakow/firmy/biura-nieruchomosci/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-poznan',
                                mobile: 'frontend.navbar.menu.location-poznan',
                            },
                            id: 'rentPopularAgencyPoznan',
                            href: '/poznan/firmy/biura-nieruchomosci/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-gdansk',
                                mobile: 'frontend.navbar.menu.location-gdansk',
                            },
                            id: 'rentPopularAgencyGdansk',
                            href: '/gdansk/firmy/biura-nieruchomosci/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-lodz',
                                mobile: 'frontend.navbar.menu.location-lodz',
                            },
                            id: 'rentPopularAgencyLodz',
                            href: '/lodz/firmy/biura-nieruchomosci/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-gdynia',
                                mobile: 'frontend.navbar.menu.location-gdynia',
                            },
                            id: 'rentPopularAgencyGdynia',
                            href: '/gdynia/firmy/biura-nieruchomosci/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-katowice',
                                mobile: 'frontend.navbar.menu.location-katowice',
                            },
                            id: 'rentPopularAgencyKatowice',
                            href: '/katowice/firmy/biura-nieruchomosci/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.rent-guide-header',
                    },
                    id: 'rentGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-report-article',
                            },
                            id: 'rentReportArticle',
                            href: '/wiadomosci/tag/raport-z-rynku-najmu',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'rent',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.good-rental-article',
                            },
                            id: 'rentGoodRentalArticle',
                            href: '/wiadomosci/wynajmuje/krok-po-kroku-wynajmuje/dobry-wy-najem-otodom',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'rent',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.how-to-rent-an-apartment-article',
                            },
                            id: 'rentHowToRentAnApartmentArticle',
                            href: '/wiadomosci/wynajmuje/jak-wynajac-mieszkanie-poradnik-dla-studentow',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'rent',
                            },
                            target: '_blank',
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.rent-guide-header',
                        },
                        id: 'rentGuideSeeAll',
                        href: '/wiadomosci/wynajmuje',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                            categoryName: 'rent',
                        },
                        target: '_blank',
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.sellers',
            },
            id: 'sellers',
            tracking: {
                eventName: 'sale_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.selling-a-property',
                    },
                    id: 'sellingProperty',
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.selling-a-property-guide',
                            },
                            id: 'sellingPropertyGuide',
                            href: '/wiadomosci/sprzedaje/sprzedaz-mieszkania-lub-domu-na-co-zwrocic-uwage',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_sale_property',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.market-situation',
                            },
                            id: 'marketSituation',
                            href: '/wiadomosci/dane/sytuacja-na-rynku',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_sale_property',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.selling-a-property-get-inspired',
                            },
                            id: 'sellingPropertyGetInspired',
                            href: '/wiadomosci/buduje/zainspiruj-sie',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_sale_property',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.property-evaluation',
                    },
                    id: 'propertyEvaluation',
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.evaluate-property',
                            },
                            id: 'propertyEvaluationTool',
                            href: '/[lang]/wycena-nieruchomosci',
                            tracking: {
                                eventName: 'property_price_evaluation_start',
                                touchPointButton: 'header_sale_property',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.apartment-worth',
                            },
                            id: 'apartmentWorth',
                            href: '/wiadomosci/sprzedaje/finanse-i-kredyty-sprzedaje/ile-jest-warte-twoje-mieszkanie-id4510-html',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_evaluate_property',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.sell-apartment',
                    },
                    id: 'sellApartment',
                    isDesktopOnly: true,
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.sell-apartment-link',
                        },
                        isNexusButton: true,
                        id: 'sellApartmentLink',
                        href: '/nowe-ogloszenie/kategoria',
                        tracking: {
                            eventName: 'posting_form_click',
                            touchPointButton: 'header_sale_property',
                        },
                    },
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.sellers-guide-header',
                    },
                    id: 'sellersGuide',
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.selling-apartment-or-house',
                            },
                            id: 'sellingApartmentOrHouse',
                            href: '/wiadomosci/sprzedaje/sprzedaz-mieszkania-lub-domu-na-co-zwrocic-uwage',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.selling-garage-article',
                            },
                            id: 'sellingGarageArticle',
                            href: '/wiadomosci/sprzedaje/krok-po-kroku-sprzedaje/sprzedaz-garazu-podstawy',
                            target: '_blank',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.benefits-article',
                            },
                            id: 'sellersRentalOrSaleAnnouncement',
                            href: '/wiadomosci/kupuje/krok-po-kroku/agent-nieruchomosci-7-korzysci',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.sellers-guide-header',
                        },
                        id: 'sellersGuideSeeAll',
                        href: '/wiadomosci/sprzedaje',
                        target: '_blank',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                        },
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.primary-market',
            },
            id: 'primaryMarket',
            tracking: {
                eventName: 'primary_market_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.estate',
                    },
                    id: 'primaryMarketEstate',
                    tracking: {
                        eventName: 'header_categories',
                        touchPointButton: 'title_click',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-apartments',
                            },
                            id: 'primaryMarketEstateApartments',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/cala-polska',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garsoniera',
                            },
                            id: 'primaryMarketEstateStudioFlat',
                            href: '/[lang]/wyniki/sprzedaz/kawalerka,rynek-pierwotny/cala-polska',
                            searchData: {
                                estate: ESTATE.studioFlat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-house',
                            },
                            id: 'primaryMarketEstateHouse',
                            href: '/[lang]/wyniki/sprzedaz/dom,rynek-pierwotny/cala-polska',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-investment',
                            },
                            id: 'primaryMarketInvestment',
                            href: '/[lang]/wyniki/sprzedaz/inwestycja/cala-polska',
                            searchData: {
                                estate: ESTATE.investment,
                                transaction: TRANSACTION.sell,
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-commercials',
                            },
                            id: 'primaryMarketEstateCommercials',
                            href: '/[lang]/wyniki/sprzedaz/lokal,rynek-pierwotny/cala-polska',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-halls',
                            },
                            id: 'primaryMarketEstateHalls',
                            href: '/[lang]/wyniki/sprzedaz/haleimagazyny,rynek-pierwotny/cala-polska',
                            searchData: {
                                estate: ESTATE.hall,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garage',
                            },
                            id: 'primaryMarketEstateGarage',
                            href: '/[lang]/wyniki/sprzedaz/garaz,rynek-pierwotny/cala-polska',
                            searchData: {
                                estate: ESTATE.garage,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'cala-polska',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.popular-location',
                    },
                    id: 'primaryMarketPopularLocation',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_popular_locations',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-warszawa',
                            },
                            id: 'primaryMarketPopularLocationWarszawa',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/mazowieckie/warszawa/warszawa/warszawa',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'mazowieckie/warszawa/warszawa/warszawa',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-wroclaw',
                            },
                            id: 'primaryMarketPopularLocationWroclaw',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/dolnoslaskie/wroclaw/wroclaw/wroclaw',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'dolnoslaskie/wroclaw/wroclaw/wroclaw',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-krakow',
                            },
                            id: 'primaryMarketPopularLocationKrakow',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/malopolskie/krakow/krakow/krakow',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'malopolskie/krakow/krakow/krakow',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-poznan',
                            },
                            id: 'primaryMarketPopularLocationPoznan',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/wielkopolskie/poznan/poznan/poznan',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'wielkopolskie/poznan/poznan/poznan',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-gdansk',
                            },
                            id: 'primaryMarketPopularLocationGdansk',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/pomorskie/gdansk/gdansk/gdansk',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'pomorskie/gdansk/gdansk/gdansk',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-lodz',
                            },
                            id: 'primaryMarketPopularLocationLodz',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/lodzkie/lodz/lodz/lodz',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'lodzkie/lodz/lodz/lodz',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-gdynia',
                            },
                            id: 'primaryMarketPopularLocationGdynia',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/pomorskie/gdynia/gdynia/gdynia',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'pomorskie/gdynia/gdynia/gdynia',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-katowice',
                            },
                            id: 'primaryMarketPopularLocationKatowice',
                            href: '/[lang]/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/slaskie/katowice/katowice/katowice',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'slaskie/katowice/katowice/katowice',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.primary-market-popular-developers',
                    },
                    id: 'primaryMarketPopularDevelopers',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_developers',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-warszawa',
                                mobile: 'frontend.navbar.menu.location-warszawa',
                            },
                            id: 'primaryMarketPopularDevelopersWarszawa',
                            href: '/warszawa/firmy/deweloperzy/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-wroclaw',
                                mobile: 'frontend.navbar.menu.location-wroclaw',
                            },
                            id: 'primaryMarketPopularDevelopersWroclaw',
                            href: '/wroclaw/firmy/deweloperzy/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-krakow',
                                mobile: 'frontend.navbar.menu.location-krakow',
                            },
                            id: 'primaryMarketPopularDevelopersKrakow',
                            href: '/krakow/firmy/deweloperzy/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-poznan',
                                mobile: 'frontend.navbar.menu.location-poznan',
                            },
                            id: 'primaryMarketPopularDevelopersPoznan',
                            href: '/poznan/firmy/deweloperzy/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-gdansk',
                                mobile: 'frontend.navbar.menu.location-gdansk',
                            },
                            id: 'primaryMarketPopularDevelopersGdansk',
                            href: '/gdansk/firmy/deweloperzy/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-lodz',
                                mobile: 'frontend.navbar.menu.location-lodz',
                            },
                            id: 'primaryMarketPopularDevelopersLodz',
                            href: '/lodz/firmy/deweloperzy/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-gdynia',
                                mobile: 'frontend.navbar.menu.location-gdynia',
                            },
                            id: 'primaryMarketPopularDevelopersGdynia',
                            href: '/gdynia/firmy/deweloperzy/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-katowice',
                                mobile: 'frontend.navbar.menu.location-katowice',
                            },
                            id: 'primaryMarketPopularDevelopersKatowice',
                            href: '/katowice/firmy/deweloperzy/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.primary-guide-header',
                    },
                    id: 'primaryMarketGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.reports-article',
                            },
                            id: 'primaryMarketGuideReportsArticle',
                            href: '/wiadomosci/dane',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                market: 'primary',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.home-report-article',
                            },
                            id: 'primaryMarketHomeReportArticle',
                            href: '/wiadomosci/profesjonalisci/raport-szczesliwy-dom-czego-potrzebuja-twoi-klienci',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                market: 'primary',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.lighthouse-magazine-article',
                            },
                            id: 'primaryMarketLighthouseMagazine',
                            href: '/wiadomosci/tag/magazyn-lighthouse',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                market: 'primary',
                            },
                            target: '_blank',
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.primary-guide-header',
                        },
                        id: 'primaryMarketGuideSeeAll',
                        href: '/wiadomosci/kupuje',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                            market: 'primary',
                        },
                        target: '_blank',
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.companies',
            },
            id: 'companies',
            tracking: {
                eventName: 'companies_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.companies-professionals',
                    },
                    id: 'companiesProfessionals',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_companies',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.companies-agency',
                            },
                            id: 'companiesProfessionalsAgency',
                            href: '/firmy/biura-nieruchomosci/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_companies',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.companies-developers',
                            },
                            id: 'companiesProfessionalsDevelopers',
                            href: '/firmy/deweloperzy/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_companies',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.companies-question',
                    },
                    id: 'companiesQuestion',
                    isDesktopOnly: true,
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_companies',
                    },
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.become-a-real-state-leader',
                        },
                        target: '_blank',
                        isNexusButton: true,
                        id: 'becomeARealStateLeader',
                        href: 'https://lidernieruchomosci.otodom.pl',
                        tracking: {
                            eventName: 'posting_form_click',
                            touchPointButton: 'header_companies',
                        },
                    },
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.companies-guide-header',
                    },
                    id: 'companiesGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.power-analytics-article',
                            },
                            id: 'companiesGuideAnalyticsArticle',
                            href: '/wiadomosci/dane/otodom-analytics',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                            target: '_blank',
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.re-leader-article',
                            },
                            id: 'companiesGuideAgentPro',
                            href: '/wiadomosci/profesjonalisci/posrednicy/nowy-lider-nieruchomosci',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                            shoudlBeMasked: true,
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.partner-article',
                            },
                            id: 'companiesGuidePartner',
                            href: 'https://partner.otodom.pl',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                            shoudlBeMasked: true,
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.companies-guide-header',
                        },
                        id: 'companiesGuideSeeAll',
                        href: '/wiadomosci/profesjonalisci',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header_companies',
                        },
                        target: '_blank',
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.credits',
            },
            id: 'finance',
            href: '/[lang]/finanse?utm_source=otodom&utm_medium=banner&utm_content=Skorzystaj&utm_campaign=header',
            tracking: {
                eventName: 'finance_click',
                touchPointButton: 'head_bar',
            },
        },
    ],
    navUserMenu: {
        businessMember: [
            {
                localisedHref: '/[lang]/mojekonto/ustawienia/agent-nieruchomosci',
                label: 'frontend.navbar.menu.settings',
            },
        ],
        unconfirmedUser: [
            {
                href: '/konto/rejestracja',
                label: 'frontend.navbar.menu.complete-registration',
            },
        ],
        regularUser: [
            {
                localisedHref: '/[lang]/mojekonto',
                label: 'frontend.navbar.menu.adverts',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_ads',
                },
            },
            {
                href: '/mojekonto/odpowiedzi',
                label: 'frontend.navbar.menu.answers',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_messages',
                },
            },
            {
                localisedHref: '/[lang]/mojekonto/platnosci/historia',
                label: 'frontend.navbar.menu.wallet',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_payments',
                },
            },
            {
                href: '/mojekonto/ustawienia',
                label: 'frontend.navbar.menu.settings',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_settings',
                },
            },
        ],
        businessUser: [
            {
                localisedHref: '/[lang]/mojekonto/business/podsumowanie',
                label: 'frontend.navbar.menu.summary',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_summary',
                },
            },
            {
                localisedHref: '/[lang]/mojekonto',
                label: 'frontend.navbar.menu.adverts',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_ads',
                },
            },
            {
                href: '/mojekonto/odpowiedzi',
                label: 'frontend.navbar.menu.answers',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_messages',
                },
            },
            {
                localisedHref: '/[lang]/mojekonto/business/platnosci',
                label: 'frontend.navbar.menu.wallet',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_payments',
                },
            },
            {
                localisedHref: '/[lang]/mojekonto/ustawienia',
                label: 'frontend.navbar.menu.settings',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_settings',
                },
            },
        ],
        businessAdmin: [
            {
                localisedHref: '/[lang]/mojekonto/business/podsumowanie',
                label: 'frontend.navbar.menu.summary',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_summary',
                },
            },
            {
                localisedHref: '/[lang]/mojekonto',
                label: 'frontend.navbar.menu.adverts',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_ads',
                },
            },
            {
                href: '/mojekonto/odpowiedzi',
                label: 'frontend.navbar.menu.answers',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_messages',
                },
            },
            {
                localisedHref: '/[lang]/mojekonto/business/platnosci',
                label: 'frontend.navbar.menu.wallet',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_payments',
                },
            },
            {
                localisedHref: '/[lang]/mojekonto/zespol',
                label: 'frontend.navbar.menu.our-team',
            },
            {
                localisedHref: '/[lang]/mojekonto/ustawienia',
                label: 'frontend.navbar.menu.settings',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_settings',
                },
            },
        ],
        favourites: [
            {
                href: '/[lang]/zapisane/ogloszenia',
                label: 'frontend.navbar.menu.favourite-adverts',
            },
            {
                href: '/[lang]/zapisane/wyszukiwania',
                label: 'frontend.navbar.menu.favourite-searches',
            },
        ],
    },
    dateInput: {
        format: 'DD/MM/YYYY',
        placeholder: 'dd/mm/rrrr',
    },
    atlasRedirects: {
        editAd: '/nowe-ogloszenie/edit/{id}/',
        extendAd: '/platnosci/multipay/?prolong[0]=1&id[0]={id}',
        promoteAd: '/platnosci/multipay/?id[0]={id}',
        activateAd: '/platnosci/multipay/?id[0]={id}',
        adSingleMessage: '/mojekonto/odpowiedz-na-ogloszenie/{id}',
        adMessages: '/mojekonto/odpowiedzi/{id}',
        addNewUnit: '/sprzedaz/mieszkanie/nowe-ogloszenie/?parent_id={parentId}',
        addAd: '/nowe-ogloszenie/kategoria',
        agencyRegistration: '/konto/rejestracja-firmy/biuro-nieruchomosci/',
        developerRegistration: '/konto/rejestracja-firmy/deweloper/',
        copyAd: '/sprzedaz/mieszkanie/nowe-ogloszenie/?parent_id={parentId}&id={id}&copying=1',
        moreArticles: '/wiadomosci',
        offers: '/oferty/?search%5Bhomepage_ads%5D=1/',
        developersPage: '/firmy/deweloperzy/',
        analytics: '/analytics/login/re-platform',
        registrationSuccess: '/konto/checkemailafterpost/?type=registerbusiness',
        businessActivation: '/konto/aktywacja',
        projectPostingForm: '',
        hciamLoginPage: '/api/internal/hciam/signin',
    },
    isProjectPostingFormMigrated: true,
    feedbackButton: {
        link: 'https://pomoc.otodom.pl/otodomhelpcenter/s/contactsupport',
    },
    baxter: {
        urlInit: 'https://cdn.slots.baxter.olx.org/otodompl/web/release/init.min.js',
        urlStyling: 'https://cdn.slots.baxter.olx.org/otodompl/web/release/init.css',
    },
    shouldShowSocialButtonsOnTopOnLoginModal: true,
    privacyPolicyLink: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/kA009000001v1hJCAQ',
    features: {
        addOns: { enabledForUserTypes: ['agency', 'developer'] },
    },
    externalUrl: {
        obidoHomepage: 'https://obido.pl/dla-deweloperow/',
    },
    cookiesPolicyLink: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/kA009000001v1hTCAQ',
    regulationPolicyLink: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/kA009000001v1hSCAQ',
    shouldUnregisterServiceWorker: true,
    postCode: {
        placeholder: '12-345',
    },
    topLocations: {
        atlasLocations: [
            {
                name: '26',
                label: 'Warszawa, mazowieckie',
                areaName: 'Warszawa, mazowieckie',
            },
            {
                name: '39',
                label: 'Wrocław, dolnośląskie',
                areaName: 'Wrocław, dolnośląskie',
            },
            {
                name: '38',
                label: 'Kraków, małopolskie',
                areaName: 'Kraków, małopolskie',
            },
            {
                name: '1',
                label: 'Poznań, wielkopolskie',
                areaName: 'Poznań, wielkopolskie',
            },
            {
                name: '40',
                label: 'Gdańsk, pomorskie',
                areaName: 'Gdańsk, pomorskie',
            },
        ],
        locationServiceLocations: [
            {
                name: 'mazowieckie/warszawa/warszawa/warszawa',
                label: 'Warszawa, mazowieckie',
                areaName: 'Warszawa',
            },
            {
                name: 'dolnoslaskie/wroclaw/wroclaw/wroclaw',
                label: 'Wrocław, dolnośląskie',
                areaName: 'Wrocław',
            },
            {
                name: 'malopolskie/krakow/krakow/krakow',
                label: 'Kraków, małopolskie',
                areaName: 'Kraków',
            },
            {
                name: 'wielkopolskie/poznan/poznan/poznan',
                label: 'Poznań, wielkopolskie',
                areaName: 'Poznań',
            },
            {
                name: 'pomorskie/gdansk/gdansk/gdansk',
                label: 'Gdańsk, pomorskie',
                areaName: 'Gdańsk',
            },
        ],
    },
    validation: {
        postCode: /^\d{2}-\d{3}$/,
        tin: 'tin-pl',
        regon: 'regon-pl',
    },
    shouldUseTypologyInsteadOfNumberOfRooms: false,
};
