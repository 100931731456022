import { logError } from '@domains/shared/helpers/logger';
import { getStorageItemValue, setStorageItemValue } from '@domains/shared/helpers/storage';
import type { NextRouter } from 'next/router';
import Router from 'next/router';
import { useEffect } from 'react';

const saveScrollPosition = (url: string): void => {
    const scrollPosition = { x: window.scrollX, y: window.scrollY };

    setStorageItemValue(url, scrollPosition, { storageType: 'sessionStorage' });
};

const restoreScrollPosition = (url: string): void => {
    try {
        const scrollPosition = getStorageItemValue(url, { storageType: 'sessionStorage' }) as {
            x: number;
            y: number;
        };

        if (!scrollPosition || typeof scrollPosition.x !== 'number' || typeof scrollPosition.y !== 'number') {
            return;
        }

        window.scrollTo(scrollPosition.x, scrollPosition.y);
    } catch (error) {
        logError('restoreScrollPosition error', { error });
    }
};

export const useScrollRestoration = (router: NextRouter): void => {
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            let shouldScrollRestore = false;

            window.history.scrollRestoration = 'manual';
            restoreScrollPosition(router.asPath);

            const onBeforeUnload = (event: BeforeUnloadEvent): void => {
                saveScrollPosition(router.asPath);
                delete event.returnValue;
            };

            const onRouteChangeStart = (): void => {
                saveScrollPosition(router.asPath);
            };

            const onRouteChangeComplete = (url: string): void => {
                if (shouldScrollRestore) {
                    shouldScrollRestore = false;
                    restoreScrollPosition(url);
                }
            };

            window.addEventListener('beforeunload', onBeforeUnload);
            Router.events.on('routeChangeStart', onRouteChangeStart);
            Router.events.on('routeChangeComplete', onRouteChangeComplete);
            Router.beforePopState(() => {
                shouldScrollRestore = true;

                return true;
            });

            return (): void => {
                window.removeEventListener('beforeunload', onBeforeUnload);
                Router.events.off('routeChangeStart', onRouteChangeStart);
                Router.events.off('routeChangeComplete', onRouteChangeComplete);
                Router.beforePopState(() => true);
            };
        }
    }, [router]);
};
