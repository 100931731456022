import type { CurrentUser } from '@lib/pages/user';
import type { JSX, PropsWithChildren } from 'react';
import { createContext } from 'react';

interface Props {
    /** @deprecated Please use LazyUserContext */
    user?: CurrentUser | null;
}

/** @deprecated Please use LazyUserContext or when it is really necessary in the protected page - useProtectedPageUser() */
export const CurrentUserContext = createContext<Readonly<CurrentUser> | null>(null);
export const CurrentUserContextProvider = ({ children, user = null }: PropsWithChildren<Props>): JSX.Element => {
    return <CurrentUserContext.Provider value={user}>{children}</CurrentUserContext.Provider>;
};
